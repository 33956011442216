import React from 'react'
import { Card, Container, Image } from 'react-bootstrap'
import '../ComponentsCss/TrendingGadgets.css'
import phone from '../Images/homepage/phone.png'
import Slider from "react-slick";
import star from '../Images/homepage/star.png'
import { QUERY_GET_TRENDING_PRODUCTS } from '../graphql/Query';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

export default function TrendingGadgets() {
    const { data: getTrendingBuyProduct, loading: Loading } = useQuery(QUERY_GET_TRENDING_PRODUCTS,
        {
            variables:
            {
                "category": "Phones"

            }
        });
    const navigate = useNavigate()
    const handleProductClick = (product) => {
        navigate(`/buying-product-details/${product.id}`, {
            state: {
                product: product
            }
        })
    }
    var settings = {
        arrows: false,
        dots: true,
        pauseOnHover: true,
        infinite: true,
        speed: 2000,
        autoplay: true,
        fade: false,
        variableWidth: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            <Container className='trndmain' >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 40, width: 5, backgroundColor: '#44CE30', marginTop: 0 }}></div>
                    <h3 style={{ marginTop: 10, marginLeft: 10, fontWeight: 500 }}>Trending Gadgets</h3>
                </div>
                <Slider {...settings}>
                    {
                        getTrendingBuyProduct && getTrendingBuyProduct?.getTrendingBuyProduct?.map((product) => {
                            return (

                                <div>
                                    <Card className='trendingCard  mx-auto d-block' onClick={() => handleProductClick(product)}>
                                        <div className='trndimg'>
                                            <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`} className='mx-auto d-block' style={{ width: '100%', height: '150px' }}></Image>
                                        </div>
                                        <p style={{ textAlign: 'center', fontWeight: 500 }} className='pt-3'>{product?.productName}</p>
                                        {/* <Image src={star} className='mx-auto d-block' style={{ height: '15px', width: '80px', marginTop: -10 }}></Image> */}

                                    </Card>
                                </div>
                            )

                        })

                    }


                </Slider>

            </Container>
        </>
    )
}
