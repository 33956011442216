import React, { useState } from 'react'
import { Button, Col, Container, Form, Image, Modal, Row, Spinner, Table } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client';
import { QUERY_GET_SELL_PRODUCT } from '../../graphql/Query';
import { MUTATION_DELETE_SELL_PRODUCT, MUTATION_SELL_PRODUCT } from '../../graphql/Mutations';
import swal from 'sweetalert';
import { FaPencilAlt } from "react-icons/fa";

import '../../ComponentsCss/AdminCss/AllsellingProduct.css'
import EditModal from './Modals/EditSellingProductModal';
import EditImage from './Modals/editImage';
const AllSellingProducts = () => {
    const { data: sellProData, loading: sellProLoading } = useQuery(QUERY_GET_SELL_PRODUCT);
    const [selectedProduct, setSelectedProduct] = useState(null);
    let count = 1;
    const [smShow, setSmShow] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [imagemodal , setImageModal]= useState(false) ;
    const [proId, setProId] = useState();

    const [deleteSellProductByid, { loading: dltLoading }] = useMutation(MUTATION_DELETE_SELL_PRODUCT, {
        refetchQueries: [
            QUERY_GET_SELL_PRODUCT
        ]
    })

    function showdlthandle(id) {
        setProId(id)
        setSmShow(true)
    }
    function deleteHandle() {
        deleteSellProductByid({
            variables: {
                "sellProductId": `${proId}`
            }
        })
        swal({
            title: "Deleted",
            text: "Product Delete successfully",
            icon: "success",
        })
        setSmShow(false)
    }
    const handleEdit = (product) => {
        setEditModal(true)
        setSelectedProduct(product)
    }
    const handleImageEdit=(pro)=>{
      setImageModal(true) ;
      setSelectedProduct(pro)
    }
    return (
        <>
            <Table responsive striped bordered hover>
                <thead className='table-head'>
                    <tr>
                        <th>s.no.</th>
                        <th>Produc Name</th>
                        <th>Produc Price</th>

                        {/* <th>Product Description</th> */}
                        <th>Brand</th>
                        {/* <th>Variant</th> */}
                        <th>Series</th>
                        {/* <th>Product Type</th> */}
                        <th>Category</th>
                        {/* <th>Image</th> */}
                        <th>Remove Product</th>
                        <th>Edit Product</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        sellProLoading ?
                            <Spinner animation="border" variant="success" className='mx-auto d-block' />

                            :
                            sellProData && sellProData.getSellProduct.slice(0).reverse().map(data =>
                                <tr className='table-data'>
                                    <td>{count++}</td>
                                    <td>{data.productName}</td>
                                    <td>{data.productPrice}</td>
                                    {/* <td>{data.productDescription}</td> */}
                                    <td>{data.productBrand}</td>
                                    {/* <td>{data.variant}</td> */}
                                    <td>{data.series}</td>
                                    {/* <td>{data.productType}</td> */}
                                    <td>{data.category}</td>
                                    {/* <td>
                                        <Image fluid src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${data.image}`} style={{ height: 80, width: 80 }}></Image>
                                         <FaPencilAlt className ="edit-icon" onClick={()=>{handleImageEdit(data)}}/>
                                    </td> */}
                                    <td><Button className='mx-auto d-block' variant='danger' onClick={() => showdlthandle(data.id)}>-</Button></td>
                                    <td> <Button className='mx-auto d-block' onClick={() => handleEdit(data)} > Edit  </Button></td>
                                </tr>

                            )

                    }
                </tbody>

            </Table>
            {imagemodal ? <EditImage data = {selectedProduct} imagemodal={imagemodal} setImageModal={setImageModal} /> :""}
            {editModal ? <EditModal data={selectedProduct} setEditModal={setEditModal} editModal={editModal} /> : ""}
            <Modal
                size="sm"
                show={smShow}
                onHide={() => setSmShow(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body style={{ textAlign: 'center' }}><h5>Are you sure you want to delete this Product?</h5>
                    {
                        dltLoading ?
                            <Spinner animation="border" variant="danger" />
                            :
                            <Button onClick={() => deleteHandle()} style={{ backgroundColor: '#40a737', border: 'none', width: '100%' }}>Yes</Button>

                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AllSellingProducts 