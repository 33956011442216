import { useLocation } from "react-router-dom";
import Footer from "../Footer"
import Navigation from "../Navigation"
import { Container, Col, Row, Card, Breadcrumb, Spinner, Button, Image, Alert, Offcanvas } from 'react-bootstrap';
import Moment from 'react-moment';
import { useState } from "react";


const OrderDetail = () => {
    const location = useLocation()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <>
            {window.scrollTo(0, 0)}
            <Navigation />
            <Container>
                <Card style={{ margin: "1rem 0px", padding: "20px" }} >
                    <Card.Title style={{ fontSize: "25px", fontWeight: "bold" }}> Device Details</Card.Title>
                    <Card.Body>

                        <Row className="gap-5">

                            <div className="text-center col-lg-4 col-sm-12" style={{ backgroundColor: "#f7f7f7" }} >
                                <Image fluid src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location.state.order.imageKey}`} style={{ width: "15rem", marginRight: "1rem" }} />
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <Alert variant="warning" style={{ width: "fit-content", fontWeight: "bold", textTransform: "capitalize" }}>
                                    {location.state.order.status}
                                </Alert>
                                <Card.Title style={{ fontWeight: "bold", color: "#000", padding: "10px 0px" }}>{location.state.order.productName}</Card.Title>
                                <div>
                                    <p className="grey-color">Booking Id :
                                        <span style={{ fontWeight: "bold", color: "#000" }}> {location.state.order.orderId}  </span> </p>
                                    <p className="grey-color">Order created on:
                                        <span style={{ fontWeight: "bold", color: "#000" }}>
                                            <Moment format="DD/MM/YYYY, hh:mm A">{location.state.order.createdDateTime}</Moment>

                                        </span>
                                    </p>
                                    <p className="grey-color">  Quote Price:</p>

                                    <div style={{ fontSize: "36px", color: "green", fontWeight: "bold" }}>
                                        ₹{location.state.order.sellingAmount}
                                    </div>
                                </div>
                                <Button variant="primary" onClick={handleShow} className="me-2">
                                    View Details
                                </Button>
                                <Offcanvas show={show} onHide={handleClose} placement={'end'} >
                                    <Offcanvas.Header closeButton>
                                        <Offcanvas.Title>Order Details</Offcanvas.Title>
                                    </Offcanvas.Header>
                                    <Offcanvas.Body>
                                        <div >

                                            <div style={{ fontSize: "15px", textTransform: "capitalize" }} className="p-2 fw-medium"> Storage : <span className="fw-bold ms-1 text-success">{location.state.order.storage} </span> </div>
                                            <div style={{ fontSize: "15px", textTransform: "capitalize" }} className="p-2 fw-medium"> Ram : <span className="fw-bold ms-1 text-success">{location.state.order.ram} </span> </div>

                                            {location?.state?.order.details?.map((detail) => {
                                                 let answer = detail?.ans;
                                                 if (answer === "undefined") {
                                                     answer = "Not Available"
                                                 } else if (answer == "false" || answer == false) {
                                                     answer = "Not Available"
                                                 } else if (answer == "true") {
                                                     answer = "Available"
                                                 } else {
             
                                                     if (detail?.que === "4. Tell us about your gadget’s screen?") {
                                                         if (detail.ans === "change1") {
                                                             answer = "Changed (Compatible)";
                                                         } else if (detail.ans === "change2") {
                                                             answer = "Changed but Original (Replacement Bill Required)";
                                                         }
                                                     }
                                                     if (detail?.que === "5. Is your device ever repaired?") {
                                                         if (detail.ans === "yes1") {
                                                             answer = "Yes (Repaired in Authorised Service Centre - Job Sheet Required"
                                                         }
                                                         else if (detail.ans === "yes2") {
                                                             answer = "Yes (Repaired in Unauthorised Centre"
                                                         }
             
                                                     }
                                                     if (detail?.que === "4. Is your device ever repaired?") {
                                                         if (detail.ans === "yes1") {
                                                             answer = "Yes (Repaired in Authorised Service Centre - Job Sheet Required"
                                                         }
                                                         else if (detail.ans === "yes2") {
                                                             answer = "Yes (Repaired in Unauthorised Centre"
                                                         }
             
                                                     }
                                                     if (detail?.que === "condition of your device screen	") {
                                                         if (detail.ans === "minor") {
                                                             answer = "Minor/least visible scratches (Less than 3)";
                                                         } else if (detail.ans === "major") {
                                                             answer = "Major/More Visible Scratches (More Than 3)";
                                                         } else if (detail.ans === "no") {
                                                             answer = "No Scratches";
                                                         }
                                                     }
                                                     if (detail?.que === "condition of your device Body") {
                                                         if (detail.ans === "excellent") {
                                                             answer = "Excellent Condition (No visible sign of usage)";
                                                         } else if (detail.ans === "good") {
                                                             answer = "Good Condition (Minor visible scratches, Minor discoloration";
                                                         } else if (detail.ans === "average") {
                                                             answer = "Average Condition (Major visible scratches, Minor dent, Major discoloration)";
                                                         } else if (detail.ans === "poor") {
                                                             answer = "Poor Condition (Major visible scratches, Major discoloration, Cracks, Bend, Gap between display and body, Camera glass broken)";
                                                         }
                                                     }
                                                     if(detail?.que === "Warranty Period"){
                                                         if(detail.ans === "1"){
                                                             answer = "Below 1 Month";
                                                         }else if(detail.ans === "3"){
                                                             answer = "1 To 3 Months";
                                                         }else if(detail.ans === "6"){
                                                             answer = "3 To 6 Months";
                                                         }
                                                         else if(detail.ans === "9"){
                                                             answer = "6 To 9 Months";
                                                         }
                                                         else if(detail.ans === "12"){
                                                             answer = "9 To 12 Months";
                                                         }
                                                         else if(detail.ans === "default"){
                                                             answer = "Above 1 year";
                                                         }
                                                     }
                                                 }
                                                return (
                                                    <>
                                                        <div className="p-2 fw-medium" style={{ fontSize: "15px", textTransform: "capitalize" }}> <span> {detail?.que} : </span> <span className="fw-bold ms-1 text-success"> {answer} </span> </div>
                                                    </>
                                                )
                                            })}

                                        </div>
                                    </Offcanvas.Body>
                                </Offcanvas>




                            </div>
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
            <Footer />
        </>
    )
}

export default OrderDetail