import React, { useEffect, useState } from 'react'
import Navigation from './Navigation'
import MainPage from './MainPage'
import OurServices from './OurServices'
import WhyChooseUs from './WhyChooseUs'
import Footer from './Footer'
import TrendingGadgets from './TrendingGadgets'
import Testimonials from './Testimonials'
import HowWeWork from './HowWeWork'
import Dealer from './Dealer'
import { Modal, Row, Col, Container, Image } from "react-bootstrap"
import { FiArrowRight } from "react-icons/fi";
import delhi from "../Images/placeIcon/india-gate.png"
import castle from "../Images/placeIcon/castle.png"
import mumbai from "../Images/placeIcon/gate-of-india.png"
import bangalore from "../Images/placeIcon/bangalore.png"
import hyderabad from "../Images/placeIcon/hyderabad-charminar.png"
import gurugram from "../Images/placeIcon/bulding.png"
import Sculpture from "../Images/placeIcon/sculpture.png"
import Chennai from "../Images/placeIcon/sri-ranganathasvamy.png"
import bhu from "../Images/placeIcon/bhu.png"
import "../ComponentsCss/Home.css"
import WorkFlow from '../Components/WorkFlow'
import { Helmet } from 'react-helmet'

export default function Home() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   handleShow()
  // }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])





  return (
    <>
      <Helmet>
        <title>Buy, Sell, and Exchange Old, Used Phones, Laptops, and Tablets</title>
        <meta name="description" content="Discover the power of emotion in buying, selling, and exchanging used mobiles, laptops, and other gadgets with HelloFi. Best prices and offers are guaranteed." />
   
      </Helmet>


      <Navigation />
      <MainPage />
      <OurServices />
      <Dealer />
      <WorkFlow />
      <HowWeWork />
      <WhyChooseUs />
      <TrendingGadgets />
      {/* <FreeshipingBaner/> */}
      <Testimonials />
      <Footer />

      <Modal
        show={show}
        onHide={handleClose}

        size='lg'
      >
        <Container>
          <h5 style={{ marginTop: 20, marginLeft: 10, marginBottom: 20, textAlign: "center" }}>Select Pickup Location</h5>
          <Row>
            <Col md={1}></Col>
            <Col md={10}>
              <div style={{ display: "flex", border: "1px solid #000", marginBottom: 10, padding: 6, borderRadius: 10 }}>
                <input type="number" style={{ width: "100%", border: "none", outline: "none" }} placeholder='Enter your pincode' onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClose()
                  }
                }} />
                <FiArrowRight style={{ marginTop: 4 }} size={25} />
              </div>
              <h5 style={{ marginTop: 20, marginLeft: 10, marginBottom: 20, textAlign: "center" }}>Enter Your City</h5>
              <div style={{ display: "flex", border: "1px solid #000", marginBottom: 10, padding: 6, borderRadius: 10 }}>
                <input type="text" style={{ width: "100%", border: "none", outline: "none" }} placeholder='Enter your City' onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleClose()
                  }
                }} />
                <FiArrowRight style={{ marginTop: 4 }} size={25} />
              </div>

              <Row style={{ marginTop: 20 }}>
                <Col><div style={{ width: "100%", background: "#000", height: 1, marginTop: 5 }}></div></Col>
                <Col ><h6 style={{ fontSize: 15, textAlign: "center" }}>Or Select you city</h6></Col>
                <Col><div style={{ width: "100%", background: "#000", height: 1, marginTop: 5 }}></div></Col>
              </Row>
            </Col>
            <Col md={1}></Col>
          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={delhi} style={{ width: 50, height: 50 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 5, fontSize: 14 }}>Delhi</h6>
            </Col>

            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={mumbai} style={{ width: 50, height: 50 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 5, fontSize: 14 }}>Mumbai</h6>
            </Col>
            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={castle} style={{ width: 50, height: 50, marginTop: 8 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 5, fontSize: 14 }}>Pune</h6>
            </Col>

          </Row>

          <Row style={{ marginTop: 10 }}>
            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={bhu} style={{ width: 50, height: 50 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 3, fontSize: 14 }}>Bhubaneswar</h6>
            </Col>

            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={hyderabad} style={{ width: 50, height: 50 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 3, fontSize: 14 }}>Hyderabad</h6>
            </Col>
            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={bangalore} style={{ width: 50, height: 50, marginTop: 8 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 0, fontSize: 14 }}>Bengaluru</h6>
            </Col>

          </Row>

          <Row style={{ marginTop: 10, marginBottom: 50 }}>
            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={Chennai} style={{ width: 50, height: 50 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 3, fontSize: 14 }}>Chennai</h6>
            </Col>

            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={Sculpture} style={{ width: 50, height: 50 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 3, fontSize: 14 }}>Kolkata</h6>
            </Col>
            <Col md={4} xs={4}>
              <div className='place-Box mx-auto d-block'>
                <Image src={gurugram} style={{ width: 50, height: 50, marginTop: 8 }} className='mx-auto d-block' />
              </div>
              <h6 style={{ textAlign: "center", marginTop: 0, fontSize: 14 }}>Guwahati</h6>
            </Col>

          </Row>

        </Container>

      </Modal>

    </>
  )
}
