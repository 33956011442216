import React, { useState } from 'react'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import '../ComponentsCss/Footer.css'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaLongArrowAltRight, FaRegEnvelope, FaTelegramPlane, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import CategoryModal from './HomePageModals/Category'
import BuyCategoryModal from './HomePageModals/BuyCategoryModal'

export default function Footer() {
    const handleLinksClick = (e) => {
        e.preventDefault()
    }
    const [showCategory, setShowCategory] = useState(false)
    const [showCategory2, setShowCategory2] = useState(false)

    return (
        <>
            <Container className='footermain' fluid>
                <Row>
                    <Col md={3}>
                        <div className='footertxt mx-auto d-block'>

                            <p> <Link className='lnktxt' to="/contact" >Contact Us</Link></p>
                            <p> <Link className='lnktxt' to="/faq" >FAQ</Link></p>
                            <p> <Link className='lnktxt' to="/careers">Career</Link></p>
                            <p><Link className='lnktxt' to="/aboutus" >About Us</Link></p>
                            <p><Link className='lnktxt' to="/terms-condition" >Term & Conditions</Link></p>
                        </div>
                        <hr className='hrfooter'></hr>
                    </Col>
                    <Col md={3}>
                        <div className='footertxt mx-auto d-block'>
                            {/* <p><Link className='lnktxt' to="/contact" >PLACE RETURN REQUEST</Link></p> */}
                            <p>  <Link className='lnktxt' to="/privacy-policy" >PRIVACY POLICY</Link></p>
                            <p className="text-uppercase">  <Link className='lnktxt' to="/shipping-delivery-policy"  >Shipping and delivery policy</Link></p>
                            <p className="text-uppercase">  <Link className='lnktxt' to="/return-refund-policy"  >Return and Refund Policy</Link></p>
                            {/* <p> <Link className='lnktxt'>TRACK YOUR ORDER</Link></p> */}

                        </div>
                        <hr className='hrfooter'></hr>
                    </Col>
                    <Col md={3}>
                        <div className='footertxt mx-auto d-block'>

                            <h4 style={{ fontSize: 14, letterSpacing: 3 }}>CUSTOMER CARE</h4>

                            <p style={{ marginTop: 10 }}>Timings: 11AM - 8PM (MON - SUN)
                                <br />Call: <a href="tel:+918150835583" style={{ color: '#000000' }}>  +91 8150835583 </a>
                                <br />
                                E-mail: <a href="mailto:contact@hellofi.in" style={{ color: '#000000', textDecoration: 'underline', }}>contact@hellofi.in</a></p>

                        </div>
                        <hr className='hrfooter'></hr>
                        <div className='d-flex justify-content-start'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.6765468479884!2d77.6334457748406!3d12.928498587382931!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae150c3f13efb3%3A0x7c63fb5ed43e5ec!2sHelloFi%20Recommerce!5e0!3m2!1sen!2sin!4v1702113122992!5m2!1sen!2sin" width="320" height="100" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                        </div>
                    </Col>
                    <Col md={3}>
                        <div className='footertxt mx-auto d-block'>
                            <h4 style={{ fontSize: 14, letterSpacing: 3 }}>SIGN UP AND SAVE</h4>
                            <p style={{ fontSize: 12, marginTop: 10 }}>Sign up now and be the first to know about exclusive offers, latest updates.</p>

                            {/* <Button className='btnfooter'>Chat With Us <FaLongArrowAltRight /></Button> */}
                        </div>
                        <div>
                            <Form>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <div className='foottxtin'> <Form.Control className='foottext' type="email" placeholder="Enter email" />
                                        <Link to={"mailto:contact@hellofi.in"} >
                                            <FaRegEnvelope size={30} style={{ cursor: 'pointer' }} className='text-dark' />
                                        </Link>
                                    </div>
                                </Form.Group>
                            </Form>
                            <div className='iconsfoot'>
                                <div className='socialicn'>
                                    <a href='https://www.facebook.com/profile.php?id=100087955281339&mibextid=ZbWKwL' target='_blank' >
                                        <FaFacebookF style={{ color: '#ffffff' }} />
                                    </a>

                                </div>
                                <div className='socialicn' style={{ marginLeft: 10 }}>
                                    <a href='https://wa.me/message/LVQPSJWMOT57O1' target='_blank' >

                                        <FaWhatsapp style={{ color: '#ffffff' }} />
                                    </a>

                                </div>
                                {/* <div className='socialicn' style={{ marginLeft: 10 }}>
                                    <a href='' target='_blank' >

                                        <FaTelegramPlane style={{ color: '#ffffff' }} />
                                    </a>

                                </div> */}
                                <div className='socialicn' style={{ marginLeft: 10 }}>
                                    <a href='https://www.instagram.com/hellofi_recommerce?igshid=NTc4MTIwNjQ2YQ' target='_blank' >

                                        <FaInstagram style={{ color: '#ffffff' }} />
                                    </a>

                                </div>
                                <div className='socialicn' style={{ marginLeft: 10 }}>
                                    <a href='https://twitter.com/hellofi_recom' target='_blank' >

                                        <FaTwitter style={{ color: '#ffffff' }} />
                                    </a>

                                </div>
                                <div className='socialicn' style={{ marginLeft: 10 }}>
                                    <a href='https://www.linkedin.com/company/hellofi-recommerce/' target='_blank' >

                                        <FaLinkedinIn style={{ color: '#ffffff' }} />
                                    </a>
                                </div>
                            </div>


                        </div>
                        <hr className='hrfooter'></hr>
                    </Col>
                </Row>
                <Row>

                    <Col md={9} style={{ fontSize: 13, width: "100%" }} >
                        <div className='footertxt mx-auto d-block' >
                            <h4 style={{ fontSize: 18 }}>Quick Links</h4>


                            <p onClick={() => setShowCategory(true)} style={{cursor :"pointer"}} >
                                <a className='text-blue' >sell Old Mobile Phones In Mumbai</a> | 
                                <a className='text-blue'>sell Old Mobile Phones In Hyderabad</a> |
                                <a className='text-blue'>sell Old Laptops in Mumbai</a> |
                                <a className='text-blue'>sell Old Laptops in Mumbai</a> |
                                <a className='text-blue'>sell Old Laptops in Mumbai</a> |
                                <a className='text-blue'>sell Tablets in Mumbai</a> |
                                <a className='text-blue'>sell old apple macbook in Mumbai</a> |
                                <a className='text-blue'>sell apple iPad in Mumbai</a> |
                                <a className='text-blue'>sell apple iPhone in Mumbai</a> |
                                <a className='text-blue'>sell Old Mobile Phones In Hyderabad</a> |
                                <a className='text-blue'>sell Old Laptops in Mumbai</a> |
                                <a className='text-blue'>sell Tablets in Mumbai</a> |
                                <a className='text-blue'>sell old apple macbook in Mumbai</a> |
                                <a className='text-blue'>sell apple iPad in Mumbai</a> |
                                <a className='text-blue'>sell apple iPhone in Mumbai</a> |
                                <a className='text-blue'>sell Old Mobile Phones In Bangalore</a> |
                                <a className='text-blue'>sell Old Mobile Phones In Kolkata</a> |
                                <a className='text-blue'>sell Old Laptops in Bangalore</a> |
                                <a className='text-blue'>sell Tablets in Bangalore</a> |
                                <a className='text-blue'>sell old apple macbook in Bangalore</a> |
                                <a className='text-blue'>sell apple iPad in Bangalore</a> |
                                <a className='text-blue'>sell apple iPhone in Bangalore</a> |
                                <a className='text-blue'>sell macmini in Bangalore</a> |
                                <a className='text-blue'>sell macmini in Kolkata</a> |
                                <a className='text-blue'>sell macmini in Mumbai</a> |
                                <a className='text-blue'>sell macmini in Bhubaneswar</a> |
                                <a className='text-blue'>sell Old Mobile Phones In Bhubaneswar</a> |
                                <a className='text-blue'>sell Old Mobile Phones In Agartala</a> |
                                <a className='text-blue'>sell Old Laptops in Kolkata</a> |
                                <a className='text-blue'>sell Tablets in Kolkata</a> |
                                <a className='text-blue'>sell old apple macbook in Kolkata</a> |
                                <a className='text-blue'>sell apple iPad in Kolkata</a> |
                                <a className='text-blue'>sell apple iPhone in Kolkata</a> |
                                <a className='text-blue'>sell Old Mobile Phones In Koramangala</a> |
                                <a className='text-blue'>sell Old Laptops in Koramangala</a> |
                                <a className='text-blue'>sell Tablets in Koramangala</a> |
                                <a className='text-blue'>sell old apple macbook in Koramangala</a> |
                                <a className='text-blue'>sell apple iPad in Koramangala</a> |
                                <a className='text-blue'>sell apple iPhone in Koramangala</a> |
                                <a className='text-blue'>sell smartwatch in Mumbai</a> |
                                <a className='text-blue'>sell smartwatch in Bangalore</a> |
                                <a className='text-blue'>sell smartwatch in Bhubaneswar</a> |
                                <a className='text-blue'>sell smartwatch in Hyderabad</a> |
                                <a className='text-blue'>sell smartwatch in Kolkata</a> |
                                <a className='text-blue'>sell old phone</a> |
                                <a className='text-blue'>sell mobile online</a> |
                                <a className='text-blue'>sell apple mobile</a> |
                                <a className='text-blue'>sell Samsung mobile</a> |
                                <a className='text-blue'>sell OnePlus mobile</a> |
                                <a className='text-blue'>sell Oppo mobile</a> |
                                <a className='text-blue'>sell Realme mobile</a> |
                                <a className='text-blue'>sell Nothing mobile</a> |
                                <a className='text-blue'>sell Xiaomi mobile</a> |
                                <a className='text-blue'>sell iQOO mobile</a> |
                                <a className='text-blue'>sell Google mobile</a> |
                                <a className='text-blue'>sell Vivo mobile</a> |
                                <a className='text-blue'>sell used apple laptop</a> |
                                <a className='text-blue'>sell used Dell laptop</a> |
                                <a className='text-blue'>sell used HP laptop</a> |
                                <a className='text-blue'>sell used Lenovo laptop</a> |
                                <a className='text-blue'>sell used Samsung laptop</a> |
                                <a className='text-blue'>sell used Asus laptop</a> |
                                <a className='text-blue'>sell used Acer laptop</a> |
                                <a className='text-blue'>sell used Xiaomi laptop</a> |
                                <a className='text-blue'>sell used apple iPads</a> |
                                <a className='text-blue'>sell used Samsung tablets</a> |
                                <a className='text-blue'>sell used Mi pads</a> |
                                <a className='text-blue'>sell used OnePlus tabs</a> |
                                <a className='text-blue'>sell used apple smartwatch</a> |
                                <a className='text-blue'>sell used Samsung smartwatch</a> |
                                <a className='text-blue'>sell iWatch online</a> |
                                <a className='text-blue'>sell electronic gadgets</a> |
                                <a className='text-blue'>sell macmini</a> |
                                <a className='text-blue'>sell mac studio</a> |
                                <a className='text-blue'>sell camera</a> 

                            </p>
                            <p onClick={() => setShowCategory2(true)}>
                                <Link>buy old laptop</Link> |
                                <Link>buy apple macbook</Link> |
                                <Link>buy used apple macbook</Link> |
                                <Link>buy used macbook</Link> |
                                <Link>buy apple iphone</Link> |
                                <Link>old phones buy</Link> |
                                <Link>mobile for cash</Link> |
                                <Link>buy used apple ipad</Link> |
                                <Link>buy used tablet</Link> |
                                <Link>buy apple smartwatch</Link> |
                                <Link>buy iphone in bangalore</Link> |
                                <Link>buy used phones</Link> |
                                <Link>buy second hand mobile</Link> |
                                <Link>second hand mobile phone</Link> |
                                <Link>buy used apple mobile</Link> |
                                <Link>buy used Samsung mobile</Link> |
                                <Link>buy used oneplus mobile</Link> |
                                <Link>buy used oppo mobile</Link> |
                                <Link>buy used realme mobile</Link> |
                                <Link>buy used nothing mobile</Link> |
                                <Link>buy used xiaomi mobile</Link> |
                                <Link>buy used iqoo mobile</Link> |
                                <Link>buy used google mobile</Link> |
                                <Link>buy used vivo mobile</Link> |
                                <Link>buy used apple laptop</Link> |
                                <Link>buy used dell laptop</Link> |
                                <Link>buy used hp laptop</Link> |
                                <Link>buy used Lenovo laptop</Link> |
                                <Link>buy used Samsung laptop</Link> |
                                <Link>buy used asus laptop</Link> |
                                <Link>buy used acer laptop</Link> |
                                <Link>buy used xiaomi laptop</Link> |
                                <Link>buy used apple ipads</Link> |
                                <Link>buy used Samsung tablets</Link> |
                                <Link>buy used mi pads</Link> |
                                <Link>buy used oneplus tabs</Link> |
                                <Link>buy used apple smartwatch</Link> |
                                <Link>buy used Samsung smartwatch</Link> |
                                <Link>buy used iwatch online</Link> |
                                <Link>buy used electronic gadgets</Link> |
                                <Link>buy used macmini</Link> |
                                <Link>buy used mac studio</Link> |
                                <Link>buy used camera</Link>

                            </p>
                        </div>
                    </Col>
                    {/* <Col md={3}>
                        <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <div className='foottxtin'> <Form.Control className='foottext' type="email" placeholder="Enter email" />
                                    <FaRegEnvelope size={30} style={{ cursor: 'pointer' }} />
                                </div>
                            </Form.Group>
                        </Form>
                        <div className='iconsfoot'>
                            <div className='socialicn'>
                                <FaFacebookF style={{ color: '#ffffff' }} />
                            </div>
                            <div className='socialicn' style={{ marginLeft: 10 }}>
                                <FaWhatsapp style={{ color: '#ffffff' }} />
                            </div>
                            <div className='socialicn' style={{ marginLeft: 10 }}>
                                <FaTelegramPlane style={{ color: '#ffffff' }} />
                            </div>
                            <div className='socialicn' style={{ marginLeft: 10 }}>
                                <FaInstagram style={{ color: '#ffffff' }} />
                            </div>
                            <div className='socialicn' style={{ marginLeft: 10 }}>
                                <FaTwitter style={{ color: '#ffffff' }} />
                            </div>
                            <div className='socialicn' style={{ marginLeft: 10 }}>
                                <FaLinkedinIn style={{ color: '#ffffff' }} />
                            </div>
                        </div>


                    </Col> */}
                </Row>
                <h6 style={{ textAlign: 'center', marginTop: '30px', fontSize: '13px' }}>© copyright 2023 Hellofi Recommerce all rights reserved | Design & maintain by <a href='https://softseekersinfotech.com/' target='_blank' style={{ textDecoration: 'none' }}>Softseekers infotech pvt. ltd.</a></h6>
            </Container>
            {
                showCategory ? <CategoryModal showCategory={showCategory} setShowCategory={setShowCategory} /> : ""
            }
                        {
                showCategory2 ? <BuyCategoryModal showCategory2={showCategory2} setShowCategory2={setShowCategory2} /> : ""
            }
        </>
    )
}

