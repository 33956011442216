import React, { useEffect, useState } from "react";
import { MUTATION_CREATE_CART_ITEM } from "../../graphql/Mutations";
import { MUTATION_DELETE_CART_ITEM } from "../../graphql/Mutations";
import { MUTATION_UPDATE_CART_ITEM } from "../../graphql/Mutations";
import { MUTATION_CREATE_BUY_ORDER } from "../../graphql/Mutations";
import { QUERY_GET_CART_ITEM_BY_ID } from "../../graphql/Query";
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";
const CartContext = React.createContext();

const CartProvider = (props) => {
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate()

    const [cartData, setCartdata] = useState([]);
    const [productQuantity, setProductQuantity] = useState(1)
    const [totalAmount, setTotalAmount] = useState(0);
    const { data: getCartItemsByUserId, loading: dataLoading } = useQuery(QUERY_GET_CART_ITEM_BY_ID,
        {
            variables:
            {
                "userId": userId
 
            }
        });
    useEffect(() => {
        if (getCartItemsByUserId)
            setCartdata(getCartItemsByUserId?.getCartItemsByUserId)

    }, [getCartItemsByUserId])

    const [createCartItem, { loading: Loading }] = useMutation(MUTATION_CREATE_CART_ITEM
        , {
            refetchQueries: [
                QUERY_GET_CART_ITEM_BY_ID
            ]
        }
    )
    const [deleteCartItem, { loading: delLoading }] = useMutation(MUTATION_DELETE_CART_ITEM
        , {
            refetchQueries: [
                QUERY_GET_CART_ITEM_BY_ID
            ]
        }
    )
   
    const [createBuyOrder] = useMutation(MUTATION_CREATE_BUY_ORDER
        // , {
        //     refetchQueries: [
        //         QUERY_All_BUY_ORDERS
        //     ]
        // }
    )

    const [updateCartItem, { loading: updateLoading }] = useMutation(MUTATION_UPDATE_CART_ITEM
        , {
            refetchQueries: [
                QUERY_GET_CART_ITEM_BY_ID
            ]
        }
    )
    const updateProductCount = (item, id, count, op, quantity) => {
        let updatableCount = parseInt(count);
        if (op === "+") {
            if (updatableCount === parseInt(quantity)) {
                return;
            }
            updatableCount++;
        } else {
            updatableCount--;
        }
        if (updatableCount === 0) {
            removeItem(item);
        } else {
            updateCartItem({
                variables: {
                    cartItemInput: {
                        id: `${id}`,
                        quantity: `${updatableCount}`
                    }
                }
            }).then(() => {
                // Any additional logic after updating cart item
            });
        }
    };
    


    const addToCart = (data,isBuy) => {
        if(isBuy){
            emptyCart()
        }
        createCartItem({
            variables: {
                "cartItemInput": {
                    "productId": `${data.id}`,
                    "userId": `${userId}`,
                    "productUniqueId": `${data.productUniqueId}`,
                    "quantity": `${"1"}`,
                 
                }
            }
        }).then(() => {
        
            if(isBuy) {
                // navigate("add-buy-address")
            }else{
                navigate("/buying/cart")

            }

        })
        
        // setCartdata([...cartData, data]);
    }

    const removeItem = (data) => {

        deleteCartItem({
            variables: {
                "itemId": `${data.id}`
            }
        }).then(() => {
            swal({
                title: "Success",
                text: "Product deleted from Cart",
                icon: "success",
            })
        })
        const newItems = cartData.filter((item) => item.id !== data.id)
        setCartdata(newItems);
    }
    const emptyCart =()=>{
       
        for (let x of cartData) {

            deleteCartItem({
                variables: {
                    "itemId": `${x.id}`
                }
            }).then(() => {
               
            })
            
          }
    
    }

    
    return (
        <CartContext.Provider value={{ cartData, addToCart, removeItem, totalAmount, updateProductCount,emptyCart }}>
            {props.children}
        </CartContext.Provider>
    )
}
export { CartContext, CartProvider };