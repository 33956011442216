import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Image, Button, Spinner } from 'react-bootstrap';
import Navigation from '../Navigation';
import success from "../../Images/success.png";
import { useQuery, useMutation } from '@apollo/client';
import { GET_BUYORDER_BY_PAYMENT_ID } from '../../graphql/Query';
import { useNavigate, useParams } from 'react-router-dom';
import { MUTATION_CHECK_PAYMENT, MUTATION_ORDER_PAYMENT_UPDATE } from '../../graphql/Mutations';
import failed from "../../Images/failed.png";

export default function Status() {
  let { id } = useParams();
  const { data, loading } = useQuery(GET_BUYORDER_BY_PAYMENT_ID, {
    variables: {
      "paymentId": `${id}`
    }
  });

  const [paymentStatus, setPaymentStatus] = useState("pending");

  useEffect(() => {
    if (data) {
      checked();
    }
  }, [data]);

  const [checkPhonePePayment] = useMutation(MUTATION_CHECK_PAYMENT);
  const [buyOrderUpdate] = useMutation(MUTATION_ORDER_PAYMENT_UPDATE);

  const checked = async () => {
    const { data: checkData } = await checkPhonePePayment({
      variables: {
        transactionId: `${id}`,
      },
    });

    if (checkData && checkData.checkPhonePePayment === "PAYMENT_SUCCESS") {
      await buyOrderUpdate({
        variables: {
          "buyOrderUpdateInput": {
            "buyOrderId": `${data.getBuyOrderPaymentId.id}`,
            "paymentStatus": "Successfully"
          }
        }
      });
      setPaymentStatus("success");
    } else {
      setPaymentStatus("failed");
    }
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate('/userDashboard/yourorder');
  };

  return (
    <>
      <Navigation />
      {loading ? (
        <Spinner animation="border" role="status" className="mx-auto d-block">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      ) : (
        <Container>
          <Card>
            <Row>
              <Col md={3}></Col>
              <Col md={6}>
                {paymentStatus === "success" ? (
                  <>
                    <Image src={success} style={{ width: "60%", height: "75%" }} className='mx-auto d-block' />
                    <h6 style={{ textAlign: "center", color: "green" }}>Payment Successfully Done !!!</h6>
                    <Button className='mx-auto d-block' onClick={handleClick}>View Order</Button>
                  </>
                ) : paymentStatus === "failed" ? (
                  <>
                    <Image src={failed} style={{ width: "60%", height: "90%" }} className='mx-auto d-block' />
                    <h6 style={{ textAlign: "center", color: "red" }}>Payment Failed !!!</h6>
                  </>
                ) : null}
              </Col>
              <Col md={3}></Col>
            </Row>
          </Card>
        </Container>
      )}
    </>
  );
}
