import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { GET_QUERY_ALL_COUPON } from '../../graphql/Query'
import { MUTATION_CREATE_COUPON } from '../../graphql/Mutations'
import { MUTATION_DELETE_COUPON } from '../../graphql/Mutations'
import swal from 'sweetalert';



const AddCoupon = () => {
    let count = 1

    const [couponState, setCouponState] = useState("");
    const [couponPercentState, setPerCouponState] = useState("");
    const [couponCategory, setCouponCategory] = useState("")
    const [validated, setValidated] = useState("");

    const { data: getAllCoupon, loading: CouponLoading } = useQuery(GET_QUERY_ALL_COUPON);

    const [createCoupon, { loading: createLoading }] = useMutation(MUTATION_CREATE_COUPON, {
        refetchQueries: [
            GET_QUERY_ALL_COUPON
        ]
    })
    const [deleteCoupon, { loading: dltLoading }] = useMutation(MUTATION_DELETE_COUPON, {
        refetchQueries: [
            GET_QUERY_ALL_COUPON
        ]
    })
    const handleDelete=(id)=>{
        deleteCoupon({
            variables :{
                "couponId":id
            }
        }).then(() => {

            swal({
                title: "Success",
                text: "deleted successfully",
                icon: "success",
            })
    
        }
            
        ).catch((e)=> alert(e))
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        createCoupon({
            variables: {
                "couponInput": {
                    // "category": couponCategory,
                    "couponPercent": `${couponPercentState}`,
                    "coupon": `${couponState}`
                }
            }
        }).then(() => {

            swal({
                title: "Success",
                text: "coupon added successfully",
                icon: "success",
            })
            setCouponCategory("")
            setPerCouponState("")
            setCouponState("")
        }
            
        ).catch((e)=> alert(e))
    }
    return (
        <>
            <Container style={{ padding: 20 }} fluid>
                <Row>
                    <Col md={4}>
                        <div style={{ padding: "1rem" }} className='box-shadow'>
                            <h3 style={{ textAlign: 'center' }}>Add Coupon Here</h3>
                            <Col>
                                {/* <Form.Group as={Col} md="12" controlId="validationCustom02">

                                    <Dropdown style={{ marginTop: "1rem" }} >
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='w-100' >
                                            Category
                                        </Dropdown.Toggle>
                                        <span> {couponCategory} </span>
                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}className='w-100'>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Phones")}>Phones </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Laptops")}>Laptops </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Tablets")}>Tablets </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Smart Watches")}> Smart Watches </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Desktop/Imac")}> Desktop/Imac </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setCouponCategory("Other Accessories")}> Other Accessories </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group> */}
                            </Col>
                            <Col>
                                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="validationCustom01">
                                            <Form.Control
                                                required
                                                type="text"
                                                className='txtinpt'
                                                placeholder="coupon"
                                                onChange={(e) => setCouponState(e.target.value)}
                                                value={couponState}
                                            />
                                            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                        </Form.Group>

                                    </Row>
                                </Form>

                            </Col>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="number"
                                            className='txtinpt'
                                            placeholder="percent"
                                            onChange={(e) => setPerCouponState(e.target.value)}
                                            value={couponPercentState}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                    <Button type='submit' variant='dark' className='mt-5' disabled={ couponState && couponPercentState  ? false : true} >  Add Coupon </Button>
                                </Row>
                            </Form>

                        </div>
                    </Col>
                    <Col>
                        <Table responsive striped bordered hover>
                            <thead className='table-head'>
                                <tr>
                                    <th>S.no.</th>
                                    {/* <th>Category</th> */}
                                    <th>Coupon</th>
                                    <th>Percent</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getAllCoupon && getAllCoupon?.getAllCoupon?.map((coupon) => {
                                        return (
                                            <tr>
                                                <td> {count++} </td>
                                                {/* <td> {coupon?.category} </td> */}
                                                <td> {coupon?.coupon} </td>
                                                <td> {coupon?.couponPercent} </td>
                                                <td> <Button variant='danger' onClick={() => handleDelete(coupon.id)} >Delete</Button> </td>
                                            </tr>
                                        )
                                    })
                                }
                                <td>  </td>
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddCoupon 