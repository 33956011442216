import React from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import '../../ComponentsCss/Ourservices.css'
import Card1 from '../../Images/homepage/card1.png'
import Card2 from '../../Images/homepage/card2.png'
import Card3 from '../../Images/homepage/card3.png'
import Card4 from '../../Images/homepage/card4.png'


export default function ProductAssured() {
    return (
        <>
            <Container style={{ marginTop: '20px' ,marginBottom:"20px" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 40, width: 5, backgroundColor: '#44CE30', marginTop: 20 }}></div>
                    <h3 style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}> HelloFi Assured</h3>
                </div>
                <Row>
                    <Col md={3}>
                        <Card className='newservice mx-auto d-block' style={{ padding: "1rem" }} >
                            <div className='txtsev ' >
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17, fontWeight: "bold" }}>32 Points Quality Checks</h4>
                                </div>
                                <Image src={Card1} style={{ height: '70%', width: '65%' }} ></Image>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='newservice mx-auto d-block' >
                            <div className='txtsev'>
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17, fontWeight: "bold" }}>Easy Replacement Policy.</h4>
                                </div>
                                <Image src={Card2} style={{ height: '70%', width: '65%' }} responsive></Image>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='newservice mx-auto d-block' >
                            <div className='txtsev'>
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17, fontWeight: "bold" }}>3 Months HelloFi Service Warranty.</h4>
                                </div>
                                <Image src={Card3} style={{ height: '75%', width: '69%' }} responsive></Image>
                            </div>
                        </Card>
                    </Col>
                    <Col md={3}>
                        <Card className='newservice mx-auto d-block' >
                            <div className='txtsev'>
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17, fontWeight: "bold" }}>Lifetime On Call Assistance Post Sales.</h4>
                                </div>
                                <Image src={Card4} style={{ height: '75%', width: '69%' }} responsive></Image>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
