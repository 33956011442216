
import React, { useState } from 'react'
import { QUERY_GET_BUYING_ORDERS_USERID } from '../../graphql/Query'
import { useMutation, useQuery } from '@apollo/client';
import { Container, Col, Row, Card, Breadcrumb, Spinner, Modal, Button, Image, Toast } from 'react-bootstrap';
import '../../ComponentsCss/userCss/BuyOrder.css'
import { useNavigate } from 'react-router-dom';
import Moment from 'react-moment';
import CardHeader from 'react-bootstrap/esm/CardHeader';
// import { TbAbacus } from 'react-icons/fa';

const UserBuyOrder = () => {
    const userId = localStorage.getItem('userId')
    const [selectedOrder, setSelectedOrder] = useState()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    let totalOrderprice = 0
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const navigate = useNavigate()
    const { data: getBuyOrdersByUserId, loading: ordersLoading } = useQuery(QUERY_GET_BUYING_ORDERS_USERID,
        {
            variables:
            {
                "userId": userId
            }
        });

    const handelOrderdetails = (order) => {
        setSelectedOrder(order)
        totalOrderprice = 0
        handleShow()
    }
    const handelViewAddress = (order) => {
        setSelectedOrder(order)
        // totalOrderprice = 0
        handleShow2()
    }


    const initialDate = new Date(selectedOrder?.createdDateTime);

    // Calculate the date 7 days later
    const sevenDaysLater = new Date(initialDate);
    sevenDaysLater.setDate(initialDate.getDate() + 7);

    return (
        <>
            {window.scrollTo(0, 0)}
            <Container style={{ padding: "0px" }} fluid>
                {
                    ordersLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
                        getBuyOrdersByUserId && getBuyOrdersByUserId?.getBuyOrdersByUserId?.slice().reverse().map((order) => {
                            let deliverdate = new Date(order?.createdDateTime);
                            const currentDate = new Date(order?.createdDateTime);
                            currentDate.setDate(currentDate.getDate() + 7);
                            const options = { month: "short", day: "2-digit" };
                            const deliveredDate = currentDate.toLocaleDateString(undefined, options)


                            return (
                                <Card className='mb-3'>

                                    <CardHeader>
                                        <Container>
                                            <Row>

                                                <Col xl={3} xs={12} className='orderhead'> <div>Order Placed :  </div> <div className='fw-bold'>  <Moment format="DD/MM/YYYY">{order.createdDateTime}</Moment> </div>  </Col>
                                                <Col xl={2} xs={12} className='orderhead'> <div>   Total : </div> <div className='fw-bold'>   ₹ {order.totalprice} </div> </Col>
                                                <Col xl={2} xs={12} className='orderhead'> <div>   status : </div> <div className='fw-bold'>    {order.status} </div> </Col>
                                                <Col xl={2} xs={12} className='address orderhead'><div>   Delivery Address : </div>

                                                    <p onClick={()=>handelViewAddress(order)} className="mb-2 text-primary" style={{ cursor: "pointer" }} >
                                                        View Details
                                                    </p>

                                                </Col>

                                                <Col xl={3} xs={12} className='orderhead '> <div>   Order Id : </div>  <div className='fw-bold'>  {order.orderNo} </div> </Col>
                                            </Row>
                                        </Container>
                                    </CardHeader>
                                    <Card.Body>

                                        {order?.productDetails.map((product) => {



                                            return (
                                                <>

                                                    <div className='productdetailCard row my-2'>

                                                        <div className='buyorderimg col-xl-2 col-4'  >
                                                            <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.productImage}`} fluid className='orderimg' />


                                                        </div>


                                                        <div className='col-xl-3 col-7'>
                                                            Product Name <br />
                                                            <div className='fw-bold' > {product?.productName} </div>
                                                            <Button className="orderv mt-2" variant='success' size="sm" onClick={() => handelOrderdetails(order)} >Order Details </Button>
                                                        </div>
                                                        <div className='col-xl-3 col-6'>
                                                            <div className='codi'>Product Condition </div>
                                                            <div className='fw-bold' > {product?.condition} </div>

                                                        </div>
                                                        <div className='priceQuant col-xl-2 col-5'>
                                                            <div className='productPrice' > ₹ {product?.productPrice} </div>
                                                            <div> Qty : <span> {product?.quantity} </span> </div>
                                                        </div>
                                                    </div>
                                                    <div className='d-flex justify-content-center' >
                                                        <div className='fw-bold' >Payment Status :   </div>
                                                        {
                                                            order.paymentMethod == "COD" ? <div className='fw-bold text-success' style={{ marginLeft: '5px' }} > COD  </div> : order?.paymentStatus == "Successfully" ? <div className='text-center fw-bold text-success ' style={{ marginLeft: '5px' }}>Completed </div>
                                                                : <div className='fw-bold text-danger' style={{ marginLeft: '5px' }} > Failed </div>
                                                        }

                                                        {/* {
                                                            
                                                        } */}

                                                    </div>
                                                </>
                                            )
                                        })
                                        }


                                    </Card.Body>
                                    <Modal show={show} onHide={handleClose} size='lg' centered >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Oders Details</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <div className='ms-2 fw-bold'>
                                                Payment Method : {selectedOrder?.paymentMethod}
                                            </div>

                                            <div className='buycardHeader'>
                                                <div className='text-success'  >
                                                    Estimated delivery : <Moment format="DD/MM/YYYY">{sevenDaysLater}</Moment></div>
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleClose}>
                                                Close
                                            </Button>

                                        </Modal.Footer>
                                    </Modal>
                                    <Modal show={show2} onHide={handleClose2} size='lg' centered>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Deliver To</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className='d-flex flex-column align-items-start'>
                                            <div className='mb-3'>
                                                <span className='fw-bold'>Name :</span> {selectedOrder?.fName}
                                            </div>
                                            <div className='mb-3'>
                                                <span className='fw-bold'>Address:</span> {selectedOrder?.address}
                                            </div>
                                            <div className='mb-3'>
                                                <span className='fw-bold'>City:</span> {selectedOrder?.city}
                                            </div>
                                            <div className='mb-3'>
                                                <span className='fw-bold'>State:</span> {selectedOrder?.state}
                                            </div>
                                            <div className='mb-3'>
                                                <span className='fw-bold'>Contact :</span> {selectedOrder?.contact}
                                            </div>
                                            <div className='mb-3'>
                                                <span className='fw-bold'>Email :</span> {selectedOrder?.email}
                                            </div>

                                            {selectedOrder?.landmark && (
                                                <div className='mb-3'>
                                                    <span className='fw-bold'>Landmark:</span> {selectedOrder?.landmark}
                                                </div>
                                            )}
                                            <div className='mb-3'>
                                                <span className='fw-bold'>Pincode:</span> {selectedOrder?.pincode}
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant='secondary' onClick={handleClose2}>
                                                Close
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>

                                </Card>

                            )
                        }
                        )
                }

            </Container>


        </>
    )
}
export default UserBuyOrder 