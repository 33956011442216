import React, { useState } from 'react'
import { Button, Col, Container, Form, Image, Modal, Row, Spinner, Table, Dropdown } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_DELETE_SELL_PRODUCT, MUTATION_SELL_PRODUCT } from '../../graphql/Mutations';
import { QUERY_GET_ALL_BRANDS } from '../../graphql/Query';
import { QUERY_GET_SELL_PRODUCT } from '../../graphql/Query';
import { QUERY_GET_SERIES } from '../../graphql/Query'

import AWS from "aws-sdk";
import uniqid from "uniqid";
import swal from 'sweetalert';

const ID = "AKIA6GB4RFKTJXDPLPOH";
const SECRET = "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";
const BUCKET_NAME = "sanjaytestbucket";

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
});

export default function SellProctct() {
    let count = 1;
    const [validated, setValidated] = useState(false);
    const [productName, setProductName] = useState("")
    const [productDescription, setProductDescription] = useState("")
    const [variant, setVariant] = useState("")
    const [series, setSeries] = useState("")
    const [productType, setProductType] = useState("")
    const [category, setCategory] = useState("")
    const [productBrand, setProductBrand] = useState("")
    const [productPrice, setProductPrice] = useState("")


    const [imageBox, setImageBox] = useState('')
    const [uploadDone, setUploadDone] = useState(false)
    const [uploadLoading, setUploadLoading] = useState(false)
    const [selectImage, setSelectImage] = useState()
    const [multipleImgKey, setMultipleImgKey] = useState([])
    const { data: getSeries, loading: allBrandLoading } = useQuery(QUERY_GET_SERIES);


    const { data: getAllBrands, loading: brandLoading } = useQuery(QUERY_GET_ALL_BRANDS);

    const { data: sellProData, loading: sellProLoading } = useQuery(QUERY_GET_SELL_PRODUCT)

    const [createSellProduct, { loading: sellLoading }] = useMutation(MUTATION_SELL_PRODUCT, {
        refetchQueries: [
            QUERY_GET_SELL_PRODUCT
        ]
    })

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            setValidated(true);
            event.preventDefault();
            event.stopPropagation();
        } else {
            setValidated(false);
            event.preventDefault();
            try {
                const uniqueId = uniqid();
                var regex = new RegExp('[^.]+$');
                var extension = selectImage.name.match(regex);
                var uniqueFileName = 'hellofi-img' + uniqueId + '.' + extension[0];
                setImageBox(uniqueFileName)
                const params = {
                    Bucket: BUCKET_NAME,
                    Key: uniqueFileName,
                    Body: selectImage,
                };
                s3.upload(params, function (err, data) {
                    if (err) {
                        throw err;
                    } else {
                        swal({
                            title: 'Successfull!!!',
                            text: ' Uploaded Successfully',
                            icon: 'success',
                        });
                        setMultipleImgKey([])
                        setSelectImage('')
                        setUploadLoading(false)
                        setUploadDone(true)
                    }

                });
            } catch (err) {
                swal({
                    title: 'Error!!!',
                    text: 'Error please try again',
                    icon: 'error',
                });
                console.log(err);
                setUploadLoading(false)
            }
            createSellProduct({
                variables: {
                    "sellProductInput": {
                        "productName": `${productName}`,
                        // "productDescription": `${productDescription}`,
                        "variant": `${variant}`,
                        "series": `${series}`,
                        // "productType": `${productType}`,
                        "category": `${category}`,
                        "productBrand": `${productBrand}`,
                        "image": `${uniqueFileName}`,
                        "productPrice": `${productPrice}`

                    }
                }
            })
            swal({
                title: "Success",
                text: "Product Add successfully",
                icon: "success",
            })
            setProductBrand('')
            setProductName('')
            setProductDescription('')
            setVariant('')
            setSeries('')
            setProductType('')
            setCategory('')
            setSelectImage('')
            setProductPrice('')
        }

    };
    const [deleteSellProductByid, { loading: dltLoading }] = useMutation(MUTATION_DELETE_SELL_PRODUCT, {
        refetchQueries: [
            QUERY_GET_SELL_PRODUCT
        ]
    })




    return (
        <>
            <Container style={{ padding: 20 }} fluid >
                <Row>
                    <Col md={4} className='mx-auto d-block'>
                        <div className=' box-shadow' style={{ padding: "1rem" }}>
                            <h5 style={{ textAlign: 'center' }}>Add Selling  Product Here</h5>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control required className='txtinpt' type="text" placeholder="Product name" onChange={(e) => setProductName(e.target.value)} value={productName} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control required type="text" className='txtinpt' placeholder="Product Price" onChange={(e) => setProductPrice(e.target.value)} value={productPrice} />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                   
                                </Row>
                                <Row className="mb-3">
                                    {/* <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="Variant"
                                            onChange={(e) => setVariant(e.target.value)}
                                            value={variant}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                                    {/* <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="Series"
                                            onChange={(e) => setSeries(e.target.value)}
                                            value={series}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                                </Row>
                                <Row className="mb-3">
                                   
                                    <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Dropdown style={{ marginTop: "1rem" }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} > Category</Dropdown.Toggle>
                                            <span>{category}</span>
                                            <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }}>
                                                <div style={{ overflowY: 'scroll', height: '200px' }}>

                                                    <Dropdown.Item onClick={(e) => setCategory("Phones")}>Phones</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => setCategory("Laptops")}>Laptops</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => setCategory("Tablets")}>Tablets</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => setCategory("Smart Watches")}>Smart Watches</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => setCategory("Desktop/Imac")}>Desktop/Imac</Dropdown.Item>
                                                    <Dropdown.Item onClick={(e) => setCategory("Other Accessories")}>Other Accessories</Dropdown.Item>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: 20 }}>
                                        <Dropdown drop='down'>

                                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} >
                                                Select Brand
                                            </Dropdown.Toggle>
                                            <span>
                                                {productBrand}
                                            </span>
                                            <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }} >
                                                <div style={{ overflowY: 'scroll', height: '300px' }}>

                                                    {
                                                        getAllBrands && getAllBrands?.getAllBrands.map((brand) => {
                                                            return (
                                                                <Dropdown.Item onClick={() => setProductBrand(brand.brandName)}  >{brand.brandName}</Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </div>

                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom02" style={{ marginTop: 20 }}>
                                        <Dropdown drop='down'>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic"  style={{width:"100%"}} >
                                                Select Series
                                            </Dropdown.Toggle>
                                            <span>
                                                {series}
                                            </span>
                                            <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }} style={{width:"100%"}}>
                                                <div style={{ overflowY: 'scroll', height: '300px' }}>

                                                    {
                                                        getSeries && getSeries?.getSeries.map((s) => {
                                                            return (
                                                                <Dropdown.Item onClick={() => setSeries(s.seriesName)}  >{s.seriesName}</Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Form.Group>
                                    {/* <Form.Group as={Col} md="12" controlId="validationCustom02">
                                        <Form.Control
                                            required
                                            type="file"
                                            className='txtinpt'
                                            style={{ marginTop: 20 }}
                                            placeholder="Image"
                                            onChange={(e) => setSelectImage(e.target.files[0])}

                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group> */}
                                </Row>
                                <Button type="submit" className='mx-auto d-block' style={{ backgroundColor: '#40a737', border: 'none', width: '100%' }}>Add</Button>

                            </Form>
                        </div>
                    </Col>

                </Row>
            </Container>

        </>
    )
}
