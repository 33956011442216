import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_GET_ALL_OFFER } from '../../graphql/Query'
import { MUTATION_CREATE_OFFER } from '../../graphql/Mutations'
import { MUTATION_DELETE_OFFER } from '../../graphql/Mutations'
import swal from 'sweetalert';



export const AddOffers = () => {
    const [offer, setOffer] = useState("");
    const [validated, setValidated] = useState("");
    const [offerCategory, setOfferCategory] = useState("")
    const { data: getAllOffer, loading: allBrandLoading } = useQuery(QUERY_GET_ALL_OFFER);
    const [createOffer, { loading: createLoading }] = useMutation(MUTATION_CREATE_OFFER, {
        refetchQueries: [
            QUERY_GET_ALL_OFFER
        ]
    })
    const [deleteOfferById, { loading: dltLoading }] = useMutation(MUTATION_DELETE_OFFER, {
        refetchQueries: [
            QUERY_GET_ALL_OFFER
        ]
    })
    const handleSubmit = (e) => {
        e.preventDefault();
        createOffer({
            variables: {
                "offerInput": {
                    "category": offerCategory,
                    "offerDesc": offer
                }
            }
        }).then(() => {

            swal({
                title: "Success",
                text: "Offer Added successfully",
                icon: "success",
            })
            setOfferCategory("")
            setOffer("")
        }
            
        ).catch((e)=> alert(e))
    }
    let count = 1

    const handleDelete=(offerid)=>{
        deleteOfferById({
            variables :{
                "offerId":offerid
            }
        }).then(() => {

            swal({
                title: "Success",
                text: "deleted successfully",
                icon: "success",
            })
    
        }
            
        ).catch((e)=> alert(e))
    }
    return (
        <>

            <Container style={{ padding: 20 }} fluid>
                <Row>
                    <Col md={4}>
                        <div style={{ padding: "1rem" }} className='box-shadow'>
                            <h3 style={{ textAlign: 'center' }}>Add Product Offers Here</h3>
                            <Col>
                                <Form.Group as={Col} md="12" controlId="validationCustom02">

                                    <Dropdown style={{ marginTop: "1rem" }} >
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='w-100' >
                                            Category
                                        </Dropdown.Toggle>
                                        <span> {offerCategory} </span>
                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}className='w-100'>
                                            <Dropdown.Item onClick={(e) => setOfferCategory("Phones")}>Phones </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setOfferCategory("Laptops")}>Laptops </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setOfferCategory("Tablets")}>Tablets </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setOfferCategory("Smart Watches")}> Smart Watches </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setOfferCategory("Desktop/Imac")}> Desktop/Imac </Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setOfferCategory("Other Accessories")}> Other Accessories </Dropdown.Item>

                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                        <Form.Control
                                            required
                                            type="text"
                                            className='txtinpt'
                                            placeholder="offer description"
                                            onChange={(e) => setOffer(e.target.value)}
                                            value={offer}
                                        />
                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                    </Form.Group>

                                    <Button type='submit' variant='dark' className='mt-5' disabled={offer && offerCategory ? false : true} >  Add Offer </Button>
                                </Row>
                            </Form>

                        </div>
                    </Col>
                    <Col>
                        <Table responsive striped bordered hover>
                            <thead className='table-head'>
                                <tr>
                                    <th>S.no.</th>
                                    <th>Category</th>
                                    <th>Offer Description</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    getAllOffer && getAllOffer?.getAllOffer?.map((offer) => {
                                        return (
                                            <tr>
                                                <td> {count++} </td>
                                                <td> {offer?.category} </td>
                                                <td> {offer?.offerDesc} </td>
                                                <td> <Button variant='danger' onClick={()=>handleDelete(offer.id)} >Delete</Button> </td>
                                            </tr>
                                        )
                                    })
                                }
                                <td>  </td>
                            </tbody>

                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
