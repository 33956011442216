import React from 'react';
import '../ComponentsCss/instruction.css';

const DeviceHandoverContent = () => {
    return (
        <div className="device-handover-content mt-5">
            <h1>Things we keep in mind before handing over a preowned device to a Buyer:</h1>

            <section>
                <h2>1. Make the device ready for the New User:</h2>
                <p>We perform factory reset on the device to restore it to its original form. Performing factory reset removes traces of all the information that was stored in the device of the previous owner and any cache and junk that the device might have collected over the period of usage. This makes the device faster and as good as new for the new user.</p>
            </section>

            <section>
                <h2>2. Document verification:</h2>
                <p>We verify the documents provided by the previous user of the device, looking through for any claims settlement to make sure there are no confusion in future after and while transferring the ownership of the device, and the new user can claim the benefits that the device comes with.</p>
            </section>

            <section>
                <h2>3. Price of the device true to its market value and condition:</h2>
                <p>The price of the device is quoted true to its market value the time of purchase and the condition of the device. Transparency maintained at all the times during transaction to make sure there are no loop holes leaving grounds for any possible conflict.</p>
            </section>

            <section>
                <h2>4. Condition assessment:</h2>
                <p>The device condition is assessed thoroughly through commercially acceptable technical means to make sure the future user finds easy to get compatible with the device. Also, any prevailing technical default that can be eliminated is tried and fixed by the company making the device close to new.</p>
            </section>

            <section>
                <h2>5. Transfer of ownership:</h2>
                <p>The company assists in transferring ownership of the device to the new user by performing all the necessary registrations and logins that are required according to the law to ensure safe and easy usage by the buyer.</p>
            </section>

            <section>
                <h2>6. Receipt:</h2>
                <p>The buyer is provided with the receipt as the token of transaction with the company, the receipt contains the value paid by the buyer and the accurate description of the condition and other details pertaining to the device(s).</p>
            </section>

            <section>
                <h2>7. We evaluate and post it as per the present condition of the device mentioning the warranty if any, accessories and condition of the device in the ad description.</h2>
            </section>
        </div>
    );
}

export default DeviceHandoverContent;
