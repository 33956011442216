import { useState } from "react"
import { Button, Col, Container, Form, Image, Modal, Row, Spinner, Table, Dropdown } from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { QUERY_GET_SELL_PRODUCT } from '../../../graphql/Query'
import { MUTATION_UPDATE_PRODUCT } from '../../../graphql/Mutations'
import { QUERY_GET_ALL_BRANDS } from "../../../graphql/Query"
import { QUERY_GET_SERIES } from "../../../graphql/Query"
const EditModal = (props) => {
    const { data: getSeries, loading: allBrandLoading } = useQuery(QUERY_GET_SERIES);

    const [updateSellProduct] = useMutation(MUTATION_UPDATE_PRODUCT, {
        refetchQueries: [
            QUERY_GET_SELL_PRODUCT
        ]
    })
    const { data: getAllBrands, loading: brandLoading } = useQuery(QUERY_GET_ALL_BRANDS);


    const { data, setEditModal, editModal } = props
    const [productName, setProductName] = useState(data.productName);
    const [productPrice, setProductPrice] = useState(data.productPrice);
    const [productBrand, setProductBrand] = useState(data.productBrand);
    const [productDescription, setproductDescription] = useState(data.productDescription);
    const [productType, setproductType] = useState(data.productType);
    const [series, setseries] = useState(data.series);
    const [variant, setvariant] = useState(data.variant);
    const [category, setcategory] = useState(data.category);
    const [image, setImage] = useState(data.image);

    const handleClose = () => {
        setEditModal(false)
    }
    const handleSubmit = () => {
        updateSellProduct({

            variables: {
                sellProductInput: {
                    "id": `${data.id}`,
                    "category": `${category}`,
                    //    "productDescription" : `${productDescription}`,
                    "productBrand": `${productBrand}`,
                    "productPrice": `${productPrice}`,
                    "productName": `${productName}`,
                    //    "productType" : `${productType}`,
                    "series": `${series}`,
                    "image": `${image}`,
                    //    "variant" :`${variant}`

                }
            }
        }).then((data) => {
            setEditModal(false);
        })



    }
    return (
        <>
            <Modal
                show={editModal}
                size="lg"
                onHide={() => setEditModal(false)}
                aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Body>

                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Product Name</Form.Label>
                            <Form.Control onChange={(e) => { setProductName(e.target.value) }}
                                type="text"
                                value={productName}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Product Price</Form.Label>
                            <Form.Control onChange={(e) => { setProductPrice(e.target.value) }}
                                type="text"
                                value={productPrice}
                                autoFocus
                            />
                        </Form.Group>  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                        </Form.Group>
                        <Dropdown drop='down'>

                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} >
                                Select Brand
                            </Dropdown.Toggle>
                            <div className="mx-auto d-block">
                                {productBrand}
                            </div>
                            <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }} >
                                <div style={{ overflowY: 'scroll', height: '300px' }}>

                                    {
                                        getAllBrands && getAllBrands?.getAllBrands.map((brand) => {
                                            return (
                                                <Dropdown.Item onClick={() => setProductBrand(brand.brandName)}  >{brand.brandName}</Dropdown.Item>
                                            )
                                        })
                                    }
                                </div>

                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} >
                                    Select Series
                                </Dropdown.Toggle>
                                <span>
                                    {series}
                                </span>
                                <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }}>
                                    <div style={{ overflowY: 'scroll', height: '300px' }}>

                                        {
                                            getSeries && getSeries?.getSeries.map((s) => {
                                                return (
                                                    <Dropdown.Item onClick={() => setseries(s.seriesName)}  >{s.seriesName}</Dropdown.Item>
                                                )
                                            })
                                        }
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Form.Group>

                        <Dropdown style={{ marginTop: "1rem" }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} > Category</Dropdown.Toggle>
                            <span>{category}</span>
                            <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }}>
                                <div style={{ overflowY: 'scroll', height: '200px' }}>

                                    <Dropdown.Item onClick={(e) => setcategory("Phones")}>Phones</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setcategory("Laptops")}>Laptops</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setcategory("Tablets")}>Tablets</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setcategory("Smart Watches")}>Smart Watches</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setcategory("Desktop/Imac")}>Desktop/Imac</Dropdown.Item>
                                    <Dropdown.Item onClick={(e) => setcategory("Other Accessories")}>Other Accessories</Dropdown.Item>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                        {/* <Form.Group as={Col} md="12" controlId="validationCustom02">
                            <Form.Control
                                required
                                type="file"
                                className='txtinpt'
                                style={{ marginTop: 20 }}
                                placeholder="Image"
                                onChange={(e) => setImage(e.target.files[0])}

                            />
                        </Form.Group> */}
                    </Form>

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Save Changes
                    </Button>
                </Modal.Footer>

            </Modal>
        </>
    )
}
export default EditModal