import { useState, useEffect } from "react";
import Navigation from "../../Navigation"
import { Form, Button, Image, Card, Row, Col, Container, Dropdown } from 'react-bootstrap'
import '../../../ComponentsCss/QuestionnariesCss/step4.css'
import { FaArrowRight, FaDotCircle, FaArrowLeft } from "react-icons/fa";
import { useNavigate, useParams, useLocation } from "react-router-dom"

const DesktopStep5 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const navigate = useNavigate()
    const { productdetail } = useParams()
    const [financeClick, setFinanceClick] = useState("");

    const [boxClick, setBoxClick] = useState(false);
    const [powerCableClick, setPowerCableClick] = useState(false);
    const [adapterClick, setAdapterClick] = useState(false);
    const [purchasedPlaceClick, setPurchasedPlace] = useState(false);
    const [billClick, setBillClick] = useState(false);
    const [additionalClick, setAdditionalClick] = useState(false);
    const [warrantyPeriod, setwarrantyPeriod] = useState(false);
    const [belowOneClick, setBelowOneClick] = useState(false);
    const [oneToThreeClick, setOneToThreeClick] = useState(false);
    const [threeToSixClick, setThreeToSiXClick] = useState(false);
    const [sixToNineClick, setSixToNineClick] = useState(false);
    const [nineToTwelveClick, setNineToTwelveClick] = useState(false);
    const [aboveOneClick, setAboveOneClick] = useState(false);
    const [funcKeyboardClick, setFuncKeyboardClick] = useState(false);
    const [funcMouseClick, setFuncMouseClick] = useState(false);

    const location = useLocation();
    const handleContinue = () => {
        navigate("/desktop-price", {
            state: {
                id: location.state.id, isDeviceSwitch: location.state.isDeviceSwitch, issueWithDeviceDisplay: location.state.issueWithDeviceDisplay, deviceScreen: location.state.deviceScreen, deviceEverRepair: location.state.deviceEverRepair,
                keyBoardState: location.state.keyBoardState, trackpadState: location.state.trackpadState, SpeakerIssue: location.state.SpeakerIssue, MicIssue: location.state.MicIssue, usbPortState: location.state.usbPortState, CharginPortIssue: location.state.CharginPortIssue, webCamState: location.state.webCamState, BatteryIssue: location.state.BatteryIssue, hingeLooseState: location.state.hingeLooseState, WifiIssue: location.state.WifiIssue, BluetoothIssue: location.state.BluetoothIssue, chargerFaultyState: location.state.chargerFaultyState, AutoRestartIssue: location.state.AutoRestartIssue, EarphonePortIssue: location.state.EarphonePortIssue, graphicsCardState: location.state.graphicsCardState, hardDriveState: location.state.hardDriveState, ramIssueState: location.state.ramIssueState, productCategory: location.state.productCategory, productBrand: location.state.productBrand,
                productName: location.state.productName, imgurl: location.state.imgurl, processorState: location.state.processorState, laptopStorage: location.state.laptopStorage, laptopRam: location.state.laptopRam, graphicsState: location.state.graphicsState, screenSizeState: location.state.screenSizeState  , ConditionDeviceScreen: location.state.ConditionDeviceBody, ConditionDeviceBody: location.state.ConditionDeviceBody ,boxClick:boxClick , powerCableClick : powerCableClick , adapterClick : adapterClick ,purchasedPlaceClick:purchasedPlaceClick ,additionalClick:additionalClick ,warrantyPeriod : warrantyPeriod, funcKeyboardClick:funcKeyboardClick ,funcMouseClick : funcMouseClick ,HaveBill:billClick,fingerPrintIssueState : location.state.fingerPrintIssueState, faceSensorIssueState : location.state.faceSensorIssueState
            }
        });
    }


    return (
        <>
            <Navigation />
            <div className="banner">
                <h3>
                    <span onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                        <FaArrowLeft />
                    </span>
                    <span style={{ fontWeight: "bold", textAlign: "center", marginLeft: "5%" }}>
                        Steps - 5/5
                    </span>
                </h3>
            </div>

            <Container fluid>
                <Row>
                    <h3 style={{ marginTop: "2rem", fontSize: 20, color: '#34495e', fontWeight: 'bold' }}><FaDotCircle style={{ marginTop: '-1px', color: '#2ecc71' }} /> Select the Original Accessories available with your Device</h3>
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={boxClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => boxClick ? setBoxClick(false) : setBoxClick(true)}>
                            <h1 style={{ fontSize: 20, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Box</h1>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Box with same IMEI/Serial number</h1>
                        </div>
                    </Col>
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={powerCableClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => powerCableClick ? setPowerCableClick(false) : setPowerCableClick(true)}>
                            <h1 style={{ fontSize: 20, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Power cable</h1>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Power cable</h1>
                        </div>
                    </Col>
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={adapterClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => adapterClick ? setAdapterClick(false) : setAdapterClick(true)}>
                            <h1 style={{ fontSize: 20, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Adapter</h1>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Adapter</h1>
                        </div>
                    </Col>
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={funcMouseClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => funcMouseClick ? setFuncMouseClick(false) : setFuncMouseClick(true)}>
                            <h1 style={{ fontSize: 20, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Functional Mouse</h1>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Functional Mouse</h1>
                        </div>
                    </Col>
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={funcKeyboardClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => funcKeyboardClick ? setFuncKeyboardClick(false) : setFuncKeyboardClick(true)}>
                            <h1 style={{ fontSize: 20, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Functional Keyboard</h1>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Functional Keyboard</h1>
                        </div>
                    </Col>
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={billClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => billClick ? setBillClick(false) : setBillClick(true)}>
                            <h1 style={{ fontSize: 20, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Bill</h1>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Valid GST bill/Same IMEI/Serial number</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    
                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className={"step4textBox"} >
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: 'transparent', color: '#000', borderColor: 'transparent', fontWeight: 'bold', fontSize: 20, marginLeft: '-10px' }}>
                                    Purchased Place
                                </Dropdown.Toggle>
                                {<span> {purchasedPlaceClick}</span>}
                                <Dropdown.Menu>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setPurchasedPlace("India")}>India</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setPurchasedPlace("Abroad")}>Abroad</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Valid GST bill/Same IMEI/Serial number</h1> */}
                        </div>
                    </Col>

                    <Col md={4} style={{ marginTop: '10px' }}>
                        <div className="step4textBox">
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: 'transparent', color: '#000', borderColor: 'transparent', fontWeight: 'bold', fontSize: 20, marginLeft: '-10px' }}>
                                    Additional Insurance
                                </Dropdown.Toggle>
                                {<span> {additionalClick}</span>}

                                <Dropdown.Menu>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setAdditionalClick('Apple Care+')}>Apple Care+</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setAdditionalClick('Samsung Care+')}>Samsung Care+</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setAdditionalClick('3rd Party')}>3rd Party Insurance</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setAdditionalClick('Not applicable')}>Not applicable</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Valid Bill Required.</h1>

                            {/* <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>Valid GST bill/Same IMEI/Serial number</h1> */}
                        </div>
                    </Col>

                    <Col md={4} style={{ marginTop: '10px' , }}>
                        <div className="step8textBox" >
                            <Dropdown >
                                <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ backgroundColor: 'transparent', color: '#000', borderColor: 'transparent', fontWeight: 'bold', fontSize: 20, marginLeft: '-10px' }}>
                                 Is this Device under Finance
                                </Dropdown.Toggle>
                                {/* {<span> {financeClick}</span>} */}
                                <h1 style={{ fontSize: 12, color: '#000', marginTop: '5px' }}>  {financeClick}</h1>

                                <Dropdown.Menu>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setFinanceClick('Samsung Finance')}>Samsung Finance</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setFinanceClick('Bajaj Finance')}>Bajaj Finance</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setFinanceClick('HDFC Finance')}>HDFC Finance</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setFinanceClick('TVS Finance')}>TVS Finance</Dropdown.Item>
                                    <Dropdown.Item style={{ fontWeight: "bold" }} onClick={() => setFinanceClick('Not under Finance')}>Not under Finance</Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </Col>
                </Row>
                {
                    billClick ?
                        <Row>
                            <h3 style={{ marginTop: "2rem", fontSize: 20, color: '#34495e', fontWeight: 'bold' }}><FaDotCircle style={{ marginTop: '-1px', color: '#2ecc71' }} /> Select the exact warranty period according to the bill</h3>
                            <Col md={2} style={{ marginTop: 10 }}>
                                <div className={belowOneClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => belowOneClick ? setBelowOneClick(false) : [setBelowOneClick(true), setOneToThreeClick(false), setThreeToSiXClick(false), setSixToNineClick(false), setNineToTwelveClick(false), setAboveOneClick(false), setwarrantyPeriod("1")]}>
                                    <h1 style={{ fontSize: 15, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Below 1 Month</h1>
                                </div>
                            </Col>
                            <Col md={2} style={{ marginTop: 10 }}>
                                <div className={oneToThreeClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => oneToThreeClick ? setOneToThreeClick(false) : [setBelowOneClick(false), setOneToThreeClick(true), setThreeToSiXClick(false), setSixToNineClick(false), setNineToTwelveClick(false), setAboveOneClick(false), setwarrantyPeriod("3")]}>
                                    <h1 style={{ fontSize: 15, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>1 To 3 Months</h1>
                                </div>
                            </Col>
                            <Col md={2} style={{ marginTop: 10 }}>
                                <div className={threeToSixClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => threeToSixClick ? setThreeToSiXClick(false) : [setBelowOneClick(false), setOneToThreeClick(false), setThreeToSiXClick(true), setSixToNineClick(false), setNineToTwelveClick(false), setAboveOneClick(false), setwarrantyPeriod("6")]}>
                                    <h1 style={{ fontSize: 15, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>3 To 6 Months</h1>
                                </div>
                            </Col>
                            <Col md={2} style={{ marginTop: 10 }}>
                                <div className={sixToNineClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => sixToNineClick ? setSixToNineClick(false) : [setBelowOneClick(false), setOneToThreeClick(false), setThreeToSiXClick(false), setSixToNineClick(true), setNineToTwelveClick(false), setAboveOneClick(false), setwarrantyPeriod("9")]}>
                                    <h1 style={{ fontSize: 15, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>6 To 9 Months</h1>
                                </div>
                            </Col>
                            <Col md={2} style={{ marginTop: 10 }}>
                                <div className={nineToTwelveClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => nineToTwelveClick ? setNineToTwelveClick(false) : [setBelowOneClick(false), setOneToThreeClick(false), setThreeToSiXClick(false), setSixToNineClick(false), setNineToTwelveClick(true), setAboveOneClick(false), setwarrantyPeriod("12")]}>
                                    <h1 style={{ fontSize: 15, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>9 To 12 Months</h1>
                                </div>
                            </Col>
                            <Col md={2} style={{ marginTop: 10 }}>
                                <div className={aboveOneClick ? "step4textBoxClicked" : 'step4textBox'} onClick={() => aboveOneClick ? setAboveOneClick(false) : [setBelowOneClick(false), setOneToThreeClick(false), setThreeToSiXClick(false), setSixToNineClick(false), setNineToTwelveClick(false), setAboveOneClick(true), setwarrantyPeriod("default")]}>
                                    <h1 style={{ fontSize: 15, color: '#000', marginTop: '5px', fontWeight: 'bold' }}>Above 1 year</h1>
                                </div>
                            </Col>
                        </Row> :
                        ''
                }

                <div style={{ display: "flex", justifyContent: "center", margin: "1rem", marginTop: "3rem" }}>
                    <Button disabled={purchasedPlaceClick && additionalClick ? false : true} variant="warning" onClick={() => handleContinue()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>
                </div>

            </Container>
        </>
    )
}
export default DesktopStep5