import React, { useState } from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import '../ComponentsCss/Ourservices.css'
import sell from '../Images/homepage/sell.png'
import buy from '../Images/homepage/buy.png'
import exchange from '../Images/homepage/exchange.png'
import CategoryModal from './HomePageModals/Category'
import BuyCategoryModal from './HomePageModals/BuyCategoryModal';
import ExchangeModal from './HomePageModals/ExchangeModal'
export default function OurServices() {   

    const [showCategory, setShowCategory] = useState(false)
    const [showCategory2, setShowCategory2] = useState(false)
    const [showExchangeModal, setShowExchangeModal] = useState(false)

    return (
        <>
            <Container style={{ marginTop: '20px' }} >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ height: 40, width: 5, backgroundColor: '#44CE30', marginTop: 20 }}></div>
                    <h3 style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Our Services</h3>
                </div>
                <Row>
                    <Col md={4}>
                        <Card className='newservice mx-auto d-block' >
                            <div className='txtsev'>
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17 }}>Earn</h4>
                                    <p className='crdtxt2' style={{ marginTop: -12, fontSize: 12 }}>while you <span className='crdtxt' >Sell</span></p>

                                    <p className='crdtxt2' style={{ marginTop: -12, fontSize: 12 }}>your Electronic Gadgets</p>
                                    <Button onClick={() => setShowCategory(true)} className='btnserv' style={{ fontSize: 12 }}>Sell Now</Button>
                                </div>
                                <Image className='imgservice' src={sell}></Image>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='newservice mx-auto d-block' >
                            <div className='txtsev'>
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17 }}>Save</h4>
                                    <p className='crdtxt2' style={{ marginTop: -12, fontSize: 12 }}>while you <span className='crdtxt' >Buy</span></p>
                                    <p className='crdtxt2' style={{ marginTop: -12, fontSize: 12 }}>Preowned Gadgets</p>
                                    <Button className='btnserv' style={{ fontSize: 12 }} onClick={() => setShowCategory2(true)}>Buy Now</Button>
                                </div>
                                <Image className='imgservice' src={buy}  responsive></Image>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className='newservice mx-auto d-block' >
                            <div className='txtsev'>
                                <div>
                                    <h4 className='crdtxt' style={{ marginTop: 10, fontSize: 17 }}>Upgrade</h4>
                                    <p className='crdtxt2' style={{ marginTop: -8, fontSize: 12 }}>while you <span className='crdtxt' >Sell</span></p>
                                    <p className='crdtxt2' style={{ marginTop: -12, fontSize: 12 }}>your Gadgets</p>
                                    <Button onClick={() => setShowExchangeModal(true)} className='btnserv' style={{ fontSize: 12 }}>Exchange Now</Button>
                                </div>
                                <Image className='imgservice' src={exchange} responsive></Image>
                            </div>
                        </Card>
                    </Col>
                </Row>

                {/*  */}
                {/* <Card className='cardbgservice mx-auto d-block' >
                    <div className='cardimgservice'>
                        <h2 className='freetxtservice'>Pickup Service</h2>
                        <span className='imgshipserv' style={{ fontSize: 30, color: '#dcdde1' }}>|</span>
                        <p className='freetxtservi' >Start buy, sell or exchange your phone.</p>
                        <span className='imgshipserv' style={{ fontSize: 30, color: '#dcdde1' }}>|</span>
                        <Link to='/' className='freetxtservicebtn'>Start Now</Link>
                    </div>
                </Card> */}
                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                    <div style={{ height: 60, width: 5, backgroundColor: '#44CE30', marginTop: 20 }}></div>
                    <h3 style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Are you a Corporate/Vendor?<br></br><span style={{ fontSize: 18, marginLeft: 2 }}>Want to sell/buy in Quantity?</span></h3>
                </div>

                {
                    showCategory ? <CategoryModal showCategory={showCategory} setShowCategory={setShowCategory} /> : ""
                }
                {
                    showCategory2 ? <BuyCategoryModal showCategory2={showCategory2} setShowCategory2={setShowCategory2} /> : ""
                }
                {
                    showExchangeModal ? <ExchangeModal showExchangeModal={showExchangeModal} setShowExchangeModal={setShowExchangeModal} /> : ""
                }
            </Container>
        </>
    )
}
