import { gql } from '@apollo/client'

export const QUERY_GET_ALL_USER = gql`
query Query {
  getAllUser {
    id
    fName
    lName
    contact
    email
    createDateTime
    status
  }
}
`
export const QUERY_GET_USER_BY_ID = gql`
query GetUserDetailsByID($userId: String) {
  getUserDetailsByID(userId: $userId) {
    id
    fName
    lName
    contact
    email
    createDateTime
    status
  }
}
`
export const QUERY_GET_All_SELLING_ORDERS = gql`
query Query {
  getSellOrders {
    id
    userId
    orderId
    imageKey
    productCategory
    productBrand
    productName
    storage
    ram
    addressName
    addressEmail
    addressContact
    addressFlat
    addressState
    addressCity
    addressLandmark
    addressPincode
    addressAlternatContact
    addressType
    wantToPickupYourself
    pickupDate
    pickupTime
    paymentMethod
    sellingAmount
    bankName
    bankAccountHolderName
    bankIfsc
    bankAccountNo
    upiId
    createdDateTime
    status
    details {
      
      que
      ans
      
    }
  }
}
`
export const QUERY_GET_SELL_PRODUCT = gql`
query Query {
  getSellProduct {
    id
    productName
    productPrice
    productDescription
    variant
    series
    productType
    category
    productBrand
    image
    createdDateTime
    status
  }
}
`
export const QUERY_GET_ALL_BRANDS = gql`

query Query {
  getAllBrands {
    id
    brandName
    brandImage
    category
    createdDateTime
    status
  }
}

`
export const QUERY_GET_BUY_ALL_BRANDS = gql`

query GetAllBuyBrands {
  getAllBuyBrands {
    id
    brandName
    brandImage
    category
    createdDateTime
    status
  }
}

`

export const QUERY_GET_PRODUCT_BY_ID = gql`

query GetProductById($id: String) {
  getProductById(Id: $id) {
    id
    productName
    productDescription
    variant
    series
    productType
    category
    productBrand
    image
    productPrice
    createdDateTime
    status
  }
}

`
export const QUERY_GET_PRODUCTS = gql`
query GetProducts($productBrand: String, $category: String) {
  getProducts(ProductBrand: $productBrand, category: $category) {
    id
    productName
    productDescription
    variant
    series
    productType
    category
    productBrand
    image
    productPrice
    createdDateTime
    status
  }
}

`
export const GET_SPECIFIC_PRODUCT = gql`

query GetSpecificProduct($productName: String) {
  getSpecificProduct(productName: $productName) {
    id
    productName
    productDescription
    variant
    series
    productType
    category
    productBrand
    image
    productPrice
    createdDateTime
    status
  }
}
`
export const QUERY_GET_ALL_ORDERS_BY_USERID = gql`
query GetSellOrderByUserId($userId: String) {
  getSellOrderByUserId(UserId: $userId) {
    id
    userId
    imageKey
    orderId
    productCategory
    productBrand
    productName
    storage
    ram
    details {
      que
      ans
    }
    addressName
    addressEmail
    addressContact
    addressFlat
    addressState
    addressCity
    addressLandmark
    addressPincode
    addressAlternatContact
    addressType
    wantToPickupYourself
    pickupDate
    pickupTime
    paymentMethod
    bankName
    bankAccountHolderName
    bankIfsc
    bankAccountNo
    upiId
    sellingAmount
    createdDateTime
    status
  }
}
`

export const QUERY_GET_QUESTIONS = gql`
query Query {
  getQuestions {
    id
    isDeviceSwitch
    isDeviceSwitchPer
    ableToMakeReceiveCall
    ableToMakeReceiveCallPer
    issueWithDeviceDisplay
    issueWithDeviceDisplayPer
    deviceScreen
    deviceScreenPer
    deviceEverRepair
    deviceEverRepairPer
    FrontCameraIssue
    FrontCameraIssuePer
    BackCameraIssue
    BackCameraIssuePer
    SpeakerIssue
    SpeakerIssuePer
    MicIssue
    MicIssuePer
    RingerIssue
    RingerIssuePer
    CharginPortIssue
    CharginPortIssuePer
    SensorIssue
    SensorIssuePer
    BatteryIssue
    BatteryIssuePer
    ButtonIssue
    ButtonIssuePer
    WifiIssue
    WifiIssuePer
    BluetoothIssue
    BluetoothIssuePer
    FlashLightIssue
    FlashLightIssuePer
    VibratorIssue
    VibratorIssuePer
    AutoRestartIssue
    AutoRestartIssuePer
    EarphonePortIssue
    EarphonePortIssuePer
    SimCardIssue
    SimCardIssuePer
    GpsIssue
    GpsIssuePer
    ConditionDeviceScreen
    ConditionDeviceScreenPer
    ConditionDeviceBody
    ConditionDeviceBodyPer
    HaveBill
    HaveBillPer
    HaveBoxWithIMEI
    HaveBoxWithIMEIPer
    HaveDataCable
    HaveDataCablePer
    HaveAdapter
    HaveAdapterPer
    HaveEarphones
    HaveEarphonesPer
    AdditionalInsurance
    AdditionalInsurancePer
    PurchasedPlace
    PurchasedPlacePer
    WarrantyPeriod
    WarrantyPeriodPer
    storage16gb
    storage32gb
    storage64gb
    storage128gb
    storage256gb
    storage1tb
    storage2tb
    ram2gb
    ram3gb
    ram4gb
    ram6gb
    ram8gb
    ram12gb
    ram16gb
    ram32gb
    ram18gb
    ram24gb
    ram64gb
    ram128gb
    deviceScreenOriginPer
    deviceScreenChangesPer
    deviceScreenOriginalChangedPer
    deviceRepairAuthCenterPer
    deviceRepairUnAuthCenterPer
    deviceRepairNoPer
    deviceConditionMinorPer
    deviceConditionMajorPer
    deviceConditionNoScratchPer
    deviceBodyConditionExcellentPer
    deviceBodyConditionGoodPer
    deviceBodyConditionAveragePer
    deviceBodyConditionPoorPer
    addInsAppleCarePer
    addInsSamsungCarePer
    addInsThirdPartyPer
    addInsNotApplicablePer
    purchasePlaceIndiaPer
    purchasePlaceAbroadPer
    billBelowOneMonthPer
    billOneToThreeMonthPer
    billThreeToSixMonthPer
    billSixToNineMonthPer
    billNineToTwelveMonthPer
    billAboveOneYear
    createdDateTime
    status
    FaceSensorIssuePer
    FingerPrintIssuePer
  }
}

`
export const QUERY_All_BUY_ORDERS = gql`
query Query {
  getAllBuyingProduct {
    productName
    productSubTitle
    productDescription
    warrantyDescription
    AdditionalFeatures
    dimensionsDescrption
    isTrending
    screenSize
    type
    networkType
    liveLink
    quantity
    mrp
    ram
    image
    status
    productPrice
    id
    productUniqueId
    condition 
    storage 
    productCategory
    productBrand 
    createdDateTime
  }
}
`
export const GET_ADDRESS_BY_USERID = gql`
query GetAddressByUserID($userId: String) {
  getAddressByUserID(userId: $userId) {
    id
    userId
    address
    sellername
    landmark
    city
    pincode
    state
    contactNo
    alternativeContactNo
    email
    addressType
    createDateTime
    status
  }
}

`
export const QUERY_GET_USER_DETAIL_BY_ID = gql`
query GetUserDetailById($userId: String) {
  getUserDetailById(userId: $userId) {
    id
    fName
    lName
    contact
    email
    createDateTime
    status
  }
}

`
export const QUERY_GET_SELL_MODAL_IMAGE = gql`
query Query {
  getSellImageModal {
    brandName
    createdDateTime
    id
    image
    status
  }
}
`
export const QUERY_GET_SELL_MODAL_IMAGE_BY_BRANDNAME = gql`
query Query($brandName: String) {
  getSellImageModalByBrandName(brandName: $brandName) {
    id
    brandName
    image
    createdDateTime
    status
  }
} 
`
export const QUERY_GET_ALL_QUOTE_REQ = gql`
query Query {

  getQuoteRequest {
    id
    userId
    reqNum
    productType
    productBrand
    ageOfProduct
    physicalCondition
    accessoriesIncluded
    originalInvoice
    name 
    email
    phone
    deviceDefect
    productModel
    productStorage
    productRam
    productProcessor
    createdDateTime
    status
  }
}
`
export const QUERY_GET_ALL_BRANDS_BY_CAT = gql`

query Query($category: String) {
  getAllBrandByCategory(category: $category) {
    id
    brandName
    brandImage
    category

  }
}
`
export const QUERY_GET_LAPTOP_QUESTIONS = gql`
query Query {
  getLaptopQuestios {
    id
    isDeviceSwitchPer
    issueWithDeviceDisplayPer
    deviceScreenPer
    deviceEverRepairPer
    SpeakerIssuePer
    MicIssuePer
    CharginPortIssuePer
    BatteryIssuePer
    WifiIssuePer
    BluetoothIssuePer
    AutoRestartIssue
    AutoRestartIssuePer
    EarphonePortIssuePer
    KeyboardFaultyPer
    TrackpadFaultyPer
    UsbPortIssuePer
    ChargerFaultyPer
    HardDriveFaultyPer
    RamIssuePer
    GraphicsCardIssuePer
    HingeLoosePer
    GraphicsCardYesPer
    GraphicsCardNoPer
    ScreenSize10To11Per
    ScreenSize12To13Per
    ScreenSize13To14Per
    ScreenSize14To15Per
    ScreenSizeAbove15Per
    WebcamFaulty
    HaveBill
    HaveBillPer
    HaveBoxWithIMEI
    HaveBoxWithIMEIPer
    HavePowerCablePer
    HaveAdapter
    HaveAdapterPer
    AdditionalInsurance
    AdditionalInsurancePer
    PurchasedPlace
    PurchasedPlacePer
    WarrantyPeriod
    WarrantyPeriodPer
    ram2gb
    ram3gb
    ram4gb
    ram6gb
    ram8gb
    ram12gb
    ram16gb
    ram32gb
    deviceScreenOriginPer
    deviceScreenChangesPer
    deviceScreenOriginalChangedPer
    deviceRepairAuthCenterPer
    deviceRepairUnAuthCenterPer
    deviceRepairNoPer
    deviceConditionMinorPer
    deviceConditionMajorPer
    deviceConditionNoScratchPer
    deviceBodyConditionExcellentPer
    deviceBodyConditionGoodPer
    deviceBodyConditionAveragePer
    deviceBodyConditionPoorPer
    addInsAppleCarePer
    addInsSamsungCarePer
    addInsThirdPartyPer
    addInsNotApplicablePer
    purchasePlaceIndiaPer
    purchasePlaceAbroadPer
    billBelowOneMonthPer
    billOneToThreeMonthPer
    billThreeToSixMonthPer
    billSixToNineMonthPer
    billNineToTwelveMonthPer
    billAboveOneYear
    intelCoreI33Gen
    intelCoreI34Gen
    intelCoreI35Gen
    intelCoreI36Gen
    intelCoreI37Gen
    intelCoreI38Gen
    intelCoreI39Gen
    intelCoreI310Gen
    intelCoreI311Gen
    intelCoreI312Gen
    intelCoreI53Gen
    intelCoreI54Gen
    intelCoreI55Gen
    intelCoreI56Gen
    intelCoreI57Gen
    intelCoreI58Gen
    intelCoreI59Gen
    intelCoreI510Gen
    intelCoreI511Gen
    intelCoreI512Gen
    intelCoreI73Gen
    intelCoreI74Gen
    intelCoreI75Gen
    intelCoreI76Gen
    intelCoreI77Gen
    intelCoreI78Gen
    intelCoreI79Gen
    intelCoreI710Gen
    intelCoreI711Gen
    intelCoreI98Gen
    intelCoreI99Gen
    intelCoreI910Gen
    intelCoreI911Gen
    appleM1Chip
    appleM1MaxChip
    appleM1ProChip
    appleM1PlusChip
    appleM2Chip
    appleM2MaxChip
    appleM2ProChip
    appleM2PlusChip
    amdA9
    amdA10
    amdA4
    amdA6
    amdA8
    amdBrazosDualCoreC70
    amdDualCoreA6
    amdDualCoreA97gen
    amdDualCoreE1
    amdDualCoreE2
    amdSeries
    amdE1
    amdQuadCore
    amdQuadCoreA10
    amdQuadCoreA15
    amdQuadCoreA4
    amdQuadCoreA6
    amdQuadCoreA8
    amdQuadCoreE27thGen
    amdQuadCoreE26thGen
    amdRyzen3
    amdRyzen5
    amdRyzen5Pro
    amdRyzen7
    amdRyzen7Pro
    amdRyzen9
    amdRyzenThreadDripper
    intelCeleronDualCore3gen
    intelCeleronDualCore4gen
    intelCeleronDualCore5gen
    intelCeleronQuadCore4gen
    intelCoreM36gen
    intelCoreM37gen
    intelCoreM38gen
    intelCoreM55gen
    intelCoreM56gen
    intelCoreM76gen
    intelPentiumDualCore3Gen
    intelPentiumDualCore4Gen
    intelPentiumOctaCore
    intelPentiumQuadCore
    intelPentiumQuadCore3rdGen
    intelPentiumQuadCore4Gen
    intelPentiumQuadCore6Gen
    intelXeon
    microSoftSq1
    microSoftSq2
    quadCore
    storage250GbHDD
    storage320GbHDD
    storage500GbHDD
    storage640GbHDD
    storage750GbHDD
    storage1TbHDD
    storage1P5TbHDD
    storage2TbHDD
    storage128SDD
    storage256SDD
    storage500Plus128SDD
    storage1TbPlus128SDD
    storage512SSD
    storage1TbHDDPlus256Ssd
    storage1TbHDDPlus512Ssd
    storage1Tbssd
    storage2Tbssd
    storage3Tbssd
    storage4Tbssd
    createdDateTime
    status

    
    intelCoreI313Gen
    intelCoreI513Gen
    intelCoreI713Gen
    intelCoreI913Gen
    intelCoreI712Gen
    intelCoreI912Gen

    amdRyzen32Gen
    amdRyzen33Gen
    amdRyzen34Gen
    amdRyzen35Gen
    amdRyzen36Gen
    amdRyzen37Gen

    amdRyzen52Gen
    amdRyzen53Gen
    amdRyzen54Gen
    amdRyzen55Gen
    amdRyzen56Gen
    amdRyzen57Gen

    amdRyzen72Gen
    amdRyzen73Gen
    amdRyzen74Gen
    amdRyzen75Gen
    amdRyzen76Gen
    amdRyzen77Gen

    amdRyzen92Gen
    amdRyzen93Gen
    amdRyzen94Gen
    amdRyzen95Gen
    amdRyzen96Gen
    amdRyzen97Gen

    amdRyzen3Pro
    amdRyzen3Pro2Gen
    amdRyzen3Pro3Gen
    amdRyzen3Pro4Gen
    amdRyzen3Pro5Gen
    amdRyzen3Pro6Gen
    amdRyzen3Pro7Gen

    amdRyzen5Pro2Gen
    amdRyzen5Pro3Gen
    amdRyzen5Pro4Gen
    amdRyzen5Pro5Gen
    amdRyzen5Pro6Gen
    amdRyzen5Pro7Gen

    amdRyzen7Pro2Gen
    amdRyzen7Pro3Gen
    amdRyzen7Pro4Gen
    amdRyzen7Pro5Gen
    amdRyzen7Pro6Gen
    amdRyzen7Pro7Gen

    appleM3Chip
    appleM3ProChip
    appleM3MaxChip
    appleM1UltraChip
    appleM2UltraChip

    storage2tb
    storage1tbHDDPlus128GBSSD
    storage2tbHDDPlus128GBSSD
    storage2tbHDDPlus256GBSSD
    storage2tbHDDPlus512GBSSD
    ram18gb
    ram24gb
    ram64gb
    ram128gb
    FaceSensorIssuePer
    FingerPrintIssuePer
  }
}

`
export const QUERY_GET_SMARTWATCH_QUESTION = gql`
query Query {
  getSmartWatchQuestion {
    id
    SpeakerIssuePer
    MicIssuePer
    MagneticCharginPortPer
    SensorIssuePer
    BatteryIssuePer
    ButtonIssuePer
    WifiIssuePer
    BluetoothIssuePer
    DigitalCrownIssuePer
    AutoRestartIssuePer
    GpsIssuePer
    CellularConIssue
    isDeviceSwitchPer
    issueWithDeviceDisplayPer
    deviceScreenOriginPer
    deviceScreenChangesPer
    deviceScreenOriginalChangedPer
    deviceRepairAuthCenterPer
    deviceRepairUnAuthCenterPer
    deviceRepairNoPer
    deviceConditionMinorPer
    deviceConditionMajorPer
    deviceConditionNoScratchPer
    deviceBodyConditionExcellentPer
    deviceBodyConditionGoodPer
    deviceBodyConditionAveragePer
    deviceBodyConditionPoorPer
    HaveBillPer
    HaveBoxWithIMEIPer
    HavePowerCablePer
    HaveStrapPer
    HaveAdapterPer
    addInsAppleCarePer
    addInsSamsungCarePer
    addInsThirdPartyPer
    addInsNotApplicablePer
    purchasePlaceIndiaPer
    purchasePlaceAbroadPer
    billBelowOneMonthPer
    billOneToThreeMonthPer
    billThreeToSixMonthPer
    billSixToNineMonthPer
    billNineToTwelveMonthPer
    billAboveOneYear
    createdDateTime
    status
    FaceSensorIssuePer
    FingerPrintIssuePer
  }
}

`
export const GET_TABLET_QUESTIONS = gql`
query Query {
  getTabletQuestions {
    id
    isDeviceSwitch
    isDeviceSwitchPer
    ableToMakeReceiveCall
    ableToMakeReceiveCallPer
    issueWithDeviceDisplay
    issueWithDeviceDisplayPer
    deviceScreen
    deviceScreenPer
    deviceEverRepair
    deviceEverRepairPer
    FrontCameraIssue
    FrontCameraIssuePer
    BackCameraIssue
    BackCameraIssuePer
    SpeakerIssue
    SpeakerIssuePer
    MicIssue
    MicIssuePer
    RingerIssue
    RingerIssuePer
    CharginPortIssue
    CharginPortIssuePer
    SensorIssue
    SensorIssuePer
    BatteryIssue
    BatteryIssuePer
    ButtonIssue
    ButtonIssuePer
    WifiIssue
    WifiIssuePer
    BluetoothIssue
    BluetoothIssuePer
    FlashLightIssue
    FlashLightIssuePer
    VibratorIssue
    VibratorIssuePer
    AutoRestartIssue
    AutoRestartIssuePer
    EarphonePortIssue
    EarphonePortIssuePer
    SimCardIssue
    SimCardIssuePer
    GpsIssue
    GpsIssuePer
    ConditionDeviceScreen
    ConditionDeviceScreenPer
    ConditionDeviceBody
    ConditionDeviceBodyPer
    HaveBill
    HaveBillPer
    HaveBoxWithIMEI
    HaveBoxWithIMEIPer
    HaveDataCable
    HaveDataCablePer
    HaveAdapter
    HaveAdapterPer
    HaveEarphones
    HaveEarphonesPer
    AdditionalInsurance
    AdditionalInsurancePer
    PurchasedPlace
    PurchasedPlacePer
    WarrantyPeriod
    WarrantyPeriodPer
    storage16gb
    storage32gb
    storage64gb
    storage128gb
    storage256gb
    storage1tb
    storage2tb
    ram2gb
    ram3gb
    ram4gb
    ram6gb
    ram8gb
    ram12gb
    ram16gb
    ram32gb
    ram18gb
    ram24gb
    ram64gb
    ram128gb
    deviceScreenOriginPer
    deviceScreenChangesPer
    deviceScreenOriginalChangedPer
    deviceRepairAuthCenterPer
    deviceRepairUnAuthCenterPer
    deviceRepairNoPer
    deviceConditionMinorPer
    deviceConditionMajorPer
    deviceConditionNoScratchPer
    deviceBodyConditionExcellentPer
    deviceBodyConditionGoodPer
    deviceBodyConditionAveragePer
    deviceBodyConditionPoorPer
    addInsAppleCarePer
    addInsSamsungCarePer
    addInsThirdPartyPer
    addInsNotApplicablePer
    purchasePlaceIndiaPer
    purchasePlaceAbroadPer
    billBelowOneMonthPer
    billOneToThreeMonthPer
    billThreeToSixMonthPer
    billSixToNineMonthPer
    billNineToTwelveMonthPer
    billAboveOneYear
    HaveApplePencil
    createdDateTime
    status
    FaceSensorIssuePer
    FingerPrintIssuePer
  }
}


`

export const QUERY_GET_DESKTOP_QUESTIONS = gql`
query Query {
  getDesktopQuestion {
    id
    isDeviceSwitchPer
    issueWithDeviceDisplayPer
    deviceScreenPer
    deviceEverRepairPer
    SpeakerIssuePer
    MicIssuePer
    CharginPortIssuePer
    BatteryIssuePer
    WifiIssuePer
    BluetoothIssuePer
    AutoRestartIssue
    AutoRestartIssuePer
    EarphonePortIssuePer
    KeyboardFaultyPer
    TrackpadFaultyPer
    UsbPortIssuePer
    ChargerFaultyPer
    HardDriveFaultyPer
    RamIssuePer
    GraphicsCardIssuePer
    HingeLoosePer
    GraphicsCardYesPer
    GraphicsCardNoPer
    ScreenSize10To11Per
    ScreenSize12To13Per
    ScreenSize13To14Per
    ScreenSize14To15Per
    ScreenSize15To20Per
    ScreenSize20To30Per
    ScreenSizeAbove30Per
    WebcamFaulty
    HaveBill
    HaveBillPer
    HaveBoxWithIMEI
    HaveBoxWithIMEIPer
    HavePowerCablePer
    HaveAdapter
    HaveAdapterPer 
    HaveFunctKeyBoardPer
    HaveFunctMousePer


    AdditionalInsurance
    AdditionalInsurancePer
    PurchasedPlace
    PurchasedPlacePer
    WarrantyPeriod
    WarrantyPeriodPer
    ram2gb
    ram3gb
    ram4gb
    ram6gb
    ram8gb
    ram12gb
    ram16gb
    ram32gb
    deviceScreenOriginPer
    deviceScreenChangesPer
    deviceScreenOriginalChangedPer
    deviceRepairAuthCenterPer
    deviceRepairUnAuthCenterPer
    deviceRepairNoPer
    deviceConditionMinorPer
    deviceConditionMajorPer
    deviceConditionNoScratchPer
    deviceBodyConditionExcellentPer
    deviceBodyConditionGoodPer
    deviceBodyConditionAveragePer
    deviceBodyConditionPoorPer
    addInsAppleCarePer
    addInsSamsungCarePer
    addInsThirdPartyPer
    addInsNotApplicablePer
    purchasePlaceIndiaPer
    purchasePlaceAbroadPer
    billBelowOneMonthPer
    billOneToThreeMonthPer
    billThreeToSixMonthPer
    billSixToNineMonthPer
    billNineToTwelveMonthPer
    billAboveOneYear
    intelCoreI33Gen
    intelCoreI34Gen
    intelCoreI35Gen
    intelCoreI36Gen
    intelCoreI37Gen
    intelCoreI38Gen
    intelCoreI39Gen
    intelCoreI310Gen
    intelCoreI311Gen
    intelCoreI312Gen
    intelCoreI53Gen
    intelCoreI54Gen
    intelCoreI55Gen
    intelCoreI56Gen
    intelCoreI57Gen
    intelCoreI58Gen
    intelCoreI59Gen
    intelCoreI510Gen
    intelCoreI511Gen
    intelCoreI512Gen
    intelCoreI73Gen
    intelCoreI74Gen
    intelCoreI75Gen
    intelCoreI76Gen
    intelCoreI77Gen
    intelCoreI78Gen
    intelCoreI79Gen
    intelCoreI710Gen
    intelCoreI711Gen
    intelCoreI98Gen
    intelCoreI99Gen
    intelCoreI910Gen
    intelCoreI911Gen
    appleM1Chip
    appleM1MaxChip
    appleM1ProChip
    appleM1PlusChip
    appleM2Chip
    appleM2MaxChip
    appleM2ProChip
    appleM2PlusChip
    amdA9
    amdA10
    amdA4
    amdA6
    amdA8
    amdBrazosDualCoreC70
    amdDualCoreA6
    amdDualCoreA97gen
    amdDualCoreE1
    amdDualCoreE2
    amdSeries
    amdE1
    amdQuadCore
    amdQuadCoreA10
    amdQuadCoreA15
    amdQuadCoreA4
    amdQuadCoreA6
    amdQuadCoreA8
    amdQuadCoreE27thGen
    amdQuadCoreE26thGen
    amdRyzen3
    amdRyzen5
    amdRyzen5Pro
    amdRyzen7
    amdRyzen7Pro
    amdRyzen9
    amdRyzenThreadDripper
    intelCeleronDualCore3gen
    intelCeleronDualCore4gen
    intelCeleronDualCore5gen
    intelCeleronQuadCore4gen
    intelCoreM36gen
    intelCoreM37gen
    intelCoreM38gen
    intelCoreM55gen
    intelCoreM56gen
    intelCoreM76gen
    intelPentiumDualCore3Gen
    intelPentiumDualCore4Gen
    intelPentiumOctaCore
    intelPentiumQuadCore
    intelPentiumQuadCore3rdGen
    intelPentiumQuadCore4Gen
    intelPentiumQuadCore6Gen
    intelXeon
    microSoftSq1
    microSoftSq2
    quadCore
    storage250GbHDD
    storage320GbHDD
    storage500GbHDD
    storage640GbHDD
    storage750GbHDD
    storage1TbHDD
    storage1P5TbHDD
    storage2TbHDD
    storage128SDD
    storage256SDD
    storage500Plus128SDD
    storage1TbPlus128SDD
    storage512SSD
    storage1TbHDDPlus256Ssd
    storage1TbHDDPlus512Ssd
    storage1Tbssd
    storage2Tbssd
    storage3Tbssd
    storage4Tbssd
    createdDateTime
    status

    intelCoreI313Gen
    intelCoreI513Gen
    intelCoreI713Gen
    intelCoreI913Gen
    intelCoreI712Gen
    intelCoreI912Gen

    amdRyzen32Gen
    amdRyzen33Gen
    amdRyzen34Gen
    amdRyzen35Gen
    amdRyzen36Gen
    amdRyzen37Gen

    amdRyzen52Gen
    amdRyzen53Gen
    amdRyzen54Gen
    amdRyzen55Gen
    amdRyzen56Gen
    amdRyzen57Gen

    amdRyzen72Gen
    amdRyzen73Gen
    amdRyzen74Gen
    amdRyzen75Gen
    amdRyzen76Gen
    amdRyzen77Gen

    amdRyzen92Gen
    amdRyzen93Gen
    amdRyzen94Gen
    amdRyzen95Gen
    amdRyzen96Gen
    amdRyzen97Gen

    amdRyzen3Pro
    amdRyzen3Pro2Gen
    amdRyzen3Pro3Gen
    amdRyzen3Pro4Gen
    amdRyzen3Pro5Gen
    amdRyzen3Pro6Gen
    amdRyzen3Pro7Gen

    amdRyzen5Pro2Gen
    amdRyzen5Pro3Gen
    amdRyzen5Pro4Gen
    amdRyzen5Pro5Gen
    amdRyzen5Pro6Gen
    amdRyzen5Pro7Gen

    amdRyzen7Pro2Gen
    amdRyzen7Pro3Gen
    amdRyzen7Pro4Gen
    amdRyzen7Pro5Gen
    amdRyzen7Pro6Gen
    amdRyzen7Pro7Gen

    appleM3Chip
    appleM3ProChip
    appleM3MaxChip
    appleM1UltraChip
    appleM2UltraChip

    storage2tb
    storage1tbHDDPlus128GBSSD
    storage2tbHDDPlus128GBSSD
    storage2tbHDDPlus256GBSSD
    storage2tbHDDPlus512GBSSD
    ram18gb
    ram24gb
    ram64gb
    ram128gb
    FaceSensorIssuePer
    FingerPrintIssuePer
  }
}

`
export const QUERY_GET_SERIES = gql`
query Query {
  getSeries {
    id
    brandName
    seriesName
    createDateTime
    status
  }
}
`
export const QUERY_GET_PRODUCT_BY_SERIES = gql`
query GetProductBySeries($seriesName: String) {
  getProductBySeries(seriesName: $seriesName) {
    id
    productName
    productDescription
    variant
    series
    productType
    category
    productBrand
    image
    productPrice
    createdDateTime
    status
  }
}
`
export const QUERY_GET_SERIES_BY_BRAND = gql`
query GetSeriesByBrand($productBrand: String) {
  getSeriesByBrand(productBrand: $productBrand) {
    id
    brandName
    seriesName
    createDateTime
    status
  }
}
`
export const QUERY_GET_CART_ITEM_BY_ID = gql`
query GetCartItemsByUserId($userId: String) {
  getCartItemsByUserId(userId: $userId) {
    id
    userId
    productId {
      id
      productUniqueId
      productName
      productPrice
      productSubTitle
      productDescription
      warrantyDescription
      AdditionalFeatures
      dimensionsDescrption
      isTrending
      liveLink
      quantity
      screenSize
      type
      networkType
      mrp
      ram
      image
      condition
      storage
      productCategory
      productBrand
      createdDateTime
      status
    }
    productUniqueId
    productName
    description
    image
    price
    incrementPrice
    totalProductPrice
    availableQuantity
    quantity
    category
    storage
    color
    condition
    createDateTime
    status
  }
}
`
export const QUERY_GET_FILTERED_BUY_PRODUCT = gql`
query GetFilterBuyProduct($filterFileld: String, $filterVal: String, $filterFileld2: String, $filterVal2: String) {
  getFilterBuyProduct(filterFileld: $filterFileld, filterVal: $filterVal, filterFileld2: $filterFileld2, filterVal2: $filterVal2) {
    id
    productUniqueId
    productName
    productPrice
    productSubTitle
    productDescription
    warrantyDescription
    AdditionalFeatures
    dimensionsDescrption
    isTrending
    screenSize
    type
    networkType
    liveLink
    quantity
    mrp
    ram
    condition
    storage
    productCategory
    productBrand
    createdDateTime
    status
    image
  }
}

`
export const QUERY_GET_FILTERED_BUY_PRODUCT_BY_CAT = gql`
query GetFilteredByProductBycat($category: String) {
  getFilteredByProductBycat(Category: $category) {
    id
    productUniqueId
    productName
    productPrice
    productSubTitle
    productDescription
    warrantyDescription
    AdditionalFeatures
    dimensionsDescrption
    isTrending
    screenSize
    type
    liveLink
    quantity
    networkType
    mrp
    ram
    image
    condition
    storage
    productCategory
    productBrand
    createdDateTime
    status
  }
}

`
export const GET_SEARCH_BUY_PRODUCT = gql`
query SearchBuyProduct($name: String) {
  searchBuyProduct(name: $name) {
    id
    productUniqueId
    productName
    productPrice
    productSubTitle
    productDescription
    warrantyDescription
    AdditionalFeatures
    dimensionsDescrption
    isTrending
    screenSize
    type
    networkType
    liveLink
    quantity
    mrp
    ram
    image
    condition
    storage
    productCategory
    productBrand
    createdDateTime
    status
  }
}

`
export const GET_ALL_BUY_ORDERS = gql`
query Query {
  getBuyorders {
    id
    orderNo
    userId
    productDetails {
      productId
      productName
      productPrice
      totalPrice
      productImage
      quantity
      category
      storage
      color
      condition
    }
    paymentId
    paymentMethod
    paymentStatus
    totalprice
    fName
    lName
    contact
    alternateContactNo
    email
    address
    landmark
    city
    pincode
    state
    createdDateTime
    status
  }
}

`
export const QUERY_GET_BUYING_ORDERS_USERID = gql`
query Query($userId: String) {
  getBuyOrdersByUserId(userId: $userId) {
    id
    orderNo
    userId
    productDetails {
      productId
      productName
      productPrice
      totalPrice
      productImage
      quantity
      category
      storage
      color
      condition
    }
    paymentId
    paymentMethod
    paymentStatus
    totalprice
    fName
    lName
    contact
    alternateContactNo
    email
    address
    landmark
    city
    pincode
    state
    createdDateTime
    status
  }
}
`
export const QUERY_GET_ALL_REQ = gql`
query GetFormRequest {
  getFormRequest {
    id
    userId
    reqNum
    title
    phone
    email
    address
    reqDetails
    reqCategory
    createdDateTime
    status
  }
}
`
export const QUERY_GET_TRENDING_PRODUCTS = gql`
query Query($category: String) {
  getTrendingBuyProduct(Category: $category) {
    id
    productUniqueId
    productName
    productPrice
    productSubTitle
    productDescription
    warrantyDescription
    AdditionalFeatures
    dimensionsDescrption
    isTrending
    screenSize
    type
    networkType
    liveLink
    quantity
    mrp
    ram
    image
    condition
    storage
    productCategory
    productBrand
    createdDateTime
    status
  }
}

   `
export const QUERY_GET_EXPLORE_BRAND = gql`
   query GetExploreByBrands($category: String) {
    getExploreByBrands(Category: $category) {
      id
      brandName
      brandImage
      category
      createdDateTime
      status
    }
  }
   `
export const QUERY_GET_ALL_OFFER = gql`
   query GetAllOffer {
    getAllOffer {
      id
      category
      offerDesc
      createdDateTime
      status
    }
  }

   `
export const QUERY_GET_OFFER_BY_CAT = gql`
   query GetAllOfferByCategory($category: String) {
    getAllOfferByCategory(Category: $category) {
      id
      category
      offerDesc
      createdDateTime
      status
    }
  }

   `
export const GET_BUY_PRODUCT_BY_PRICERANGE = gql`
   query GetProductByPrice($productRange: String, $category: String) {
    getProductByPrice(ProductRange: $productRange, Category: $category) {
      id
      productUniqueId
      productName
      productPrice
      productSubTitle
      productDescription
      warrantyDescription
      AdditionalFeatures
      dimensionsDescrption
      isTrending
      screenSize
      type
      networkType
      liveLink
      quantity
      quantity
      mrp
      ram
      image
      condition
      storage
      productCategory
      productBrand
      
      mrp
      ram
      createdDateTime
      status
    }
  }
   
   `
export const GET_QUERY_ALL_COUPON = gql`
   query GetAllCoupon {
    getAllCoupon {
      id
      coupon
      category
      couponPercent
      createdDateTime
      status
    }
  }
   `
export const GET_ALL_COUPON_BY_CATEGORY = gql`
   query GetAllCouponByCategory($category: String) {
    getAllCouponByCategory(Category: $category) {
      id
      coupon
      category
      couponPercent
      createdDateTime
      status
    }
  }
   `

export const GET_ALL_PINCODE = gql`
query Query {
  getAllPincode {
    id
    pincode
    city
    createdDateTime
    status
  }
}
`
export const GET_SEARCH_SELL_PRODUCTS = gql`
query SearchSellProduct($name: String, $category: String) {
  searchSellProduct(name: $name, category: $category) {
    id
    productName
    productDescription
    variant
    series
    productType
    category
    productBrand
    image
    productPrice
    createdDateTime
    status
  }
}
`
export const GET_ADDRESS_BY_ID = gql`
query GetAddressByID($addressId: String) {
  getAddressByID(addressId: $addressId) {
    id
    userId
    address
    sellername
    landmark
    city
    pincode
    state
    contactNo
    alternativeContactNo
    email
    addressType
    createDateTime
    status
  }
}

`
export const GET_ALL_TESTIMONIAL = gql`
query GetAllTestimonial {
  getAllTestimonial {
    id
    name
    position
    description
  }
}`
export const GET_ALL_BLOG = gql`
query Query {
  getAllBlog {
    id
    title
    description
    imageKey
    createDateTime
    status
  }
}

`
export const GET_ALL_NEWS = gql`
query Query {
  getAllNews {
    id
    title
    description
    imageKey
    newsLink
    createDateTime
    status
  }
}

`

export const GET_BUYORDER_BY_PAYMENT_ID = gql`
query GetBuyOrderPaymentId($paymentId: String) {
  getBuyOrderPaymentId(paymentId: $paymentId) {
    id
    orderNo
    userId
    totalprice
    paymentId
    paymentMethod
    paymentStatus
    fName
    lName
    contact
    alternateContactNo
    email
    address
    landmark
    city
    pincode
    state
    createdDateTime
    status
    productDetails {
      productId
      productName
      productPrice
      totalPrice
      productImage
      quantity
      category
      storage
      color
      condition
    }
  }
}
`

export const GET_BUY_PRODUCT_BY_ID = gql`
query GetBuyProductById($productId: String) {
  getBuyProductById(productId: $productId) {
    id
    productUniqueId
    productName
    productPrice
    productSubTitle
    productDescription
    warrantyDescription
    AdditionalFeatures
    dimensionsDescrption
    isTrending
    liveLink
    quantity
    screenSize
    type
    networkType
    mrp
    ram
    image
    condition
    storage
    productCategory
    productBrand
    createdDateTime
    status
  }
}

`