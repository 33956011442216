import { Container, Form, Button, Card, Row, Col, Breadcrumb, Image } from "react-bootstrap"
import Footer from "../../Components/Footer"
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import { FaArrowRight, FaDotCircle } from "react-icons/fa";
import { MUTATION_ADD_ADDRESS, MUTATION_PAYMENT } from '../../graphql/Mutations'
import { useMutation } from '@apollo/client'
import { FaSearch, FaArrowCircleRight } from 'react-icons/fa'
import Navigation from "../../Components/Navigation";
import { useContext, useEffect, useState } from "react";
import swal from 'sweetalert';
import { MUTATION_CREATE_BUY_ORDER } from '../../graphql/Mutations'
import { CartContext } from "../Contex/ProductDetailContex";
import { QUERY_GET_BUYING_ORDERS_USERID } from '../../graphql/Query'
import { v4 as uuidv4 } from 'uuid'


const AddBuyAddress = () => {
    const location = useLocation()
    const { cartData, removeItem, emptyCart } = useContext(CartContext);
    const [allProducts, setAllProducts] = useState([])
    const [codAvailableState, setCodAvailableState] = useState(false)


    useEffect(() => {
        window.scrollTo(0, 0)
        if (cartData.length === 0) {
            return navigate('/userDashboard/yourorder')
        }
        setAllProducts([])
        for (let x of cartData) {
            if (x?.productId?.quantity == "0") {
            } else {
                setAllProducts(allProducts => [...allProducts,
                {
                    "productId": x?.productId?.id,
                    "productName": x?.productId?.productName,
                    "totalPrice": `${parseInt(x?.quantity) * parseInt(x?.productId?.productPrice)}`,
                    "productPrice": `${x?.productId?.productPrice}`,
                    "productImage": x?.productId?.image[0],
                    "quantity": parseInt(x?.quantity),
                    "category": x?.productId?.productCategory,
                    "storage": x?.productId?.storage,
                    "color": null,
                    "condition": x?.productId?.condition,
                }
                ])
            }
        }
    }, [cartData])

    const userId = localStorage.getItem('userId')
    const totalprice = location.state?.totalCartPrice;
    const navigate = useNavigate()

    const [nameState, setNameState] = useState()
    const [emailState, setEmailState] = useState()
    const [contactState, setContactState] = useState()
    const [flateState, setFlateState] = useState()
    const [stateState, setStateState] = useState()
    const [cityState, setCityState] = useState()
    const [landmarkState, setLandmarkState] = useState()
    const [pincodeState, setPincodeState] = useState()
    const [alternativeMobState, setAlternativeMobState] = useState()
    const [saveAsState, setSaveAsState] = useState()
    const [errors, setErrors] = useState({}); // State to store validation errors

    const [createSaveAddress, { loading: addressAddLoading }] = useMutation(MUTATION_ADD_ADDRESS)

    const [createBuyOrder] = useMutation(MUTATION_CREATE_BUY_ORDER
        , {
            refetchQueries: [
                QUERY_GET_BUYING_ORDERS_USERID
            ]
        }
    )


    const [initiatePhonePePayment, { data, loading }] = useMutation(MUTATION_PAYMENT)
    const transactionId = "T-HelloFi-" + uuidv4().toString(36).slice(-6)

    const handleProceed = () => {
        if (codAvailableState) {
            createBuyOrder({
                variables: {
                    "buyOrderInput": {
                        "userId": userId,
                        "productDetails": allProducts,
                        "totalprice": `${parseInt(totalprice)}`,
                        "paymentId": `${transactionId}`,
                        "paymentMethod": "COD",
                        "fName": `${nameState}`,
                        // "lName": null,
                        "contact": `${contactState}`,
                        "alternateContactNo": `${alternativeMobState}`,
                        "email": `${emailState}`,
                        "address": `${flateState}`,
                        "landmark": `${landmarkState}`,
                        "city": `${cityState}`,
                        "pincode": `${pincodeState}`,
                        "state": `${stateState}`,
                    }
                }
            }).then(() => {
                emptyCart()
                navigate('/userDashboard/yourorder')
                swal("Order Placed Successfully", "", "success");

            })
        } else {
            initiatePhonePePayment({
                variables: {
                    "amount": totalprice,
                    "transactionId": `${transactionId}`,
                    "contact": `${contactState}`
                }

            }).then(() => {
                createBuyOrder({
                    variables: {
                        "buyOrderInput": {
                            "userId": userId,
                            "productDetails": allProducts,
                            "totalprice": `${totalprice}`,
                            "paymentId": `${transactionId}`,
                            "paymentMethod": "Online",
                            "fName": `${nameState}`,
                            // "lName": null,
                            "contact": `${contactState}`,
                            "alternateContactNo": `${alternativeMobState}`,
                            "email": `${emailState}`,
                            "address": `${flateState}`,
                            "landmark": `${landmarkState}`,
                            "city": `${cityState}`,
                            "pincode": `${pincodeState}`,
                            "state": `${stateState}`,
                        }
                    }
                }).then(() => {
                    emptyCart()
                    //  navigate('/userDashboard/yourorder')

                }).catch((error) => {
                    console.error("Error initiating payment:", error);
                    // Handle payment error, show error message to the user
                });
            })
        }
    }


    if (data) {
        return window.location.href = data && data.initiatePhonePePayment
    }





    const validateForm = () => {
        const errors = {};

        if (!nameState) {
            errors.name = "Name is required";
        }

        if (!emailState) {
            errors.email = "Email is required";
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailState)) {
            errors.email = "Invalid email format";
            setEmailState("")
        }

        if (!contactState) {
            errors.contact = "Contact number is required";
        } else if (!/^\d{10}$/.test(contactState)) {
            errors.contact = "Contact number must be 10 digits";
            setContactState("")

        }
        if (alternativeMobState) {
            if (!/^\d{10}$/.test(alternativeMobState)) {
                errors.alternativeMob = "Alternative contact number must be 10 digits";
                setAlternativeMobState(""); // Clear the value 
            }
        }
        if (!pincodeState) {
            errors.pincode = "PIN code is required";
        } else if (!/^\d{6}$/.test(pincodeState)) {
            errors.pincode = "PIN code must be 6 digits";
            setPincodeState("")
        }



        // Add similar validation for other fields

        setErrors(errors);

        // Check if there are any validation errors
        return Object.keys(errors).length === 0;
    };
    return (
        <>
            <Navigation />
            <div className="banner">
                <h2>
                    Please Fill Your Address
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                {/* <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/allbrands">Select Brand</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Sell Product</Breadcrumb.Item>
                </Breadcrumb> */}

            </div>
            <Container style={{ marginTop: "1rem" }}>
                <Row>
                    <Col md={6}>
                        <Card style={{ padding: "2rem", height: "100%", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }}>
                            <Form.Group className="mb-3"  >
                                <Form.Control type="text" placeholder="Enter Name" name="name" style={{ fontWeight: "bold" }} onChange={(e) => setNameState(e.target.value)} value={nameState} />
                                {errors.name && <p className="text-danger">{errors.name}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="email" placeholder="Enter Email" style={{ fontWeight: "bold" }} onChange={(e) => setEmailState(e.target.value)} value={emailState} />
                                {errors.email && <p className="text-danger">{errors.email}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Control type="number" placeholder="Enter Contact Number" name="phone" style={{ fontWeight: "bold" }} onChange={(e) => setContactState(e.target.value)} value={contactState} />
                                {errors.contact && <p className="text-danger">{errors.contact}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Flat no / Office" name="address" style={{ fontWeight: "bold" }} onChange={(e) => setFlateState(e.target.value)} value={flateState} />

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Enter Your State" style={{ fontWeight: "bold" }} onChange={(e) => setStateState(e.target.value)} value={stateState} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Enter Your City" style={{ fontWeight: "bold" }} onChange={(e) => setCityState(e.target.value)} value={cityState} />
                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="text" placeholder="Landmark" style={{ fontWeight: "bold" }} onChange={(e) => setLandmarkState(e.target.value)} value={landmarkState} />
                            </Form.Group>
                            <Form.Group className="mb-3" >

                                <Form.Control type="number" placeholder="Pincode" style={{ fontWeight: "bold" }} onChange={(e) => setPincodeState(e.target.value)} value={pincodeState} />
                                {errors.pincode && <p className="text-danger">{errors.pincode}</p>}

                            </Form.Group>
                            <Form.Group className="mb-3" >
                                <Form.Control type="number" placeholder="Alternative Phone Number (Optional)" style={{ fontWeight: "bold" }} onChange={(e) => setAlternativeMobState(e.target.value)} value={alternativeMobState} />
                                {errors.alternativeMob && <p className="text-danger">{errors.alternativeMob}</p>}
                            </Form.Group>
                            <Form.Group>
                                <Form.Label as={Col} column sm={2} style={{ fontWeight: "bold" }}>
                                    Save as
                                </Form.Label>
                            </Form.Group>
                            <Form.Group as={Row}>
                                <Col sm={10} style={{ fontWeight: "bold" }}>
                                    <Form.Check
                                        type="radio"
                                        label="Home"
                                        name="radioGroup"
                                        value="Home"
                                        onChange={(e) => setSaveAsState(e.target.value)}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Office"
                                        name="radioGroup"
                                        value="Office"
                                        onChange={(e) => setSaveAsState(e.target.value)}
                                        inline
                                    />
                                    <Form.Check
                                        type="radio"
                                        label="Other"
                                        name="radioGroup"
                                        value="Other"
                                        onChange={(e) => setSaveAsState(e.target.value)}
                                        inline
                                    />
                                </Col>
                            </Form.Group>
                            {
                                totalprice <= 20000 ?

                                    <Form.Group className="mb-3 mt-3 fw-bold">
                                        <Form.Check
                                            type="checkbox"
                                            label="Order via Cash on Delivery"
                                            name="codAvailable"
                                            onChange={(e) => setCodAvailableState(e.target.checked)}
                                            inline
                                        />
                                    </Form.Group> : ""

                            }
                            <Card.Footer style={{ marginTop: "1rem" }} >
                                <Button className="mx-auto d-block" disabled={nameState && emailState && contactState && flateState && stateState && cityState && pincodeState && saveAsState ? false : true} variant="warning" onClick={() => handleProceed()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}> Place Order </Button>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col >
                        <Card style={{ height: "50%", boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px" }} className="d-none d-sm-block">
                            <Card.Body>
                                <Card.Title style={{ fontWeight: "bold" }}>Price Summary</Card.Title>
                                {/* <Image fluid style={{ width: "14rem", margin: "1rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location?.state?.imgurl}`} className='mx-auto d-block' /> */}
                                <Card.Text style={{ marginTop: "6rem", fontWeight: "bold" }} className="text-center fs-1 " > Total Price  <span style={{ color: "rgb(64, 167, 55)" }}> ₹ {location.state?.totalCartPrice} </span></Card.Text>
                                {/* <Card.Text className="text-center fs-5" style={{ fontWeight: "bold" }}>Pick Up Charges ₹<span style={{ color: "rgb(64, 167, 55)" }}> 00 </span></Card.Text> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AddBuyAddress 