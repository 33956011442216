import React, { useState } from 'react'
import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap'
import Navigation from '../Navigation'
import Footer from '../Footer'
import img1 from '../../Images/Messenger-pana.png'
import img2 from '../../Images/Delivery-amico.png'
import img3 from '../../Images/driving school-cuate.png'
import img4 from '../../Images/In no time-pana.png'
import '../../ComponentsCss/userCss/Userlogin.css'
import Slider from 'react-slick'
import { useMutation, useQuery } from '@apollo/client';
import { Navigate, useNavigate } from 'react-router-dom';
import { MUTATION_SEND_USEROTP, MUTATION_USER_OTP_LOGIN } from '../../graphql/Mutations'
import swal from 'sweetalert';
import { QUERY_GET_ALL_USER } from '../../graphql/Query'
import imglog from '../../Images/5856-removebg-preview.png'

export default function Userlogin( {isPurchacing,handleClose2}) {
    const navigate = useNavigate()
    const userId = localStorage.getItem('userId')

    const userToken = localStorage.getItem('userToken')

    const { data: userData } = useQuery(QUERY_GET_ALL_USER)

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [gOtp, setGeneratedOtp] = useState('')
    const [contact, setContact] = useState('')
    const [otpSent, setOtpSent] = useState(false)
    const [enteredOtp, setEnteredOtp] = useState('')

    const [userLoginDone, setUserLoginDone] = useState(false)
    const [sendOtp] = useMutation(MUTATION_SEND_USEROTP)
    const [userOtpLogin, { data: logindata, error }] = useMutation(MUTATION_USER_OTP_LOGIN)

    // const { data: user_data } = useQuery(GET_USERDATA_BY_USERID,{
    //   variables:{
    //     "userId": `${userId}`
    //   }
    // })

    const [otpSendClick, setOtpSendClick] = useState(false)
    const [otpState, setOtpState] = useState('')
    const [otpError, setOtpError] = useState(false)
    const [otpResend, setOtpResend] = useState(false)


    function generateUserOtp(event) {
        if (contact === '' || contact.length < 10 || contact.length > 10) {
            setValidated(true)
            event.preventDefault();
            event.stopPropagation();
        }
        else {
            event.preventDefault();
            const gOtp = Math.floor(1000 + Math.random() * 9000);
            setValidated(false)
            localStorage.removeItem("userOtp");
            localStorage.setItem('userOtp', gOtp);
            sendOtp({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${gOtp}`
                }
            })
            swal({
                title: "OTP Send",
                text: "OTP Send on your mobile no.",
                icon: "success",
            })
            setGeneratedOtp(gOtp)
            setOtpSent(true)
        }
    }

    function handleClick(event) {
        const otp = localStorage.getItem('userOtp');
        if (otp === otpState) {
            setValidated2(true);
            event.preventDefault();
            event.stopPropagation();
            userOtpLogin({
                variables: {
                    "contact": `${contact}`,
                    "otp": `${otp}`
                }
            }).then((data)=>{
                localStorage.setItem('userId', data.data.userOtpLogin.userId)
                localStorage.setItem('userToken', data.data.userOtpLogin.userToken)
                localStorage.setItem('userTokenExpiration', data.data.userOtpLogin.userTokenExpiration)
                localStorage.removeItem("userOtp")
                
              if(isPurchacing){
                handleClose2()
                }else{
                    navigate("/")
                }
              return 0
            })
        } else {
            event.preventDefault();
            setValidated2(false);
            alert("OTP Not Match !!!")
        }
    }


    function contactEnterAgain() {
        localStorage.removeItem("userOtp")
        setOtpError(false)
        setContact('')
        setOtpState('')
        setOtpSendClick(false)
    }

    function otpSendAgain() {
        setOtpResend(true)
        setOtpError(false)
        localStorage.removeItem("userOtp")
        setOtpState('')
        generateUserOtp()
    }


    if (logindata) {
        localStorage.setItem('userId', logindata.userOtpLogin.userId)
        // setUserLoginDone(true)
        localStorage.setItem('userToken', logindata.userOtpLogin.userToken)
        localStorage.setItem('userTokenExpiration', logindata.userOtpLogin.userTokenExpiration)
        localStorage.removeItem("userOtp")
    }
    if (userToken) {
        if(isPurchacing){
            handleClose2()
        }
        else{
            navigate("/")
        }
        
        return 0
    }

    // if (userLoginDone) {
    //     window.location.reload()
    //     return <Navigate to='/userDashboard' />
    // }

    var settings = {
        arrows: true,
        dots: true,
        pauseOnHover: true,
        infinite: true,
        speed: 2500,
        autoplay: true,
        fade: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
        {isPurchacing ? "" :  <Navigation />}
           
            <Container className='loginmain'>
            <div className='bglog'></div>
                <Row>
                    <Col md={3}></Col>
                    <Col md={6}>
                        <Card className='logCard mx-auto d-block'>
                           <div className='imglogcard mx-auto d-block'>
                            <Image src={imglog} style={{height:'100%',width:'100%'}}></Image>
                           </div>
                           <h2 className='txtlogin'>Login Now</h2>
                           <Row>
                            <Col md={2}></Col>
                            <Col md={8}>
                           {
                                    otpSent ?
                                        <>
                                            <Form validated={validated2} onSubmit={handleClick} >
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label style={{ marginTop: 20 }}>Enter OTP</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Enter OTP"
                                                            className='txtbx'
                                                            onChange={(e) => setOtpState(e.target.value)} value={otpState}
                                                        />
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>

                                                </Row>

                                                <Button type="submit" className='btnlogin mx-auto d-block'>Login</Button>
                                            </Form>
                                        </>
                                        :
                                        <>
                                            <Form validated={validated} onSubmit={generateUserOtp}>
                                                <Row className="mb-3">
                                                    <Form.Group as={Col} md="12" controlId="validationCustom01">
                                                        <Form.Label style={{ marginTop: 20 }}>Enter Mobile No.</Form.Label>
                                                        <Form.Control
                                                            required
                                                            type="text"
                                                            placeholder="Mobile No."
                                                            className='txtbx'
                                                            onChange={(e) => setContact(e.target.value)} value={contact}
                                                        />
                                                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                                    </Form.Group>

                                                </Row>

                                                <Button type="submit" className='btnlogin mx-auto d-block'>Send OTP</Button>
                                            </Form>
                                        </>

                                }
                            </Col>
                            <Col md={2}></Col>
                           </Row>
                        </Card>
                    </Col>
                    <Col md={3}></Col>
                </Row>
            </Container>
            {isPurchacing ? "" :  <Footer />}

            
        </>
    )
}
