import React, { useEffect } from "react";
import Footer from "../Footer";
import Navigation from "../Navigation";
const AboutUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Navigation />
            <div className="term-container">
                <div className="about-us-content" >
                    <h1>About Us</h1>
                    <p>It is the year 2023 when articles about global warming are all time trending on every platform. Pratish and Sourav were in their cubicles reading them and wanted to take a step in making a change that would change the course of devastation the planet was moving towards. The news that was spreading in parallel was of the new iPhone 15 launch, and there were many people around who were going to abandon their iPhone 14 to try the new tech, while there were some who were still dreaming of their first phone. It was then they came together to start HelloFi Recommerce an environment-friendly platform for affordable tech.</p>
                    <p>At HelloFi, you can sell your used electronics at the best prices and make room for the new ones. We not only provide you with the best prices for your used products but also offer you a wide range of products at the most affordable rates, which have gone through quality checks through advanced technological means. And make sure that every product that comes to you is perfect for you.</p>
                    <p>We all want to walk hand in hand with technology, but the weight of old tech slows us down. Selling our old gadgets is like funding for a new one. Nature is also thankful to you!</p>
                    <p>Do you know E-waste comprises 70% of our overall toxic waste and only 12.5% of it is recycled? While 85% of it ends up in landfills where harmful toxins are absorbed by the soil which further pollutes the groundwater. In worst cases, it is also burned, releasing harmful gases into the atmosphere. When we recycle, we not only earn money but also health. The toxins that are released and absorbed by the environment end up getting consumed by us in some form or the other, being the root cause of most life-threatening diseases. If not disposed of properly, our furry friends who rely mostly on dumpster food end up consuming it and suffer painfully for mistakes they did not commit.</p>
                    <p>By recycling, we save energy, environment, and money. It's an investment in the present and future, an investment in your fun and health. So, what are you waiting for? Go ahead, upgrade yourself, make that stunning design on the new Mac, and give us your Windows - we will find a home for it where uncle is still maintaining his register and could really use Excel.</p>
                    <h2 className="highlighted-tagline">HelloFi - A cycle where everyone benefits.</h2>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
