import React, { useState } from 'react'

import { Spinner, Table, Image, Button, Modal, Card, Form, Dropdown } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import swal from 'sweetalert';
import { QUERY_All_BUY_ORDERS } from '../../graphql/Query'
import { MUTATION_DELETE_BUY_ORDER } from '../../graphql/Mutations'
import { MUTATION_UPDATE_BUY_ORDER } from '../../graphql/Mutations'
import { MUTATION_TRENDING } from '../../graphql/Mutations'
import { FaEye, FaPen } from 'react-icons/fa'

import {
    BtnBold,
    BtnItalic,
    Editor,
    EditorProvider,
    Toolbar,
    createButton
} from 'react-simple-wysiwyg';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const AllBuyOrders = () => {
    const { data: buyOrderData, loading: buyProLoading } = useQuery(QUERY_All_BUY_ORDERS);

    const [deleteBuyOrderByID] = useMutation(MUTATION_DELETE_BUY_ORDER, {
        refetchQueries: [
            QUERY_All_BUY_ORDERS
        ]
    })

    const [updateBuyProduct] = useMutation(MUTATION_UPDATE_BUY_ORDER, {
        refetchQueries: [
            QUERY_All_BUY_ORDERS
        ]
    })
    const [updateTrendingProduct] = useMutation(MUTATION_TRENDING, {
        refetchQueries: [
            QUERY_All_BUY_ORDERS
        ]
    })

    let count = 1
    const handleDelete = (id) => {
        deleteBuyOrderByID({
            variables: {
                "productId": `${id}`
            }
        }).then(() => {
            swal({
                title: 'Successfull!!!',
                text: ' Product Deleted Successfully',
                icon: 'success',
            });

        }).catch((e) => console.log(e))


    }
    // edit modal
    const BtnAlignCenter = createButton('Align center', '≡', 'justifyCenter');

    const [lgShow, setLgShow] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState("");
    const [editProductName, setEditProductName] = useState("");
    const [editProductSubtitle, setEditProductSubtitle] = useState("");
    const [editProductPrice, setEditProductPrice] = useState("");
    const [editProductDescription, setEditProductDesc] = useState("");
    const [editWarrantDesc, setEditWarrantyDesc] = useState("");
    const [editProBrand, setEditProBrand] = useState("");
    const [editProStorage, setEditProStorage] = useState("");
    const [editProCategory, setEditProCategory] = useState("");
    const [editProCondition, setEditProCondition] = useState("");
    const [editProMRP, setEditProMRP] = useState("");
    const [editProRAM, setEditProRAM] = useState("");
    const [editProLink, setEditProLink] = useState("");
    const [editProReturnPolicy, setEditReturnPolicy] = useState("");
    const [editProPackInfo, setEditPackInfo] = useState("");
    const [networkType, setNetworkType] = useState("");
    const [editQuantity, setEditQuantity] = useState("");
    const handleEdit = (order) => {
        setLgShow(true);
        setSelectedOrder(order)
        setEditProBrand(order.productBrand)
        setEditQuantity(order.quantity)
        setEditProStorage(order.storage)
        setEditProCategory(order.productCategory)
        setEditProCondition(order.condition)
        setEditProductName(order.productName)
        setEditProductSubtitle(order.productSubTitle)
        setEditProductDesc(order.productDescription)
        setEditProductPrice(order.productPrice)
        setEditWarrantyDesc(order.warrantyDescription)
        setEditProMRP(order?.mrp)
        setEditProRAM(order?.ram)
        setEditProLink(order?.liveLink)
        setEditReturnPolicy(order?.dimensionsDescrption)
        setEditPackInfo(order?.AdditionalFeatures)
        setNetworkType(order?.networkType)

    }

    const handleEditChanges = () => {

        updateBuyProduct({
            variables: {
                "productInput": {
                    "id": `${selectedOrder.id}`,
                    "productName": `${editProductName}`,
                    "productPrice": `${editProductPrice}`,
                    "productSubTitle": `${editProductSubtitle}`,
                    "productDescription": `${editProductDescription}`,
                    "warrantyDescription": `${editWarrantDesc}`,
                    "storage": `${editProStorage}`,
                    "condition": `${editProCondition}`,
                    "productCategory": `${editProCategory}`,
                    "productBrand": `${editProBrand}`,
                    "liveLink": `${editProLink}`,
                    "mrp": `${editProMRP}`,
                    "ram": `${editProRAM}`,
                    "dimensionsDescrption": `${editProReturnPolicy}`,
                    "AdditionalFeatures": `${editProPackInfo}`,
                    "networkType": `${networkType}`,
                    "quantity": `${editQuantity}`,
                }
            }
        }).then(() => {
            swal({
                title: 'Successfull!!!',
                text: ' Product Edited Successfully',
                icon: 'success',
            });

        }).catch((e) => {
            console.log(e);
        })
        setLgShow(false)
    }
    const [show, setShow] = useState(false);
    const [productDesc, setProductDesc] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [show2, setShow2] = useState(false);
    const [productWarranty, setProductWarranty] = useState("");

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const handleDescClick = (desc) => {
        setProductDesc(desc)
        handleShow()
    }
    const handleWarranrtClick = (warranty) => {
        setProductWarranty(warranty)
        handleShow2()
    }
    // handle dimension show
    const [show4, setShow4] = useState(false);
    const [productDimension, setProductDimension] = useState("");

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);


    const handleDimensionClick = (dimension) => {
        setProductDimension(dimension)
        handleShow4()
    }
    // handle Addition Feature show
    const [show5, setShow5] = useState(false);
    const [productAddFeature, setProductAddFeature] = useState("");

    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);


    const handleAdditionalFeatureClick = (feature) => {
        setProductAddFeature(feature)
        handleShow5()
    }
    // image click handling
    const [show3, setShow3] = useState(false);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [imagesState, setImagesState] = useState([])

    const handleImageClick = (images) => {
        setImagesState(images)
        handleShow3()
    }
    const handleTrendingClick = (proId, bool) => {
        updateTrendingProduct({
            variables: {
                "proId": proId,
                "isTrending": bool

            }
        }).then(() => {
            swal({
                title: 'Successfull!!!',
                text: 'Product Updated in Trending Section',
                icon: 'success',
            });

        }).catch((e) => {
            console.log(e);
        })
    }
    return (
        <>
            <Table responsive striped bordered hover style={{ fontSize: "14px", fontWeight: "bold", display: "block", height: "600px", width: "100%" }}>
                <thead className='table-head'>
                    <tr>
                        <th>s.no.</th>
                        <th>Produc Name</th>
                        <th>Quantity</th>
                        <th>Brand</th>
                        <th>Produc Subtitle</th>
                        <th>Product Price</th>
                        <th>MRP.</th>
                        <th>Storage</th>
                        <th>RAM</th>
                        <th>Live Link</th>
                        <th>Category</th>
                        <th>Condition</th>
                        <th>Network Type</th>
                        <th>Screen Size</th>
                        <th>Product Type</th>
                        <th>Product Description</th>
                        <th>Warranty Description</th>
                        <th>Return Policy </th>
                        <th>Pakaging info</th>
                        <th>Images</th>
                        <th>Edit</th>
                        <th>Delete</th>
                        <th>Trending option</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        buyProLoading ?
                            <Spinner animation="border" variant="success" className='mx-auto d-block' />
                            : buyOrderData && buyOrderData?.getAllBuyingProduct.slice(0).reverse().map((order) =>
                                <tr>
                                    <td>
                                        {count++}
                                    </td>
                                    <td>
                                        {order.productName}
                                    </td>
                                    <td>
                                        {order?.quantity }
                                    </td>
                                    <td>
                                        {order.productBrand}
                                    </td>
                                    <td>
                                        {order.productSubTitle}
                                    </td>
                                    <td>
                                        {order.productPrice}
                                    </td>
                                    <td>
                                        {order.mrp}
                                    </td>
                                    <td>
                                        {order.storage}
                                    </td>
                                    <td>
                                        {order.ram}
                                    </td>
                                    <td>
                                        {order.liveLink}
                                        <a href={order.liveLink} target='_blank' > check </a>
                                    </td>
                                    <td>
                                        {order.productCategory}
                                    </td>
                                    <td>
                                        {order.condition}
                                    </td>
                                    <td>
                                        {order?.networkType}
                                    </td>
                                    <td>
                                        {order?.screenSize}
                                    </td>
                                    <td>
                                        {order?.type}
                                    </td>
                                    <td>
                                        <td style={{ cursor: "pointer" }} className='mx-auto d-block' onClick={() => handleDescClick(order.productDescription)} >  <  FaEye /> </td>


                                    </td>
                                    <td>
                                        <td style={{ cursor: "pointer" }} className='mx-auto d-block' onClick={() => handleWarranrtClick(order.warrantyDescription)} >  <  FaEye /> </td>

                                    </td>
                                    <td>
                                        <td style={{ cursor: "pointer" }} className='mx-auto d-block' onClick={() => handleDimensionClick(order?.dimensionsDescrption)} >  <  FaEye /> </td>

                                    </td>
                                    <td>
                                        <td style={{ cursor: "pointer" }} className='mx-auto d-block' onClick={() => handleAdditionalFeatureClick(order?.AdditionalFeatures)} >  <  FaEye /> </td>

                                    </td>
                                    <td>
                                        <td style={{ cursor: "pointer" }} className='mx-auto d-block' onClick={() => handleImageClick(order?.image)} >  <  FaEye /> </td>


                                    </td>
                                    <td> <Button style={{ fontSize: "11px" }} onClick={() => handleEdit(order)}> Edit  </Button> </td>
                                    <td> <Button variant='danger' style={{ fontSize: "11px" }} onClick={() => handleDelete(order.id)} > Delete  </Button> </td>
                                    {
                                        order?.isTrending ?
                                            <td> <Button variant='danger' style={{ fontSize: "11px" }} onClick={() => handleTrendingClick(order.id, false)} > Remove from Trending </Button> </td>
                                            :
                                            <td> <Button variant='success' style={{ fontSize: "11px" }} onClick={() => handleTrendingClick(order.id, true)} > Add to Tending </Button> </td>
                                    }
                                </tr>

                            )
                    }
                </tbody>
            </Table>
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => setLgShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton >
                    <Modal.Title>
                        Edit Buy Products
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Card style={{ width: '46rem' }} className='mx-auto d-block' >
                        <Card.Body>
                            <Form   >
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={editProductName}
                                        onChange={(e) => setEditProductName(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Quatity</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        value={editQuantity}
                                        onChange={(e) => setEditQuantity(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Brand</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={editProBrand}
                                        onChange={(e) => setEditProBrand(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Subtitle</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        placeholder="Product Subtitle"
                                        value={editProductSubtitle}
                                        onChange={(e) => setEditProductSubtitle(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Price</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Product Price (Rs)"
                                        value={editProductPrice}
                                        onChange={(e) => setEditProductPrice(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product MRP</Form.Label>
                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Product MRP (Rs)"
                                        value={editProMRP}
                                        onChange={(e) => setEditProMRP(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Link</Form.Label>
                                    <Form.Control
                                        required
                                        type="url"
                                        placeholder="Product link"
                                        value={editProLink}
                                        onChange={(e) => setEditProLink(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Storage</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={editProStorage}
                                        onChange={(e) => setEditProStorage(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product RAM</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        value={editProRAM}
                                        onChange={(e) => setEditProRAM(e.target.value)}
                                    />
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Dropdown style={{ marginTop: "1rem" }}>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }} > Category</Dropdown.Toggle>
                                    <span>{editProCategory}</span>
                                    <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }}>
                                        <div style={{ overflowY: 'scroll', height: '200px' }}>

                                            <Dropdown.Item onClick={(e) => setEditProCategory("Phones")}>Phones</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setEditProCategory("Laptops")}>Laptops</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setEditProCategory("Tablets")}>Tablets</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setEditProCategory("Smart Watches")}>Smart Watches</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setEditProCategory("Desktop/Imac")}>Desktop/Imac</Dropdown.Item>
                                            <Dropdown.Item onClick={(e) => setEditProCategory("Other Accessories")}>Other Accessories</Dropdown.Item>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>
                                <Form.Group md="12" controlId="validationCustom02">
                                    <Dropdown style={{ marginTop: "1rem" }}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }}> Product Condition </Dropdown.Toggle>
                                        <span>{editProCondition}</span>
                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }}>
                                            <div style={{ overflowY: 'scroll', height: '120px' }}>

                                                <Dropdown.Item onClick={(e) => setEditProCondition("Fair")}>Fair</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setEditProCondition("Good")}>Good</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setEditProCondition("Superb")}>Superb</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setEditProCondition("Unboxed")}>Unboxed</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setEditProCondition("Partially-Fair")}>Partially-Fair</Dropdown.Item>

                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group md="12" controlId="validationCustom02">
                                    <Dropdown style={{ marginTop: "1rem" }}>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" style={{ width: "100%" }}> Network Type </Dropdown.Toggle>
                                        <span>{networkType}</span>
                                        <Dropdown.Menu       popperConfig={{
                                            modifiers: [
                                                {
                                                    name: 'flip',
                                                    enabled: false // Disable flipping to prevent opening in top direction
                                                },
                                                {
                                                    name: 'offset',
                                                    options: {
                                                        offset: [0, 10] // Adjust the offset as needed
                                                    }
                                                }
                                            ]
                                        }}style={{ width: "100%" }}>
                                            <div >

                                                <Dropdown.Item onClick={(e) => setNetworkType("4G")}>4G</Dropdown.Item>
                                                <Dropdown.Item onClick={(e) => setNetworkType("5G")}>5G</Dropdown.Item>


                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Product Description</Form.Label>
                                    <EditorProvider >
                                        <Editor
                                            value={editProductDescription}
                                            onChange={(e) => setEditProductDesc(e.target.value)}
                                            style={{ height: "25rem" }}>
                                            <Toolbar>
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnAlignCenter />
                                            </Toolbar>
                                        </Editor>
                                    </EditorProvider>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Warranty  Description</Form.Label>
                                    <EditorProvider>
                                        <Editor
                                            value={editWarrantDesc}
                                            onChange={(e) => setEditWarrantyDesc(e.target.value)}
                                            style={{ height: "25rem" }}>
                                            <Toolbar>
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnAlignCenter />

                                            </Toolbar>
                                        </Editor>
                                    </EditorProvider>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Return Policy</Form.Label>
                                    <EditorProvider>
                                        <Editor
                                            value={editProReturnPolicy}
                                            onChange={(e) => setEditReturnPolicy(e.target.value)}
                                            style={{ height: "25rem" }}>
                                            <Toolbar>
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnAlignCenter />

                                            </Toolbar>
                                        </Editor>
                                    </EditorProvider>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>Pakaging Info</Form.Label>
                                    <EditorProvider>
                                        <Editor
                                            value={editProPackInfo}
                                            onChange={(e) => setEditPackInfo(e.target.value)}
                                            style={{ height: "25rem" }}>
                                            <Toolbar>
                                                <BtnBold />
                                                <BtnItalic />
                                                <BtnAlignCenter />

                                            </Toolbar>
                                        </Editor>
                                    </EditorProvider>
                                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                </Form.Group>
                            </Form>
                        </Card.Body>
                    </Card>

                </Modal.Body>
                <Modal.Footer>

                    <Button variant="primary" onClick={() => handleEditChanges()}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal >
            <Modal show={show} onHide={handleClose}
                size="lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ReactHtmlParser(productDesc)}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2}
                size="lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Warranty Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ReactHtmlParser(productWarranty)}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={show4} onHide={handleClose4}
                size="lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Dimension Description</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ReactHtmlParser(productDimension)}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose4}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>

            <Modal show={show5} onHide={handleClose5}
                size="lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Additional Feature</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {ReactHtmlParser(productAddFeature)}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose5}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
            <Modal show={show3} onHide={handleClose3}
                size="lg"

            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        imagesState?.map((image) => {
                            return (
                                <Image fluid src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${image}`} style={{ height: "4rem", margin: "3px" }}></Image>

                            )
                        })
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AllBuyOrders