import Footer from "../../Footer"
import Navigation from "../../Navigation"
import { Container, Form, Button, Card, Row, Col, Breadcrumb, Image, Dropdown, Accordion, Modal } from "react-bootstrap"
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import BankImage from '../../../Images/paymentsIcons/bank-building.png'
import UpiImage from '../../../Images/paymentsIcons/upi-icon.png'
import Cod from '../../../Images/paymentsIcons/cod.png'
import phonePayImage from '../../../Images/paymentsIcons/phonepe-logo-icon.png'
import GooglePAyImage from '../../../Images/paymentsIcons/NicePng_pay-icon-png_7692873.png'
// import { FaSearch,FaArrowCircleRight} from 'react-icons/fa'
import { FaArrowRight } from "react-icons/fa";
import { useMutation, useQuery } from '@apollo/client'

import { MUTATION_CREATE_SELL_ORDER } from '../../../graphql/Mutations'
import { QUERY_GET_All_SELLING_ORDERS } from '../../../graphql/Query'
import { GET_ALL_PINCODE } from '../../../graphql/Query'
import swal from 'sweetalert';
import { useEffect, useState } from "react";

const CheckOutPayment = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const userId = localStorage.getItem('userId')
    const [addBankDetailModal, setAddBankDetailModal] = useState(false);

    const [paymentMethodState, setPaymentMethod] = useState("");
    const [addUpiModal, setAddUpiModal] = useState(false);
    const [bankNameState, setBankNameState] = useState("");
    const [bankHolderNameState, setBankHolderNameState] = useState("");
    const [bankIfscState, setBankIfscState] = useState("");
    const [bankAccountNotate, setBankAccountNoState] = useState("");
    const [upiState, setUpiState] = useState("");
    const [pincode, setPincode] = useState(null);

    const [validated, setValidated] = useState(false);
    const [validated2, setValidated2] = useState(false);
    const [cashClicked, setCashClicked] = useState(false);
    const [showCityModal, setShowCityModal] = useState(false);

    const { data: getAllPincode, loading: allBrandLoading } = useQuery(GET_ALL_PINCODE);

    useEffect(() => {
        handlePincodeCheck()
    }, [getAllPincode])
    const handleSubmit2 = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated2(true);
        if (upiState) {
            setAddUpiModal(false)
            setPaymentMethod("UPI")
            swal({
                title: "Success",
                text: "UPI Id Added Successfully",
                icon: "success",
            })
        }
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
        if (bankNameState && bankHolderNameState && bankIfscState && bankAccountNotate) {
            setAddBankDetailModal(false)
            setPaymentMethod("Bank")
            swal({
                title: "Success",
                text: "Bank Details Added Successfully",
                icon: "success",
            })

        }
    };

    const [createSellOrder, { loading: sellOrderLoading }] = useMutation(MUTATION_CREATE_SELL_ORDER, {
        refetchQueries: [
            QUERY_GET_All_SELLING_ORDERS
        ]
    })
    const handleClose = () => {
        setAddBankDetailModal(false)
    }
    const handleClose2 = () => {
        setAddUpiModal(false)

    }
    const handleClose3 = () => {
        setShowCityModal(false)

    }
    const handleSubmit3 = () => {

    }
    const handleProceed = () => {
        if (cashClicked) {
            setPaymentMethod("Cash")
        }
        if (location.state.productCategory === "Laptops") {

            createSellOrder({
                variables: {
                    "sellOrderInput": {
                        "userId": `${userId}`,
                        "productCategory": `${location?.state.productCategory}`,
                        "imageKey": `${location?.state.imgurl}`,
                        "productBrand": `${location?.state.productBrand}`,
                        "productName": `${location?.state.productName}`,
                        "storage": `${location?.state.laptopStorage}`,
                        "ram": `${location?.state.laptopRam}`,
                        "details": [{
                            "que": "1. Is your device switching on?",
                            "ans": `${location.state.isDeviceSwitch}`
                        },
                        {
                            "que": "2. Any issue with your device display",
                            "ans": `${location.state.issueWithDeviceDisplay}`
                        }, {
                            "que": "3. Tell us about your gadget’s screen?",
                            "ans": `${location.state.deviceScreen}`
                        }, {
                            "que": "4. Is your device ever repaired?",
                            "ans": `${location.state.deviceEverRepair}`
                        },
                        {
                            "que": "defects",
                            "ans": `${location.state.defects}`
                        }
                            ,
                        {
                            "que": "condition of your device screen",
                            "ans": `${location.state.ConditionDeviceScreen}`
                        }
                            ,
                        {
                            "que": "condition of your device Body",
                            "ans": `${location.state.ConditionDeviceBody}`
                        }
                            ,
                        {
                            "que": "Processor",
                            "ans": `${location.state.processorState}`
                        },
                        {
                            "que": "Have Graphic Card",
                            "ans": `${location.state.graphicsState}`
                        },
                        {
                            "que": "Screen Size",
                            "ans": `${location.state.screenSizeState}`
                        },
                        {
                            "que": "Have Box",
                            "ans": `${location.state.HaveBoxWithIMEI}`
                        }
                            ,
                        {
                            "que": "Have Bill",
                            "ans": `${location.state?.HaveBill}`
                        },
                        {
                            "que": "Have Power Cable",
                            "ans": `${location.state.powerCableClick}`
                        }
                            ,
                        {
                            "que": "Have Adapter",
                            "ans": `${location.state.HaveAdapter}`
                        }

                            ,
                        {
                            "que": "Purchased Place",
                            "ans": `${location.state.purchasedPlaceClick}`
                        }
                            ,
                        {
                            "que": "Additional Insurance",
                            "ans": `${location.state.additionalClick}`
                        }
                            ,
                        {
                            "que": "Warranty Period",
                            "ans": `${location.state.warrantyPeriod}`
                        }
                            ,
                        {
                            "que": "Under Finance",
                            "ans": `${location.state.financeClick}`
                        }


                        ],
                        "addressName": `${location?.state.nameState}`,
                        "addressEmail": `${location?.state.emailState}`,
                        "addressContact": `${location?.state.contactState}`,
                        "addressFlat": `${location?.state.flateState}`,
                        "addressState": `${location?.state.stateState}`,
                        "addressCity": `${location?.state.cityState}`,
                        "addressLandmark": `${location?.state.landmarkState}`,
                        "addressPincode": `${location?.state.pincodeState}`,
                        "addressAlternatContact": `${location?.state.alternativeMobState}`,
                        "addressType": `${location?.state.saveAsState}`,
                        "pickupDate": `${location?.state.pickupDateState}`,
                        "pickupTime": `${location?.state.pickupTimeState}`,
                        "paymentMethod": `${paymentMethodState}`,
                        "bankName": `${bankNameState}`,
                        "bankAccountHolderName": `${bankHolderNameState}`,
                        "bankIfsc": `${bankIfscState}`,
                        "bankAccountNo": `${bankAccountNotate}`,
                        "upiId": `${upiState}`,
                        "sellingAmount": `${location.state.basePrice}`,
                        "addressId": `${location.state.addressId}`,
                        "wantToPickupYourself": `${location.state.wantToPickupYourself}`
                    }
                }
            }).then((data) => {
                return (
                    swal({
                        title: "Success",
                        text: "Laptop Booking is created successfully",
                        icon: "success",
                    }).then(() => {
                        navigate('/userDashboard/yourorder')
                    })
                )
            }

            ).catch((e) => console.log(e)).finally(() => { console.log("request completed") });

        } else if (location.state.productCategory == "Phones") {

            createSellOrder({
                variables: {
                    "sellOrderInput": {
                        "userId": `${userId}`,
                        "productCategory": `${location?.state.productCategory}`,
                        "imageKey": `${location?.state.imgurl}`,
                        "productBrand": `${location?.state.productBrand}`,
                        "productName": `${location?.state.productName}`,
                        "storage": `${location?.state.storage}`,
                        "ram": `${location?.state.ram}`,
                        "details": [{
                            "que": "1. Is your device switching on?",
                            "ans": `${location.state.isDeviceSwitch}`
                        },
                        {
                            "que": "2. Able to make and receive calls?",
                            "ans": `${location.state.ableToMakeReceiveCall}`
                        }, {
                            "que": "3. Any issue with your device display",
                            "ans": `${location.state.issueWithDeviceDisplay}`
                        }, {
                            "que": "4. Tell us about your gadget’s screen?",
                            "ans": `${location.state.deviceScreen}`
                        }, {
                            "que": "5. Is your device ever repaired?",
                            "ans": `${location.state.deviceEverRepair}`
                        },
                        {
                            "que": "defects",
                            "ans": `${location.state.defects}`
                        }
                            ,
                        {
                            "que": "condition of your device screen",
                            "ans": `${location.state.ConditionDeviceScreen}`
                        }
                            ,
                        {
                            "que": "condition of your device Body",
                            "ans": `${location.state.ConditionDeviceBody}`
                        }
                            ,
                        {
                            "que": "Have Box",
                            "ans": `${location.state.HaveBoxWithIMEI}`
                        }
                            ,
                        {
                            "que": "Have Bill",
                            "ans": `${location.state?.HaveBill}`
                        },
                        {
                            "que": "Have Data Cable",
                            "ans": `${location.state.HaveDataCable}`
                        }
                            ,
                        {
                            "que": "Have Adapter",
                            "ans": `${location.state.HaveAdapter}`
                        }
                            ,
                        {
                            "que": "Have Earphones",
                            "ans": `${location.state.HaveEarphones}`
                        }
                            ,
                        {
                            "que": "Purchased Place",
                            "ans": `${location.state.PurchasedPlace}`
                        }
                            ,
                        {
                            "que": "Additional Insurance",
                            "ans": `${location.state.AdditionalInsurance}`
                        }
                            ,
                        {
                            "que": "Warranty Period",
                            "ans": `${location.state.WarrantyPeriod}`
                        }
                            ,
                        {
                            "que": "Under Finance",
                            "ans": `${location.state.financeClick}`
                        }


                        ],
                        "addressName": `${location?.state.nameState}`,
                        "addressEmail": `${location?.state.emailState}`,
                        "addressContact": `${location?.state.contactState}`,
                        "addressFlat": `${location?.state.flateState}`,
                        "addressState": `${location?.state.stateState}`,
                        "addressCity": `${location?.state.cityState}`,
                        "addressLandmark": `${location?.state.landmarkState}`,
                        "addressPincode": `${location?.state.pincodeState}`,
                        "addressAlternatContact": `${location?.state.alternativeMobState}`,
                        "addressType": `${location?.state.saveAsState}`,
                        "pickupDate": `${location?.state.pickupDateState}`,
                        "pickupTime": `${location?.state.pickupTimeState}`,
                        "paymentMethod": `${paymentMethodState}`,
                        "bankName": `${bankNameState}`,
                        "bankAccountHolderName": `${bankHolderNameState}`,
                        "bankIfsc": `${bankIfscState}`,
                        "bankAccountNo": `${bankAccountNotate}`,
                        "upiId": `${upiState}`,
                        "sellingAmount": `${location.state.basePrice}`,
                        "addressId": `${location.state.addressId}`,
                        "wantToPickupYourself": `${location.state.wantToPickupYourself}`


                    }
                }
            }).then((data) => {
                return (
                    swal({
                        title: "Success",
                        text: "Phone Booking is created successfully",
                        icon: "success",
                    }).then(() => {
                        navigate('/userDashboard/yourorder')
                    })
                )
            }

            ).catch((e) => console.log(e)).finally(() => { console.log("request completed") });
        } else if (location.state.productCategory == "Tablets") {

            createSellOrder({
                variables: {
                    "sellOrderInput": {
                        "userId": `${userId}`,
                        "productCategory": `${location?.state.productCategory}`,
                        "imageKey": `${location?.state.imgurl}`,
                        "productBrand": `${location?.state.productBrand}`,
                        "productName": `${location?.state.productName}`,
                        "storage": `${location?.state.storage}`,
                        "ram": `${location?.state.ram}`,
                        "details": [{
                            "que": "1. Is your device switching on?",
                            "ans": `${location.state.isDeviceSwitch}`
                        },
                            , {
                            "que": "2. Any issue with your device display",
                            "ans": `${location.state.issueWithDeviceDisplay}`
                        }, {
                            "que": "3. Tell us about your gadget’s screen?",
                            "ans": `${location.state.deviceScreen}`
                        }, {
                            "que": "4. Is your device ever repaired?",
                            "ans": `${location.state.deviceEverRepair}`
                        },
                        {
                            "que": "defects",
                            "ans": `${location.state.defects}`
                        }
                            ,
                        {
                            "que": "condition of your device screen",
                            "ans": `${location.state.ConditionDeviceScreen}`
                        }
                            ,
                        {
                            "que": "condition of your device Body",
                            "ans": `${location.state.ConditionDeviceBody}`
                        }
                            ,
                        {
                            "que": "Have Box",
                            "ans": `${location.state.HaveBoxWithIMEI}`
                        }
                            ,
                        {
                            "que": "Have Bill",
                            "ans": `${location.state?.HaveBill}`
                        },
                        {
                            "que": "Have Charger",
                            "ans": `${location.state.HaveDataCable}`
                        }
                            ,
                        {
                            "que": "Have Apple Pencil",
                            "ans": `${location.state.HaveApplePencil}`
                        }
                            ,
                        {
                            "que": "Purchased Place",
                            "ans": `${location.state.PurchasedPlace}`
                        }
                            ,
                        {
                            "que": "Additional Insurance",
                            "ans": `${location.state.AdditionalInsurance}`
                        }
                            ,
                        {
                            "que": "Warranty Period",
                            "ans": `${location.state.WarrantyPeriod}`
                        }
                            ,
                        {
                            "que": "Under Finance",
                            "ans": `${location.state.financeClick}`
                        }


                        ],
                        "addressName": `${location?.state.nameState}`,
                        "addressEmail": `${location?.state.emailState}`,
                        "addressContact": `${location?.state.contactState}`,
                        "addressFlat": `${location?.state.flateState}`,
                        "addressState": `${location?.state.stateState}`,
                        "addressCity": `${location?.state.cityState}`,
                        "addressLandmark": `${location?.state.landmarkState}`,
                        "addressPincode": `${location?.state.pincodeState}`,
                        "addressAlternatContact": `${location?.state.alternativeMobState}`,
                        "addressType": `${location?.state.saveAsState}`,
                        "pickupDate": `${location?.state.pickupDateState}`,
                        "pickupTime": `${location?.state.pickupTimeState}`,
                        "paymentMethod": `${paymentMethodState}`,
                        "bankName": `${bankNameState}`,
                        "bankAccountHolderName": `${bankHolderNameState}`,
                        "bankIfsc": `${bankIfscState}`,
                        "bankAccountNo": `${bankAccountNotate}`,
                        "upiId": `${upiState}`,
                        "sellingAmount": `${location.state.basePrice}`,
                        "addressId": `${location.state.addressId}`,
                        "wantToPickupYourself": `${location.state.wantToPickupYourself}`


                    }
                }
            }).then((data) => {
                return (
                    swal({
                        title: "Success",
                        text: "Tablet Booking is created successfully",
                        icon: "success",
                    }).then(() => {
                        navigate('/userDashboard/yourorder')
                    })
                )
            }

            ).catch((e) => console.log(e)).finally(() => { console.log("request completed") });

        }
        else if (location.state.productCategory == "Smart Watches") {

            createSellOrder({
                variables: {
                    "sellOrderInput": {
                        "userId": `${userId}`,
                        "productCategory": `${location?.state.productCategory}`,
                        "imageKey": `${location?.state.imgurl}`,
                        "productBrand": `${location?.state.productBrand}`,
                        "productName": `${location?.state.productName}`,

                        "details": [{
                            "que": "1. Is your device switching on?",
                            "ans": `${location.state.isDeviceSwitch}`
                        },
                        // {
                        //     "que": "2. Able to make and receive calls?",
                        //     "ans": `${location.state.ableToMakeReceiveCall}`
                        // },
                        {
                            "que": "3. Any issue with your device display",
                            "ans": `${location.state.issueWithDeviceDisplay}`
                        }, {
                            "que": "4. Tell us about your gadget’s screen?",
                            "ans": `${location.state.deviceScreen}`
                        }, {
                            "que": "5. Is your device ever repaired?",
                            "ans": `${location.state.deviceEverRepair}`
                        },
                        {
                            "que": "defects",
                            "ans": `${location.state.defects}`
                        }
                            ,
                        {
                            "que": "condition of your device screen",
                            "ans": `${location.state.ConditionDeviceScreen}`
                        }
                            ,
                        {
                            "que": "condition of your device Body",
                            "ans": `${location.state.ConditionDeviceBody}`
                        }
                            ,
                        {
                            "que": "Have Box",
                            "ans": `${location.state.HaveBoxWithIMEI}`
                        }
                            ,
                        {
                            "que": "Have Bill",
                            "ans": `${location.state?.HaveBill}`
                        },
                        {
                            "que": "Have Power Cable",
                            "ans": `${location.state.powerCableClick}`
                        }
                            ,
                        {
                            "que": "Have Strap",
                            "ans": `${location.state.strapClick}`
                        }
                            ,

                        {
                            "que": "Purchased Place",
                            "ans": `${location.state.PurchasedPlace}`
                        }
                            ,
                        {
                            "que": "Additional Insurance",
                            "ans": `${location.state.AdditionalInsurance}`
                        }
                            ,
                        {
                            "que": "Warranty Period",
                            "ans": `${location.state.warrantyPeriod}`
                        }
                            ,
                        {
                            "que": "Under Finance",
                            "ans": `${location.state.financeClick}`
                        }
                        ],
                        "addressName": `${location?.state.nameState}`,
                        "addressEmail": `${location?.state.emailState}`,
                        "addressContact": `${location?.state.contactState}`,
                        "addressFlat": `${location?.state.flateState}`,
                        "addressState": `${location?.state.stateState}`,
                        "addressCity": `${location?.state.cityState}`,
                        "addressLandmark": `${location?.state.landmarkState}`,
                        "addressPincode": `${location?.state.pincodeState}`,
                        "addressAlternatContact": `${location?.state.alternativeMobState}`,
                        "addressType": `${location?.state.saveAsState}`,
                        "pickupDate": `${location?.state.pickupDateState}`,
                        "pickupTime": `${location?.state.pickupTimeState}`,
                        "paymentMethod": `${paymentMethodState}`,
                        "bankName": `${bankNameState}`,
                        "bankAccountHolderName": `${bankHolderNameState}`,
                        "bankIfsc": `${bankIfscState}`,
                        "bankAccountNo": `${bankAccountNotate}`,
                        "upiId": `${upiState}`,
                        "sellingAmount": `${location.state.basePrice}`,
                        "addressId": `${location.state.addressId}`,
                        "wantToPickupYourself": `${location.state.wantToPickupYourself}`


                    }
                }
            }).then((data) => {
                return (
                    swal({
                        title: "Success",
                        text: "Watch Booking is created successfully",
                        icon: "success",
                    }).then(() => {
                        navigate('/userDashboard/yourorder')
                    })
                )
            }

            ).catch((e) => console.log(e)).finally(() => { console.log("request completed") });

        }
        else if (location.state.productCategory == "Desktop/Imac") {

            createSellOrder({
                variables: {
                    "sellOrderInput": {
                        "userId": `${userId}`,
                        "productCategory": `${location?.state.productCategory}`,
                        "imageKey": `${location?.state.imgurl}`,
                        "productBrand": `${location?.state.productBrand}`,
                        "productName": `${location?.state.productName}`,
                        "storage": `${location?.state.laptopStorage}`,
                        "ram": `${location?.state.laptopRam}`,
                        "details": [{
                            "que": "1. Is your device switching on?",
                            "ans": `${location.state.isDeviceSwitch}`
                        },
                        // {
                        //     "que": "2. Able to make and receive calls?",
                        //     "ans": `${location.state.ableToMakeReceiveCall}`
                        // },
                        {
                            "que": "3. Any issue with your device display",
                            "ans": `${location.state.issueWithDeviceDisplay}`
                        }, {
                            "que": "4. Tell us about your gadget’s screen?",
                            "ans": `${location.state.deviceScreen}`
                        }, {
                            "que": "5. Is your device ever repaired?",
                            "ans": `${location.state.deviceEverRepair}`
                        },
                        {
                            "que": "defects",
                            "ans": `${location.state.defects}`
                        }
                            ,
                        {
                            "que": "condition of your device screen",
                            "ans": `${location.state.ConditionDeviceScreen}`
                        }
                            ,
                        {
                            "que": "condition of your device Body",
                            "ans": `${location.state.ConditionDeviceBody}`
                        }
                            ,
                        {
                            "que": "Processor",
                            "ans": `${location.state.processorState}`
                        },
                        {
                            "que": "Have Graphic Card",
                            "ans": `${location.state.graphicsState}`
                        },
                        {
                            "que": "Screen Size",
                            "ans": `${location.state.screenSizeState}`
                        },
                        {
                            "que": "Have Box",
                            "ans": `${location.state.HaveBoxWithIMEI}`
                        }
                            ,
                        {
                            "que": "Have Power Cable",
                            "ans": `${location.state.powerCableClick}`
                        }
                            ,
                        {
                            "que": "Have Adapter",
                            "ans": `${location.state.HaveAdapter}`
                        },
                        {
                            "que": "Have Bill",
                            "ans": `${location.state?.HaveBill}`
                        }
                            ,
                        {
                            "que": "Have Functional Mouse",
                            "ans": `${location.state.funcMouseClick}`
                        }
                            ,
                        {
                            "que": "Have Functional Keyboard",
                            "ans": `${location.state.funcKeyboardClick}`
                        }
                            ,

                        {
                            "que": "Purchased Place",
                            "ans": `${location.state.purchasedPlaceClick}`
                        }
                            ,
                        {
                            "que": "Additional Insurance",
                            "ans": `${location.state.AdditionalInsurance}`
                        }
                            ,
                        {
                            "que": "Warranty Period",
                            "ans": `${location.state.warrantyPeriod}`
                        }
                            ,
                        {
                            "que": "Under Finance",
                            "ans": `${location.state.financeClick}`
                        }
                        ],
                        "addressName": `${location?.state.nameState}`,
                        "addressEmail": `${location?.state.emailState}`,
                        "addressContact": `${location?.state.contactState}`,
                        "addressFlat": `${location?.state.flateState}`,
                        "addressState": `${location?.state.stateState}`,
                        "addressCity": `${location?.state.cityState}`,
                        "addressLandmark": `${location?.state.landmarkState}`,
                        "addressPincode": `${location?.state.pincodeState}`,
                        "addressAlternatContact": `${location?.state.alternativeMobState}`,
                        "addressType": `${location?.state.saveAsState}`,
                        "pickupDate": `${location?.state.pickupDateState}`,
                        "pickupTime": `${location?.state.pickupTimeState}`,
                        "paymentMethod": `${paymentMethodState}`,
                        "bankName": `${bankNameState}`,
                        "bankAccountHolderName": `${bankHolderNameState}`,
                        "bankIfsc": `${bankIfscState}`,
                        "bankAccountNo": `${bankAccountNotate}`,
                        "upiId": `${upiState}`,
                        "sellingAmount": `${location.state.basePrice}`,
                        "addressId": `${location.state.addressId}`,
                        "wantToPickupYourself": `${location.state.wantToPickupYourself}`


                    }
                }
            }).then((data) => {
                return (
                    swal({
                        title: "Success",
                        text: "Desktop Booking is created successfully",
                        icon: "success",
                    }).then(() => {
                        navigate('/userDashboard/yourorder')
                    })
                )
            }

            ).catch((e) => console.log(e)).finally(() => { console.log("request completed") });

        }
    }
    const handleCashClick = (e) => {
        if (cashClicked) {
            setCashClicked(false)
        } else {

            setCashClicked(true)
        }
    }
    const [showCashOption, setShowCashOption] = useState(false);

    const handlePincodeCheck = () => {
        if (getAllPincode?.getAllPincode) {
            const pincodes = getAllPincode?.getAllPincode.map((cityPincode) => cityPincode.pincode);
            // Check if the targetPincode is in the pincodes array
            const containsPincode = pincodes.includes(location.state?.pincodeState);

            if (containsPincode) {
                setShowCashOption(true)
            } else {

            }
        }

    }
    const handleSelectBrand = () => {
        let cat = location.state?.productCategory
        if (cat === "Desktop/Imac") {
            cat = "Desktop-Imac"
        }
        navigate(`/selling/${cat}`,)
    }
    const handleSelectProduct = () => {
        navigate(`/allbrands/${location.state?.productBrand}`, {
            state: {
                productCategory: location.state?.productCategory, productBrand: location.state?.productBrand,
            }
        })
    }
    return (
        <>
            <Navigation />
            <div className="banner">
                <h2>
                    Please Select Your Payment Method
                </h2>
                <div className="search-bar">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                    />
                    {/* <div className="search-icon">
                        <FaSearch />
                    </div> */}
                </div>
                <Breadcrumb>
                    <Breadcrumb.Item ><Link to="/">Home</Link></Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a  onClick={handleSelectBrand}>  Select Brand</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item onClick={handleSelectProduct}>Sell Product</Breadcrumb.Item>
                </Breadcrumb>

            </div>

            <Container>
                <Row>
                    <Col md={6}>
                        <Card style={{ height: "100%" }}>
                            <Card.Body>
                                <Card.Title>Payment Options</Card.Title>
                                <Card.Body>



                                    <Row>
                                        <Col xl={1} xs={1}>
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    required
                                                    type="radio"
                                                    name="payment"
                                                    feedbackType="invalid"
                                                    onClick={() => setAddBankDetailModal(true)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xl={11} xs={11} className="d-flex justify-content-start gap-3">
                                            <div>
                                                <img src={BankImage} width={20}>
                                                </img>
                                            </div>
                                            <div><h5>Direct Bank Transfer</h5>
                                                <p>Fill Your bank account details.</p>
                                            </div> </Col>
                                        {/* <Col xl={4}><Button variant="secondary" onClick={() => setAddBankDetailModal(true)} > Add Bank Details </Button> </Col> */}
                                    </Row>

                                    <Row>
                                        <Col xl={1} xs={1}>
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    required
                                                    type="radio"
                                                    name="payment"
                                                    feedbackType="invalid"
                                                    onClick={() => setAddUpiModal(true)}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xl={11} xs={11} className="d-flex justify-content-start gap-3">
                                            <div>
                                                <img src={UpiImage} width={30}>
                                                </img>
                                            </div>
                                            <div><h5>UPI</h5>
                                                <p>A registered UPI ID required.</p>
                                            </div> </Col>
                                        {/* <Col xl={4}><Button variant="secondary" onClick={() => setAddBankDetailModal(true)} > Add Bank Details </Button> </Col> */}
                                    </Row>

                                    {showCashOption ? <Row>

                                        <Col xl={1} xs={1}>
                                            <Form.Group className="mb-3">
                                                <Form.Check
                                                    required
                                                    type="radio"
                                                    name="payment"
                                                    feedbackType="invalid"
                                                    onClick={() => handleCashClick()}
                                                />
                                            </Form.Group>
                                        </Col>

                                        <Col xl={11} xs={11} className="d-flex justify-content-start gap-3">
                                            <div>
                                                <img src={Cod} width={30}>
                                                </img>
                                            </div>
                                            <div><h5>Cash</h5>
                                                <p>Spot Cash Payment.</p>
                                            </div> </Col>


                                    </Row>
                                        : ""}
                                </Card.Body>
                            </Card.Body>
                            <Card.Footer >
                                <Button variant="warning" disabled={cashClicked || bankNameState && bankHolderNameState && bankIfscState && bankAccountNotate || upiState ? false : true} onClick={() => handleProceed()} style={{ borderRadius: '0px', width: 150, color: '#fff', fontWeight: 'bold', fontSize: '20px', backgroundColor: "#40a737", border: "0px", borderRadius: '10px' }}>Next <FaArrowRight /></Button>
                            </Card.Footer>
                        </Card>

                    </Col>

                    <Col md={6}>
                        <Card style={{ height: "27rem" }} className="d-none d-sm-block">
                            <Card.Body>
                                <Card.Title style={{ fontWeight: "bold" }}>Price Summary</Card.Title>
                                <Image fluid style={{ width: "8rem", margin: "1rem" }} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${location?.state.imgurl}`} className='mx-auto d-block' />
                                <Card.Text style={{ marginTop: "1rem", fontWeight: "bold" }} className="sell-price"> Final Selling Price<span style={{ color: "rgb(64, 167, 55)" }} className="price-val" > Rs.{location.state.basePrice} </span></Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Modal show={addBankDetailModal} onHide={handleClose} >
                    <Modal.Header closeButton>
                        <Modal.Title>Add bank details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontWeight: "bold" }}>

                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group md="4" className="mb-3" controlId="validationCustom01">
                                    <Form.Label>Bank Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Bank Name"
                                        defaultValue={bankNameState}
                                        onChange={(e) => setBankNameState(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group md="4" className="mb-3" controlId="validationCustom01">
                                    <Form.Label>Bank Account Holder Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Account Holder Name"
                                        defaultValue={bankHolderNameState}
                                        onChange={(e) => setBankHolderNameState(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group md="4" className="mb-3" controlId="validationCustom01">
                                    <Form.Label>IFSC</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter IFSC"
                                        defaultValue={bankIfscState}
                                        onChange={(e) => setBankIfscState(e.target.value)}
                                    />
                                </Form.Group>
                                <Form.Group md="4" className="mb-3" controlId="validationCustom01">
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter Account Number"
                                        defaultValue={bankAccountNotate}
                                        onChange={(e) => setBankAccountNoState(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} >
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit} type="submit">
                            Add bank details
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={addUpiModal} onHide={handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add UPI Id</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated2} onSubmit={handleSubmit2}>
                            <Row className="mb-3">
                                <Form.Group md="4" controlId="validationCustom01">
                                    <Form.Label>Add UPI</Form.Label>
                                    <Form.Control
                                        type="text"
                                        required
                                        placeholder="Enter UPI Id"
                                        defaultValue={upiState}
                                        onChange={(e) => setUpiState(e.target.value)}
                                    />
                                </Form.Group>
                            </Row>
                        </Form>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose2}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={handleSubmit2}>
                            Add
                        </Button>
                    </Modal.Footer>
                </Modal>


            </Container>

        </>
    )
}

export default CheckOutPayment 