import React from 'react';
import Footer from '../Components/Footer';
import { Outlet } from 'react-router-dom';
import DeviceHandoverContent from '../Components/buyinstruction';

const BuyOutlet = () => {
    // Your component logic goes here

    return (
        <>
            <Outlet />
            <DeviceHandoverContent />
            <Footer />
        </>
    );
};

export default BuyOutlet;
