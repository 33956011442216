import React from 'react'
import { Button, Card, Col, Container, Image, Row } from 'react-bootstrap'
import quotes from '../Images/homepage/quotes.png'
import '../ComponentsCss/Testimonial.css'
import Slider from "react-slick";
import blog from '../Images/homepage/blog.png'
import news from '../Images/homepage/news.png'
import { FaAngleRight, FaHeadset, FaQuestionCircle, FaRegEnvelope, FaWhatsapp } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { GET_ALL_TESTIMONIAL } from '../graphql/Query';
import { useQuery } from '@apollo/client';

export default function Testimonials() {
    const { data: getAllTestimonial, loading: testimonialLoading } = useQuery(GET_ALL_TESTIMONIAL);
    
    const navigate = useNavigate();
    var settings = {
        arrows: true,
        dots: true,
        pauseOnHover: true,
        infinite: true,
        speed: 2500,
        autoplay: true,
        fade: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    var settings2 = {
        arrows: false,
        dots: false,
        pauseOnHover: true,
        infinite: true,
        speed: 2500,
        autoplay: true,
        fade: false,
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    const phoneNumber = '+918150835583'; // Replace with the recipient's phone number
    const whatsappURL = `https://wa.me/${phoneNumber}?action=video`;
    const whatsappURLPhone = `https://wa.me/${phoneNumber}`;
    const emailURL = 'mailto:contact@hellofi.in';

    return (
        <>
            <Container className='testmain'>
                <Row>
                    <Col md={4} style={{ marginTop: 20 }}>
                        <Card className='card1test'>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ height: 40, width: 5, backgroundColor: '#44CE30', marginTop: 20 }}></div>
                                <h3 style={{ marginTop: 30, marginLeft: 10, fontWeight: 500 }}>Need Help?</h3>
                            </div>
                            <a href={whatsappURLPhone} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }} >

                                <Card className='whatsappcard'>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <FaWhatsapp size={30} style={{ color: "#44CE30" }} />
                                        <div style={{ marginLeft: '20px', width: '100%' }}>
                                            <h6 style={{ fontWeight: 600, marginTop: 15 }}>Ask us on Whatsapp!</h6>
                                            <p style={{ fontSize: 14 }}>Get instant support via expert</p>
                                        </div>
                                        <div>
                                            <FaAngleRight size={25} style={{ textAlign: 'right' }} />
                                        </div>
                                    </div>
                                </Card>
                            </a>
                            <a href={whatsappURL} target="_blank" rel="noopener noreferrer" style={{ color: "#000" }} >
                                <Card className='whatsappcard'  >
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <FaHeadset size={35} style={{ color: "#44CE30" }} />
                                        <div style={{ marginLeft: '20px', width: '100%' }}>
                                            <h6 style={{ fontWeight: 600, marginTop: 15, textDecoration: "none" }}>Ask us on Video Call</h6>
                                            <p style={{ fontSize: 14 }}>Connect instantly with our team and see the products live over a video call </p>
                                        </div>
                                        <div>
                                            <FaAngleRight size={25} style={{ textAlign: 'right' }} />
                                        </div>
                                    </div>
                                </Card>
                            </a>
                            <Row>
                                <Col><Card className='whatsappcard' onClick={() => navigate("/faq")}>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}  >
                                        <FaQuestionCircle size={35} style={{ color: "#44CE30" }} />
                                        <div style={{ marginLeft: '20px', width: '100%' }}>
                                            <h6 style={{ fontWeight: 600, marginTop: 15, fontSize: 15 }}>FAQs</h6>
                                        </div>
                                    </div>
                                </Card></Col>
                                <Col>
                                    <a href={emailURL} target='_blank' style={{ color: "#000" }}>
                                        <Card className='whatsappcard'>
                                            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                <FaRegEnvelope size={35} style={{ color: "#44CE30" }} />
                                                <div style={{ marginLeft: '20px', width: '100%' }}>
                                                    <h6 style={{ fontWeight: 600, marginTop: 15, fontSize: 15 }}>Mail us</h6>
                                                </div>
                                            </div>
                                        </Card>
                                    </a></Col>

                            </Row>
                        </Card>
                    </Col>
                    <Col md={8} style={{ marginTop: 20 }}>
                        <Row>
                            <Col md={12}>
                                <Card className='card1test2'>
                                    <Image className='mx-auto d-block' src={quotes} style={{ height: '50px', width: '50px' }}></Image>

                                    <Slider {...settings}>
                                        {
                                            testimonialLoading ? <tr><td colSpan="4" className="text-center">Loading...</td></tr>

                                                :
                                                getAllTestimonial && getAllTestimonial.getAllTestimonial.map((item, index) => (

                                                    <div>
                                                        <h5 className='testtxt'>{item.description}</h5>
                                                        <div className='' style={{ display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                                                            <div>
                                                                <p style={{ fontSize: '20px', marginLeft: 20, fontWeight: 700, color: '#818EA0' }}>{item.name}</p> 
                                                                <p style={{ fontSize: '12px', marginLeft: 20, color: '#818EA0' }}>{item.position}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))      
                                        }

                                    </Slider>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Card className='newtestpro mx-auto d-block' >
                                    <Slider {...settings2}>
                                        <div>
                                            <div className='txtsev'>
                                                <div>
                                                    <h4 className='crdtxt'>Blogs</h4>
                                                    <p className='crdtxt2'>See related blogs</p>
                                                    <Button className='btnserv' onClick={()=> navigate("/blogs")} >Learn More</Button>
                                                </div>
                                                <Image src={blog} style={{ height: '100%', width: '70%' }} responsive></Image>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='txtsev'>
                                                <div>
                                                    <h4 className='crdtxt'>Blogs</h4>
                                                    <p className='crdtxt2'>See realated blogs</p>
                                                    <Button className='btnserv'>Learn More</Button>
                                                </div>
                                                <Image src={blog} style={{ height: '100%', width: '70%' }} responsive></Image>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='txtsev'>
                                                <div>
                                                    <h4 className='crdtxt'>Blogs</h4>
                                                    <p className='crdtxt2'>See realated blogs</p>
                                                    <Button className='btnserv' onClick={()=> navigate("/blogs")}>Learn More</Button>
                                                </div>
                                                <Image src={blog} style={{ height: '100%', width: '70%' }} responsive></Image>
                                            </div>
                                        </div>
                                    </Slider>
                                </Card>
                            </Col>
                            <Col md={6}>
                                <Card className='newtestpro mx-auto d-block' >
                                    <Slider {...settings2}>
                                        <div>
                                            <div className='txtsev'>
                                                <div>
                                                    <h4 className='crdtxt'>News</h4>
                                                    <p className='crdtxt2'>See related news</p>
                                                    <Button className='btnserv' >Learn More</Button> 
                                                </div>
                                                <Image src={news} style={{ height: '90%', width: '70%' }} responsive></Image>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='txtsev'>
                                                <div>
                                                    <h4 className='crdtxt'>News</h4>
                                                    <p className='crdtxt2'>See related news</p>
                                                    <Button className='btnserv' onClick={()=> navigate("/news")} >Learn More</Button>
                                                </div>
                                                <Image src={news} style={{ height: '90%', width: '70%' }} responsive></Image>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='txtsev'>
                                                <div>
                                                    <h4 className='crdtxt'>News</h4>
                                                    <p className='crdtxt2'>See related news</p>
                                                    <Button className='btnserv'onClick={()=> navigate("/news")} >Learn More</Button>
                                                </div>
                                                <Image src={news} style={{ height: '90%', width: '70%' }} responsive></Image>
                                            </div>
                                        </div>
                                    </Slider>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
