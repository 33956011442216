import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../Footer"
import { QUERY_GET_TRENDING_PRODUCTS } from '../../graphql/Query';
import { QUERY_GET_FILTERED_BUY_PRODUCT } from '../../graphql/Query'


import React, { useEffect, useState } from 'react'
import { useContext } from "react";
import Navigation from "../Navigation"
import { Button, Card, Col, Container, Form, Row, Table, Spinner, Image, Dropdown, Modal, Tabs, Tab, InputGroup, Accordion } from 'react-bootstrap'
import Slider from "react-slick"
import { FaTag, FaShoppingCart, FaShippingFast, FaMoneyBillWave, FaClock, FaHeadset, FaPhoneAlt, FaQuestionCircle, FaPhoneSquareAlt, FaYoutube, FaArrowCircleRight } from "react-icons/fa";
import "../../ComponentsCss/Buying/Buyproductdetails.css"
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { CartContext } from "../Contex/ProductDetailContex";
import ConditionImg from "../../Images/Downloads/phone-fair.png"
import OurServices from "../OurServices";
import ProductAssured from "./Product_ Assured";
import swal from "sweetalert";
import { QUERY_GET_OFFER_BY_CAT, GET_BUY_PRODUCT_BY_ID } from '../../graphql/Query'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Userlogin from "../User/Userlogin";
import { useParams } from 'react-router-dom';



const ShowProductDetails = () => {
    const { id } =  useParams();
    const { data: getBuyProductById, loading: getProductByIdLoading } = useQuery(GET_BUY_PRODUCT_BY_ID, {
        variables: {
            "productId": id
        }
    })

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const location = useLocation()
    const userId = localStorage.getItem('userId');
    const { cartData } = useContext(CartContext);
    const { data: getFilterBuyProduct, loading: SimilarProLoading } = useQuery(QUERY_GET_FILTERED_BUY_PRODUCT,
        {
            variables:
            {
                "filterFileld": "productCategory",
                "filterVal": getBuyProductById?.getBuyProductById?.productCategory,
                // "filterFileld2": "condition",
                // "filterVal2": location.state.productCondition,

            }
        });




    const [key, setKey] = useState('home');
    const [inCart, setInCart] = useState(false);
    const [pincodeState, setPincodeState] = useState("");
    //backend data treanding
    const { data: getTrendingBuyProduct, loading: Loading } = useQuery(QUERY_GET_TRENDING_PRODUCTS,
        {
            variables:
            {
                "category": getBuyProductById?.getBuyProductById.productCategory

            }
        });
    //Slider setting 

    const settings3 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: Math.min(4, getTrendingBuyProduct?.getTrendingBuyProduct?.length ? getTrendingBuyProduct?.getTrendingBuyProduct.length : 0),
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,

        responsive: [

            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },

        ]
    };
    //getting offers
    const { data: getAllOfferByCategory, loading: Loading123 } = useQuery(QUERY_GET_OFFER_BY_CAT,
        {
            variables:
            {
                "category": getBuyProductById?.getBuyProductById.productCategory

            }
        })

    useEffect(() => {
        window.scrollTo(0, 0)
        cartData?.map((product) => {
            if (product?.productId?.id === id) {
                return setInCart(true);
            }
        })
    }, [cartData])
    const [mainImage, setMainImage] = useState(null); // Initialize main image as null initially

    useEffect(() => {
        if (!getProductByIdLoading && getBuyProductById?.getBuyProductById?.image.length > 0) {
            // Set the main image once data is loaded and image array is not empty
            setMainImage(getBuyProductById.getBuyProductById.image[0]);
        }
    }, [getProductByIdLoading, getBuyProductById]);
    const { addToCart } = useContext(CartContext);
    const navigate = useNavigate()

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const settings = {
        autoplay: true,
        autoplaySpeed: 2000,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1

    };
    const handleAddToCart = () => {

        if (!userId) {
            handleShow2()


        } else {
            addToCart(getBuyProductById?.getBuyProductById)
        }
    }
    const handleCartClick = () => {
        if (!userId) {
            swal("Please Login First", "", "warning")
            navigate("/userloginpage")
        } else {

            navigate("/buying/cart")
        }
    }
    const handleImageClick = () => {
        handleShow()
    }
    const handleProceedToCart = () => {
        navigate("/buying/cart")
    }
    // delivered date algorithm 
    const currentDate = new Date();
    const currentDate2 = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    currentDate2.setDate(currentDate2.getDate() + 5);
    const options = { month: "short", day: "2-digit" };
    const deliveredDate = currentDate.toLocaleDateString(undefined, options);
    const deliveredDate2 = currentDate2.toLocaleDateString(undefined, options);
    // pincode algorithm
    const handlePinCodeBtn = () => {
        if (pincodeState.length === 6) {
            swal({
                title: "Success",
                text: "We deliver to your pincode.",
                icon: "success",
            })
        } else {
            swal({
                title: "Sorry !",
                text: "Plese provide valid pincode !",
                icon: "error",
            })
        }
    }
    const handleBuyNow = async () => {
        if (!userId) {
            swal("Please Login First", "", "warning")
            navigate("/userloginpage")
        } else {
            await addToCart(getBuyProductById?.getBuyProductById, true)
            navigate("/add-buy-address", {
                state: {
                    totalCartPrice: parseInt(getBuyProductById?.getBuyProductById?.productPrice)
                }
            }
            )
        }
    }

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const handleProductClick = (product) => {
        setMainImage(product?.image[0]); // Make sure this updates the main image

        navigate(`/buying-product-details/${product.id}`, {
            state: {
                product: product
            }
        })
    }
    const phoneNumber = '+918150835583'; // Replace with the recipient's phone number
    const whatsappURL = `https://wa.me/${phoneNumber}?action=video`;


    return (

        <>
            <Navigation />
            {getProductByIdLoading ? <Spinner animation="border" variant="success" className='mx-auto d-block' /> :
                <>
                    <Container className="container-buy-p-detail" >

                        <div className="side-thubnail cardlayout" style={{ width: "13%" }} >
                            {getBuyProductById?.getBuyProductById?.image.map((imageKey) => {
                                return (
                                    <div className="side-images">
                                        < Image variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${imageKey}`} style={{ height: "100%", width: "100%" }} className="mx-auto d-block" onClick={() => setMainImage(imageKey)} />
                                    </div>
                                )
                            })
                            }
                        </div>
                        <Card className="gallerypart cardlayout">

                            <div className="d-flex justify-content-center   ">
                                <div className="w-75" >
                                    <Slider className="productslidermobile" {...settings}>
                                        {getBuyProductById?.getBuyProductById?.image.map((imageKey) => {
                                            return (
                                                <div>
                                                    < Image onClick={() => handleImageClick()} variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${imageKey}`} style={{ width: "100%", height: "100%" }} className="mx-auto d-block" />
                                                </div>
                                            )
                                        })
                                        }
                                        {/* <Image  variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${mainImage}`} style={{ width: "80%", height: "80%" }} className="mx-auto d-block" /> */}

                                    </Slider>

                                    <Image variant="top" onClick={() => handleImageClick()} src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${mainImage}`} style={{ width: "100%" }} className="mx-auto d-block productmaindesk" />
                                </div>
                            </div>


                        </Card>

                        <Card className="despart" >

                            <Card.Body>
                                <Card.Text>
                                    <h4 className="fs-4">{getBuyProductById?.getBuyProductById?.productName}</h4>
                                    <p>{getBuyProductById?.getBuyProductById.productSubTitle}</p>
                                    <p className="">
                                        <span className="fw-bold fs-4 align-middle">₹{getBuyProductById?.getBuyProductById?.productPrice}</span> <span style={{ color: "gray", fontSize: "18px" }} className="align-middle ms-3">  MRP : <span className="sellprice align-middle">   ₹{getBuyProductById?.getBuyProductById?.mrp}</span></span>
                                    </p>
                                    <hr />
                                    <h5 style={{ fontSize: "17px" }}>Available offers</h5>
                                    {Loading ? <Spinner animation="border" variant="success" className='mx-auto d-block' />
                                        :
                                        getAllOfferByCategory && getAllOfferByCategory?.getAllOfferByCategory?.map((offer) => {
                                            return (
                                                <p className="buydetailstags d-flex justify-content-between">

                                                    <FaTag className="me-2 d-block align-top" style={{ fontSize: "25px" }} />
                                                    <div>
                                                        {/* <span className="">Bank Offer | </span><span>10% Instant Discount on ICICI Bank Credit Card and EMI Txns up to ₹1250 on orders of ₹5000 and above</span><span className="text-primary" style={{ fontSize: "13px" }}> <a href="">Know more</a> </span> */}
                                                        <span className="">{offer.offerDesc}</span>
                                                    </div>
                                                </p>

                                            )
                                        })
                                    }

                                    {/* <p className="buydetailstags d-flex justify-content-between">
                                <FaTag className="me-2 d-block align-top" style={{ fontSize: "20px" }} />
                                <div>
                                    <span className="">Partner Offer | </span><span>Sign-up for Flipkart Pay Later & get free Times Prime Benefits worth ₹10,000*</span><span className="text-primary" style={{ fontSize: "13px" }}> <a href="">Know more</a> </span>
                                </div>
                            </p> */}

                                    <hr />
                                    <div>
                                        <h5 style={{ fontSize: "17px" }}>Ship To</h5>
                                        <InputGroup className="my-3">
                                            <Form.Control
                                                placeholder="Enter Your Pincode"
                                                type="number"
                                                aria-label="Enter Your Pincode"
                                                aria-describedby="basic-addon2"
                                                style={{ fontSize: "15px" }}
                                                value={pincodeState}
                                                onChange={(e) => setPincodeState(e.target.value)}
                                            />
                                            <Button variant="secondary" id="button-addon2" style={{ fontSize: "15px" }} onClick={() => handlePinCodeBtn()}>
                                                Check Pincode
                                            </Button>
                                        </InputGroup>
                                    </div>

                                    <Row className="mb-3">
                                        <Col>
                                            <a href={getBuyProductById?.getBuyProductById?.liveLink} target="_blank" className="text-dark">
                                                <Card className='whatsappcard'>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <FaYoutube size={40} style={{ color: "#dc3545" }} />
                                                        <div style={{ marginLeft: '15px', width: '100%' }}>
                                                            <h6 style={{ fontWeight: 600, marginTop: 10, fontSize: 15 }}>Realtime Product Video </h6>

                                                        </div>
                                                        <div>
                                                            <FaArrowCircleRight size={20} style={{ color: "#198754" }} />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </a></Col>
                                        <Col>
                                            <a href={whatsappURL} target="_blank" className="text-dark">
                                                <Card className='whatsappcard'>
                                                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                                        <FaHeadset size={40} style={{ color: "#000" }} />
                                                        <div style={{ marginLeft: '20px', width: '100%' }}>
                                                            <h6 style={{ fontWeight: 600, marginTop: 10, fontSize: 15 }}>Ask us on Video Call </h6>

                                                        </div>
                                                        <div>
                                                            <FaArrowCircleRight size={20} style={{ color: "#198754" }} />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </a>
                                        </Col>

                                    </Row>


                                    <p className="buydetailstags">
                                        <FaShippingFast className="m-1" style={{ fontSize: "15px" }} /> <span className="">Delivery by | </span> <span className="fw-bold ">{deliveredDate2} - {deliveredDate}</span> </p>

                                    <p className="buydetailstags">
                                        <FaMoneyBillWave className="m-1" style={{ fontSize: "15px" }} /> <span className="">Cash on Delivery | </span> <span className="fw-bold text-success" style={{ fontSize: "13px" }}>
                                            {
                                                parseInt(getBuyProductById?.getBuyProductById?.productPrice) <= 20000 ?
                                                    "Available" : "Not Available "

                                            }
                                        </span>
                                    </p>
                                    <p className="buydetailstags">
                                        <FaClock className=" m-1" style={{ fontSize: "15px" }} /> <span className="">Easy Replacement Policy.</span>
                                    </p>


                                    {
                                        parseInt(getBuyProductById?.getBuyProductById?.quantity) > 0 || getBuyProductById?.getBuyProductById?.quantyty != undefined ?
                                            <div className="d-flex justify-content-between">
                                                {
                                                    inCart ?
                                                        <Button className="btn btn-hover w-50" variant="outline-success f" onClick={() => handleProceedToCart()} > Proceed To Cart </Button>
                                                        :
                                                        <Button className="btn btn-hover w-50" variant="outline-success f" onClick={() => handleAddToCart()} >Add To Cart </Button>

                                                }
                                                <Button className="btn btn-hover w-50 ms-3" variant="success" onClick={() => handleBuyNow()} >Buy Now</Button>
                                            </div>
                                            : <Button className="btn btn-hover w-100" variant="outline-danger f" disabled>Out of Stock</Button>}

                                </Card.Text>
                            </Card.Body>

                        </Card>
                    </Container>
                    <Container className="my-5 paccordian">
                        <Card>
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Product description</Accordion.Header>
                                    <Accordion.Body style={{ fontWeight: "600" }}>

                                        {ReactHtmlParser(getBuyProductById?.getBuyProductById?.productDescription)}

                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Warranty Policy</Accordion.Header>
                                    <Accordion.Body>
                                        {ReactHtmlParser(getBuyProductById?.getBuyProductById?.warrantyDescription)}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>Return and Replacement Policy</Accordion.Header>
                                    <Accordion.Body>
                                        {ReactHtmlParser(getBuyProductById?.getBuyProductById?.dimensionsDescrption)}
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>Packaging Information</Accordion.Header>
                                    <Accordion.Body>
                                        {ReactHtmlParser(getBuyProductById?.getBuyProductById?.AdditionalFeatures)}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Card>

                        <h2 className="my-3"> Related Product </h2>


                        {getFilterBuyProduct && getFilterBuyProduct?.getFilterBuyProduct?.length >= 5 ? (
                            <div className="productslider">
                                <Slider {...settings3}  >

                                    {
                                        getFilterBuyProduct && getFilterBuyProduct?.getFilterBuyProduct?.map((product) => {
                                            return (
                                                <div className="slider-box" onClick={() => handleProductClick(product)} >
                                                    <Card style={{ width: '18rem', height: "320px" }} className="shadow-sm p-2 mb-5 bg-body-tertiary rounded treanding_cards laptop-card-view ">
                                                        <div className="mx-auto d-block mt-3 border p-2 trend_img" >
                                                            <Card.Img variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`} style={{ width: '150px', height: '150px' }} />
                                                        </div>
                                                        <Card.Body className="text-center mt-2 " style={{ padding: "0px" }}>
                                                            <Card.Title style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                                {product?.productName.length > 18 ? `${product?.productName.substring(0, 18)}...` : product?.productName}
                                                            </Card.Title>
                                                            <Card.Text className="text-danger fw-bold">
                                                                ₹ {product?.productPrice}
                                                            </Card.Text>
                                                        </Card.Body>


                                                    </Card>
                                                </div>
                                            )
                                        })
                                    }

                                </Slider>

                            </div>
                        ) : (
                            <div className="d-flex flex-wrap gap-3 lessthen">
                                {

                                    getFilterBuyProduct && getFilterBuyProduct?.getFilterBuyProduct?.map((product) => {
                                        return (
                                            <div className="slider-box" onClick={() => handleProductClick(product)}>
                                                <Card style={{ height: "320px" }} className="shadow-sm p-2 mb-5 bg-body-tertiary rounded treanding_cards">
                                                    <div className="mx-auto d-block mt-3 border p-2 trend_img" >
                                                        <Card.Img variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`} style={{ width: '150px', height: '150px' }} />
                                                    </div>
                                                    <Card.Body className="text-center mt-2 " style={{ padding: "0px" }}>
                                                        <Card.Title style={{ fontSize: "15px", fontWeight: "bold" }} className="titlep">
                                                            {product?.productName.length > 20 ? `${product?.productName.substring(0, 18)}...` : product?.productName}
                                                        </Card.Title>
                                                        <Card.Text className="text-danger fw-bold">
                                                            ₹ {product?.productPrice}
                                                        </Card.Text>
                                                    </Card.Body>


                                                </Card>
                                            </div>
                                        )
                                    })

                                }

                            </div>)}


                        <h2 className="my-3"> Trending Product </h2>

                        {/* {getTrendingBuyProduct && getTrendingBuyProduct?.getTrendingBuyProduct?.length >= 5 ? ( */}
                        <div className="productslider">
                            <Slider {...settings3}  >

                                {
                                    getTrendingBuyProduct && getTrendingBuyProduct?.getTrendingBuyProduct?.map((product) => {
                                        return (
                                            <div className="slider-box" onClick={() => handleProductClick(product)}>
                                                <Card style={{ width: '18rem', height: "320px" }} className="shadow-sm p-2 mb-5 bg-body-tertiary rounded treanding_cards laptop-card-view">
                                                    <div className="mx-auto d-block mt-3 border p-2 trend_img" >
                                                        <Card.Img variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`} style={{ width: '150px', height: '150px' }} />
                                                    </div>
                                                    <Card.Body className="text-center mt-2 " style={{ padding: "0px" }}>
                                                        <Card.Title style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                            {product?.productName.length > 20 ? `${product?.productName.substring(0, 18)}...` : product?.productName}
                                                        </Card.Title>
                                                        <Card.Text className="text-danger fw-bold">
                                                            ₹ {product?.productPrice}
                                                        </Card.Text>
                                                    </Card.Body>


                                                </Card>
                                            </div>
                                        )
                                    })
                                }

                            </Slider>
                        </div>
                        {/* ) : (
                            <div className="d-flex flex-wrap gap-3 lessthen">
                                {

                                    getTrendingBuyProduct && getTrendingBuyProduct?.getTrendingBuyProduct?.map((product) => {
                                        return (
                                        <div className="slider-box" onClick={() => handleProductClick(product)}>
                                                    <Card style={{ width: '18rem', height: "320px" }} className="shadow-sm p-2 mb-5 bg-body-tertiary rounded treanding_cards laptop-card-view">
                                                        <div className="mx-auto d-block mt-3 border p-2 trend_img" >
                                                            <Card.Img variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${product?.image[0]}`} style={{ width: '150px', height: '150px' }} />
                                                        </div>
                                                        <Card.Body className="text-center mt-2 " style={{ padding: "0px" }}>
                                                            <Card.Title style={{ fontSize: "15px", fontWeight: "bold" }}>
                                                                {product?.productName.length > 20 ? `${product?.productName.substring(0, 25)}...` : product?.productName}
                                                            </Card.Title>
                                                            <Card.Text className="text-danger fw-bold">
                                                                ₹ {product?.productPrice}
                                                            </Card.Text>
                                                        </Card.Body>


                                                    </Card>
                                                </div>
                                        )
                                    })

                                }

                            </div>)} */}


                        <ProductAssured />
                    </Container>

                    <Modal
                        show={show}
                        onHide={handleClose}
                        backdrop="static"
                        keyboard={false}
                        size="lg"
                        centered
                    >
                        <Modal.Header closeButton>
                        </Modal.Header>
                        <Modal.Body>
                            <Slider {...settings}>
                                {getBuyProductById?.getBuyProductById?.image.map((imageKey) => {
                                    return (
                                        <div>
                                            < Image variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${imageKey}`} style={{ width: "80%", height: "80%" }} className="mx-auto d-block" />
                                        </div>
                                    )
                                })
                                }
                                {/* <Image variant="top" src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${mainImage}`} style={{ width: "80%", height: "80%" }} className="mx-auto d-block" /> */}

                            </Slider>
                        </Modal.Body>

                    </Modal>

                    <Modal show={show2} onHide={handleClose2} backdrop="static" keyboard={false} centered size="lg">
                        <Userlogin isPurchacing={true} handleClose2={handleClose2} />
                    </Modal>
                </>
            }
        </>
    )
}

export default ShowProductDetails 