import Footer from "../Footer"
import Navigation from "../Navigation"
import { CartContext } from "../Contex/ProductDetailContex";
import { useContext, useEffect, useState } from "react";
import { Button, Card, Container, Image, Form, InputGroup, Modal } from "react-bootstrap";
import '../../ComponentsCss/Buying/BuyCart.css'
import { FaTag, FaShoppingCart, FaShippingFast, FaMoneyBillWave, FaClock, FaRegTrashAlt, FaClipboardList } from "react-icons/fa";
import EmptyCartImage from '../../Images/Downloads/emptycart.jpg'
import { MUTATION_CREATE_BUY_ORDER } from '../../graphql/Mutations'
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_QUERY_ALL_COUPON } from '../../graphql/Query';

import swal from 'sweetalert';
import { useNavigate } from "react-router-dom";


const BuyCart = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const { cartData } = useContext(CartContext);
    const { data: getAllCoupon, loading: couponLoading } = useQuery(GET_QUERY_ALL_COUPON);
    const [couponState, setCouponState] = useState()
    const [show, setShow] = useState(true);

    const [discontState, setDiscountState] = useState(0)
    const navigate = useNavigate()
    const { updateProductCount } = useContext(CartContext);
    const { removeItem } = useContext(CartContext);
    const { totalAmount } = useContext(CartContext);
    let totalCartPrice = 0
    const handlePlaceOrder = () => {
        navigate('/add-buy-address', {
            state: {
                totalCartPrice: totalCartPrice - discontState
            }
        })
        // createBuyOrder({
        //     variables :{
        //         "buyOrderInput" :{
        //             "userId": null,
        //             "productDetails": [
        //               {
        //                 "productImage": null,
        //                 "productId": null,
        //                 "productName": null,
        //                 "productPrice": null,
        //                 "totalPrice": null,
        //                 "quantity": null,
        //                 "category": null,
        //                 "storage": null,
        //                 "color": null,
        //                 "condition": null
        //               }
        //             ],
        //             "paymentId": null,
        //             "paymentMethod": null,
        //             "fName": null,
        //             "lName": null,
        //             "contact": null,
        //             "alternateContactNo": null,
        //             "email": null,
        //             "address": null,
        //             "landmark": null,
        //             "city": null,
        //             "pincode": null
        //         }
        //     }
        // }).then(()=>{
        //      swal({
        //         title: "Success",
        //         text: "Product Successfully Placed",
        //         icon: "success",
        //     })

        // }).catch((e)=> console.log(e) )
    }
    const handleCouponClick = async (total) => {
        if (couponLoading) {

        } else {
            setDiscountState(0)
            if (getAllCoupon) {
                let flag = true
                getAllCoupon?.getAllCoupon.map((coupon) => {
                    // couponArray.push(coupon.coupon)
                    if (coupon.coupon == couponState) {
                        flag = false
                        const percentageAmount = (total * parseInt(coupon.couponPercent)) / 100
                        setDiscountState(percentageAmount)
                        return (
                            swal({
                                title: "Success",
                                text: "Coupon Applied Successfully",
                                icon: "success",
                            })
                        )

                    }
                })
                if (flag) {
                    swal({
                        text: "Coupon Not Valid",
                        icon: "error",

                    })
                }
            }
        }

    }
    // delivered date algorithm 
    const currentDate = new Date();
    const currentDate2 = new Date();
    currentDate.setDate(currentDate.getDate() + 7);
    currentDate2.setDate(currentDate2.getDate() + 5);
    const options = { month: "short", day: "2-digit" };
    const deliveredDate = currentDate.toLocaleDateString(undefined, options);
    const deliveredDate2 = currentDate2.toLocaleDateString(undefined, options);
    return (
        <>
            <Navigation />
            {cartData.length === 0 ? <div className="text-center">
                <Image src={EmptyCartImage} className="empty-cart-image" />
                <div className="fs-1 mt-3 fw-bold py-5"> Your cart is empty. </div>
                <div className="text-center mt-5" > <Button variant="success" onClick={() => navigate("/")} > Continue Shopping </Button> </div>

            </div>
                :
                <Container>
                    <div className="item-count"> {cartData?.length} items in cart  </div>
                    <div className="items-and-summary" >
                        <div>
                            {cartData && cartData?.map((item) => {
                                if (parseInt(item?.productId?.quantity) === 0) {
                                  totalCartPrice = totalCartPrice - (parseInt(item?.productId?.productPrice) * parseInt(item.quantity))
                                }
                                totalCartPrice += parseInt(item?.productId?.productPrice) * parseInt(item.quantity); return (
                                    <Card className="buycart-card">
                                        <div className="cart-header">
                                            <Image src={`https://sanjaytestbucket.s3.ap-south-1.amazonaws.com/${item.productId?.image[0]}`} className="cart-pro-img" />
                                            <Card.Body>
                                                <Card.Text>
                                                    <h4 className="fs-4">{item?.productId?.productName}</h4>
                                                    <p className="">
                                                        <span className="fw-bold fs-4 align-middle">₹{item?.productId?.productPrice}</span> <span style={{ color: "gray", fontSize: "17px" }} className="align-middle ms-3">  MRP : <span className="sellprice align-middle">   ₹{parseInt(item?.productId?.mrp)}</span></span>
                                                    </p>
                                                    <p className="buydetailstags">
                                                        <FaShippingFast className="m-1" style={{ fontSize: "14px" }} /> <span className="">Delivery by | </span> <span className="fw-bold ">{deliveredDate2} - {deliveredDate}</span> </p>

                                                    <p className="buydetailstags">
                                                        <FaClipboardList className="m-1" style={{ fontSize: "13px" }} /> <span className="">Category | </span> <span className="fw-bold text-success" style={{ fontSize: "15px" }}> {item?.productId?.productCategory} </span>
                                                    </p>




                                                </Card.Text>
                                            </Card.Body>
                                        </div>
                                        <Card.Footer >
                                            <div className="cart-main-footer" >
                                                <div>

                                                    {
                                                        item?.productId?.quantity === "0" ? <Button className="btn btn-hover w-100" variant="outline-danger f" disabled>Out of Stock</Button> :

                                                            <InputGroup className="">
                                                                <Button style={{ backgroundColor: "gray", borderColor: "gray" }} id="button-addon" onClick={() => updateProductCount(item, item.id, item.quantity, "-", item.productId.quantity)}>
                                                                    <b>-</b>
                                                                </Button>
                                                                <Form.Control
                                                                    aria-label="Example text with button addon"
                                                                    aria-describedby="basic-addon"
                                                                    value={`${parseInt(item?.quantity)}`}
                                                                    min="0"
                                                                    className="fw-bold text-center cartinput"
                                                                />
                                                                <Button variant="dark" id="button-addon" onClick={() => updateProductCount(item, item.id, item.quantity, "+", item.productId.quantity)}>
                                                                    <b>+</b>
                                                                </Button>
                                                            </InputGroup>
                                                    }
                                                </div>

                                                <div>
                                                    <Button variant="danger" onClick={() => removeItem(item)}>
                                                        <FaRegTrashAlt className="text-light fw-bold align-middle" />  Remove
                                                    </Button>

                                                </div>
                                            </div>
                                        </Card.Footer>
                                    </Card>
                                )
                            })}
                            <div className="text-center mt-5" > <Button variant="success" onClick={() => navigate("/")} > Continue Shopping </Button> </div>
                        </div>
                        <Card className="buy-summary-cart">
                            <Card.Header className="text-center">
                                <Card.Title>Price Summary</Card.Title>
                            </Card.Header>
                            <Card.Body style={{ marginBottom: "-30px" }}>
                                <div className="d-flex justify-content-between">
                                    <div className="fw-bold tags">Cart Total Price : </div>
                                    <div>₹ {totalCartPrice}</div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <div className="fw-bold tags">Shipping Fee : </div>
                                    <div>₹ {0}</div>
                                </div>
                                <hr />
                                <div className="d-flex justify-content-between">
                                    <div className="fw-bold tags">Product Discount(s) : </div>
                                    <div className="text-success"> - ₹ {discontState}</div>
                                </div>
                                <hr />
                                <div>
                                    <h5 style={{ fontSize: "17px" }}>Coupon Code</h5>
                                    <InputGroup className="my-3">
                                        <Form.Control
                                            value={couponState}
                                            placeholder="Enter Coupon Code"
                                            aria-label="Enter Coupon Code"
                                            aria-describedby="basic-addon2"
                                            style={{ fontSize: "15px" }}
                                            onChange={(e) => setCouponState(e.target.value)}
                                        />
                                        <Button variant="secondary" id="button-addon2" style={{ fontSize: "15px" }} onClick={() => handleCouponClick(totalCartPrice)}>
                                            Apply
                                        </Button>
                                    </InputGroup>
                                </div>
                                <hr />

                                <div className="d-flex justify-content-between fs-5">
                                    <div className="fw-bold">Total : </div>
                                    <div>₹ {totalCartPrice - parseInt(discontState)}</div>
                                </div>

                                <p className="text-success text-center" style={{ marginTop: "17px" }}>You will save ₹ {discontState} on this order</p>
                            </Card.Body>
                            <Card.Footer>
                                <div style={{ width: "100%" }}>
                                    {
                                        totalCartPrice === 0 ? <Button className="place-order-btn" variant="danger" disabled> Place Order</Button> : <Button className="place-order-btn" onClick={() => handlePlaceOrder()}> Place Order</Button>

                                    }
                                </div>
                            </Card.Footer>
                        </Card>
                    </div>
                </Container>

            }
            <Modal
                show={show}
                size="lg"
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        Apply Coupon
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center" style={{ fontSize: "16px", fontWeight: "bold" }} >
                        Use <span className="text-success"> HELLOFI15 </span>
                    </div>
                    <p >
                        <img className="mx-auto d-block img-fluid" src="https://img.freepik.com/premium-vector/coupon-sale-banner-template-design_39705-173.jpg" />
                    </p>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default BuyCart